@font-face {
  font-family: Poppins;
  src: local('Poppins Light'),local('Poppins-Light'),url(../fonts/Poppins-Light.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Poppins;
  src: local('Poppins Regular'),local('Poppins-Regular'),url(../fonts/Poppins-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Poppins;
  src: local('Poppins Medium'),local('Poppins-Medium'),url(../fonts/Poppins-Medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Poppins;
  src: local('Poppins Bold'),local('Poppins-Bold'),url(../fonts/Poppins-Bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

body,
button,
fieldset,
form,
html,
textarea {
  font-family: Poppins,sans-serif
}

body,
html {
  scroll-behavior: smooth
}

html {
  font-size: 62.5%
}

body {
  font-size: 1.6rem;
  color: #68728b;
  font-weight: 300
}

.container {
  position: relative;
  max-width: 130rem;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;
}

.container::before {
  content: none
}

.container::after {
  content: none
}

.container-xl {
  max-width: 1760px;
}

hr {
  color: #d4d7d9;
  border: 1px inset#d4d7d9
}

.fixed {
  position: fixed
}

.relative {
  position: relative
}

.absolute {
  position: absolute
}

.static {
  position: static;
  overflow: hidden
}

.hyphenate {
  text-align: left;
  -webkit-hyphens: auto;
  hyphens: auto;
  -ms-hyphens: auto
}

.inline {
  display: inline
}

.inlineblock {
  display: inline-block
}

.block {
  display: block
}

.floatleft {
  float: left
}

.floatright {
  float: right
}

.floatnone {
  float: none
}

.textleft {
  text-align: left
}

.textright {
  text-align: right
}

.textcenter {
  text-align: center
}

.textjustify {
  text-align: justify
}

.width80 {
  width: 80%
}

.width60 {
  width: 60%
}

.width50 {
  width: 50%
}

.width47 {
  width: 47%
}

.width45 {
  width: 45%
}

.width35 {
  width: 35%
}

.width33 {
  width: 33.33333333%
}

.width25 {
  width: 25%
}

.margin0 {
  margin: 0
}

.marginauto {
  margin: auto
}

.lineh1 {
  line-height: 1
}

.lineh12 {
  line-height: 1.2
}

.lineh15 {
  line-height: 1.5
}

.lineh25 {
  line-height: 2.5
}

.height465 {
  height: 465px
}

.marginright {
  margin-right: 1em
}

.marginleft {
  margin-left: 1.5em
}

.marginbottom {
  margin-bottom: 2em!important
}

.marginbottomlight {
  margin-bottom: 1em
}

.margintopheavy {
  margin-top: 4em
}

.margintop {
  margin-top: 2em
}

.margintoplight {
  margin-top: 2em
}

.margintopultralight {
  margin-top: .5em
}

.margintop-2 {
  margin-top: -2em
}

.container900 {
  max-width: 900px;
  margin: 0 auto
}

.padding10 {
  padding: 10px
}

.paddingtopbot20 {
  padding: 20px 0
}

.padding1020 {
  padding: 10px 20px
}

.padding25 {
  padding: 25px
}

.padding30 {
  padding: 30px
}

.padding40 {
  padding: 40px
}

.padding60 {
  padding: 60px
}

.padding6040 {
  padding: 60px 40px
}

.padding7040 {
  padding: 70px 40px
}

.padding4070 {
  padding: 40px 3%
}

.padding4015 {
  padding: 40px 15px
}

.vmiddle {
  vertical-align: middle
}

.vtop {
  vertical-align: top
}

.vbottom {
  vertical-align: bottom
}

.bold {
  font-weight: 700
}

.normal {
  font-weight: 400
}

.fontsize80 {
  font-size: 80%
}

.fontsize85 {
  font-size: 85%
}

.fontsize90 {
  font-size: 90%
}

.fontsize110 {
  font-size: 110%
}

.fontsize120 {
  font-size: 120%
}

.color1,
.color1 a {
  color: #56c1ff
}

.color2,
.color2 a {
  color: #2e4e4e
}

.background1 {
  background: #f4f4f4
}

.background2 {
  color: #fff;
  background: #252525
}

.background3 {
  background: #56c1ff
}

.margin0auto {
  display: block;
  margin: 0 auto
}

.lowercase {
  text-transform: lowercase
}

.uppercase {
  text-transform: uppercase
}

.mobile {
  display: none
}

.desktop {
  display: block
}

img {
  max-width: 100%
}

.aheight,
.centered-height {
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle
}

.bg_cover {
  background-position: center center;
  background-size: cover
}

.pink {
  color: #d41d4e;
}

.blue {
  color: #0ea6af
}

.grey {
  color: #9fa8bd
}

.bg_cover {
  background-position: center center;
  background-size: cover
}

.clear,
.wrap {
  clear: both
}

.clearfix:after,
.clearfix:before {
  display: block;
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  content: '.'
}

.clearfix:after {
  clear: both
}

.overlay-fullscreen{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .75);
  z-index: 10;
}

/* Sticky mag favo */

.sticky-mag-favo {
  display: block;
  position: fixed;
  right: 0;
  top: 28rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  background-color: #9fa8bd;
  text-align: center;
  padding: 1.2rem 2rem;
  line-height: 1.2;
  z-index: 1009
}

.sticky-mag-favo:hover {
  color: #d41d4e
}

.sticky-mag-favo .icon {
  display: block;
  font-size: 7rem;
  line-height: 0;
  margin-top: -1rem;
  margin-bottom: -.5rem
}

/* Header */

#header {
  display: flex;
  align-items: center;
  position: relative;
  height: 12.5rem;
  background-color: rgba(255,255,255,.76);
  z-index: 10;
}

#home #header{
  display: block;
  padding-top: 3.5rem;
  height: 17.4rem;
}

#header .container {
  display: flex;
  justify-content: space-between;
  align-items: center
}

#logo {
  max-width: 35rem
}

#header.sticky {
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  border-bottom: solid 1px #9fa8bf;
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,.5);
  padding-top: 0
}

#home #header.sticky,
#header.sticky,
#header.sticky .container {
  height: 50px;
  padding-top: 0;
}

#home #header.sticky + .content {
  margin-top: 18rem
}

#header.sticky + .content {
  margin-top: 13rem
}

#header.sticky #logo {
  max-width: 22rem
}

#primary_nav_wrap ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 -2.2rem
}

#primary_nav_wrap ul > li {
  position: relative;
  transition: all .3s ease-in-out;
  text-align: center;
  margin-left: 2.2rem;
  margin-right: 2.2rem
}

#primary_nav_wrap ul > li a {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: #68728b;
  transition: color .3s
}

#primary_nav_wrap > ul > li a::after {
  position: absolute;
  bottom: -.6rem;
  left: 0;
  width: 0;
  height: 3px;
  content: '';
  transition: all .3s;
  background: #d02752
}

#primary_nav_wrap > ul > li a:hover::after {
  width: 100%
}

#primary_nav_wrap ul > li.current > a > span {
  line-height: 30px;
  display: inline-block;
  color: #d02752;
  border-bottom: 3px solid #d02752
}

#primary_nav_wrap ul > li.current:hover > a > span {
  color: #d02752
}

#menu_launcher {
  display: none;
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 14px;
  left: 15px;
  width: 40px;
  height: 40px;
  margin: auto;
  color: #fff
}

/* FOOTER */

#footer {
  position: relative;
  color: #fff;
  background-color: #d02752;
  padding-top: 4.2rem
}

.footer-content {
  display: flex;
  justify-content: space-between
}

#logo_footer {
  width: 38rem;
  margin-bottom: 4rem
}

.footer-content a {
  font-size: 1.8rem;
  color: #fff;
  font-weight: 500;
  transition: all .3s
}

#footer .lien_footer a,
#footer .menu-footer-picto a {
  display: inline-flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  text-transform: uppercase
}

#footer .lien_footer a .icon {
  font-size: 2.4rem;
  margin-right: .8rem
}

.sep_footer {
  height: 1px;
  margin-bottom: 15px;
  background-color: #ef6488
}

.menu_footer .main_menu {
  display: flex
}

.menu_footer ul.sub_menu {
  margin-top: 0
}

.menu_footer ul.sub_menu > li a .icon{
  font-size: 2.8rem;
  margin-right: 1rem;
}

.menu_footer ul.sub_menu > li {
  display: block;
  margin-bottom: 1.8rem;
  margin-left: 0
}

.lien_footer a,
.menu_footer ul.sub_menu > li a {
  position: relative
}

.lien_footer a::after,
.menu_footer ul.sub_menu > li a::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 1px;
  content: '';
  transition: all .3s;
  background-color: #fff
}

.lien_footer a:hover::after,
.menu_footer ul.sub_menu > li a:hover::after {
  width: 100%
}

.menu-footer-legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1.5rem
}

.menu-footer-legal a {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400
}

.menu-footer-legal a:hover {
  color: #9fa8bd
}

/* SLIDER BX */

.bx-wrapper img {
  display: block;
  width: 100%
}

.bx-wrapper,
.bx-wrapper .bx-viewport {
  left: auto;
  border: none!important;
  background: 0 0;
  box-shadow: none
}

.bx-wrapper .bx-controls-direction .bx-next,
.bx-wrapper .bx-controls-direction .bx-prev {
  text-indent: inherit;
  background: 0 0
}

.bx-wrapper .bx-next:before,
.bx-wrapper .bx-prev:before {
  font-family: ecofont!important;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  text-align: center;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.bx-wrapper .bx-pager.bx-default-pager a {
  border: grey solid 1px;
  background: #fff
}

.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:hover {
  background: grey
}

.bxslider .button2,
.bxslider .slider_description,
.bxslider .slider_txt {
  transition: all .5s ease-in-out;
  opacity: 1
}

.bxslider .button2.animate,
.bxslider .slider_description.animate,
.bxslider .slider_txt.animate {
  opacity: 1
}

.bxslider .slider_txt.animate {
  margin-left: 0;
  opacity: 1
}

.bx-viewport,
.bxslider img {
  max-height: 550px!important
}

.bxslider .slider_txt {
  font-family: Raleway,sans-serif;
  font-size: 2em;
  font-weight: 400;
  line-height: inherit;
  display: inline-block;
  margin: auto;
  padding: 10px;
  color: #fff;
  border: 1px solid #fff;
  background: rgba(0,0,0,.3)
}

.bxslider .slider_description {
  font-family: 'Crimson Text',sans-serif;
  font-size: 1.6em;
  line-height: 1.5
}

.cd-main {
  z-index: 21;
  overflow: hidden;
  overflow-y: auto;
  transition: transform .7s,-webkit-transform .7s;
  transition-timing-function: cubic-bezier(.91,.01,.6,.99);
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.navigation-is-open .cd-main {
  -webkit-transform: translateX(100%);
  transform: translateX(100%)
}

.cd-nav {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  transition: visibility 0s .7s
}

#kpop_contain {
  margin-bottom: 20px
}

#kpop_contain img.fullimg {
  max-height: 80vh
}

#kpop_contain img.fullimg {
  max-height: calc(100vh - 25vh)
}

.cd-nav .cd-navigation-wrapper {
  overflow-y: auto;
  box-sizing: border-box;
  height: 100%;
  padding: 40px 5% 40px 5%;
  transition: -webkit-transform .7s;
  transition: transform .7s,-webkit-transform .7s;
  transition-timing-function: cubic-bezier(.86,.01,.77,.78);
  transform: translateX(-50%);
  text-align: left;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.navigation-is-open .cd-nav {
  visibility: visible;
  transition: visibility 0s 0s
}

.navigation-is-open .cd-nav .cd-navigation-wrapper {
  transition: -webkit-transform .5s;
  transition: transform .5s,-webkit-transform .5s;
  transition-timing-function: cubic-bezier(.82,.01,.77,.78);
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.cd-nav-trigger {
  z-index: 50;
  display: inline-block;
  overflow: hidden;
  width: 54px;
  height: 54px;
  margin-bottom: 2em;
  cursor: pointer;
  transition: transform .5s,background-color .2s ease-in,-webkit-transform .5s;
  white-space: nowrap;
  text-indent: 100%;
  background-color: #35c3c8
}

.cd-nav-trigger:focus,
.cd-nav-trigger:hover {
  background-color: #6e6e6e
}

.cd-nav-trigger .cd-nav-icon {
  position: absolute;
  top: 47%;
  right: auto;
  bottom: auto;
  left: 33%;
  width: 22px;
  height: 2px;
  transform: rotate(-45deg);
  background-color: #fff
}

.cd-nav-trigger .cd-nav-icon::before,
.cd-nav-trigger .cd-nav-icon:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  content: '';
  transition: transform .5s,width .5s,top .3s,-webkit-transform .5s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  background-color: inherit;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.cd-nav-trigger .cd-nav-icon::before {
  transform: rotate(45deg);
  -webkit-transform-origin: right top;
  transform-origin: right top
}

.cd-nav-trigger .cd-nav-icon::after {
  transform: rotate(-45deg);
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom
}

.cd-nav-trigger svg {
  position: absolute;
  top: 0;
  left: 0
}

.navigation-is-open .cd-nav-trigger {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg)
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after,
.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
  width: 50%;
  transition: transform .5s,width .5s,-webkit-transform .5s
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::after,
.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::before {
  top: 0
}

.no-js main {
  overflow: visible;
  height: auto
}

.no-js .cd-nav {
  position: static;
  visibility: visible
}

.no-js .cd-nav .cd-navigation-wrapper {
  overflow: visible;
  height: auto;
  padding: 100px 5%;
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

input[type=checkbox],
input[type=radio] {
  display: none
}

input[type=checkbox] + label,
input[type=radio] + label {
  line-height: 24px;
  position: relative;
  display: inline-block;
  height: 24px;
  padding-left: 24px
}

input[type=checkbox] + label::before,
input[type=radio] + label::before {
  font-family: kfont;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 4px;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin: 0;
  content: '';
  text-align: center;
  color: #333;
  border: 1px solid rgba(0,0,0,.2);
  background: #fff
}

input[type=radio] + label:before {
  border-radius: 50%
}

input[type=radio]:checked + label:after {
  position: absolute;
  top: 8px;
  left: 4px;
  display: block;
  width: 10px;
  height: 10px;
  content: '';
  border-radius: 50%;
  background: #333
}

.checkbox_style {
  line-height: 20px;
  position: relative
}

.checkbox_style input {
  position: absolute;
  visibility: hidden;
  opacity: 0
}

.checkbox_style label {
  padding-left: 20px
}

.checkbox_style label::after,
.checkbox_style label::before {
  position: absolute;
  top: 0;
  left: 1px;
  display: block;
  width: 14px;
  height: 14px;
  content: ''
}

.checkbox_style label::before {
  background-color: #fff
}

.checkbox_style input:checked + label::after {
  font-family: kfont;
  font-size: inherit;
  padding-left: 1px;
  content: '\e938';
  color: #737373;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

/************/
/* HOMEPAGE */
/************/

.bg-homepage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  object-fit: cover
}

/* RECHERCHE OPTICIENS */

#recherche_boutique .container {
  position: relative;
  min-height: 46rem;
  background-color: rgba(0, 0, 0, .2);
}

#recherche_boutique .container::before {
  content: "";
  display: block;
  position: absolute;
  left: -50rem;
  top: 0;
  bottom: 0;
  width: 50rem;
  background-color: rgba(255,255,255,.76)
}

#recherche_boutique .container::after {
  content: "";
  display: block;
  position: absolute;
  right: -50rem;
  top: 0;
  bottom: 0;
  width: 50rem;
  background-color: rgba(255,255,255,.76)
}

#recherche_boutique .h1 {
  color: #fff;
  padding-top: 8rem;
  margin-bottom: 2.5rem
}

#crit_search {
  text-align: center
}

.search_opticien,
.search_marque {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#home .search_opticien{
  padding-bottom: 5rem
}

.search_opticien input,
.search_marque input {
  height: 6.8rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border: none;
  color: #656975;
  font-size: 1.6rem;
  font-weight: 500
}

.search_opticien .code_post,
.search_marque .code_post {
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem
}

.search_opticien .button_blue,
.search_marque .button_blue {
  height: 6.8rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border: none;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 0 3rem 3rem 0;
  border-left: .1rem solid #0ea6af;
}

.search_opticien .plus_criteres,
.search_marque .plus_criteres {
  color: #fff;
  cursor: pointer;
  text-align: left;
  margin-left: 3rem;
  margin-top: .8rem;
  font-size: 1.2rem;
  font-weight: 500
}

.search_opticien .plus_criteres .icon_plus,
.search_marque .plus_criteres .icon_plus {
  display: inline-block;
  width: 1rem
}

.search_opticien .plus_criteres .icon_moins,
.search_marque .plus_criteres .icon_moins {
  display: none
}

.open .search_opticien .plus_criteres .icon_moins,
.open .search_marque .plus_criteres .icon_moins {
  display: inline-block;
  width: 1rem
}

.open .search_opticien .plus_criteres .icon_plus,
.open .search_marque .plus_criteres .icon_plus {
  display: none
}

/* SECTION SPECIALITES */

.specialites_home {
  position: relative;
  background-color: rgba(255,255,255,.76);
  padding-top: 5.5rem;
  padding-bottom: 5rem
}

.specialites_home .h2 {
  margin-bottom: 5rem
}

.spe-homecard-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.spe-homecard-container .spe-homecard {
  width: calc(25% - 3rem);
  text-align: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.spe-homecard-container .spe-homecard img {
  aspect-ratio: 411/316;
  object-fit: cover;
  width: 100%
}

.spe-homecard-container .spe-content {
  position: relative;
  width: calc(80%);
  margin-left: auto;
  margin-right: auto;
  margin-top: -10rem;
  background-color: #fff;
  padding: 2rem 3rem 2.5rem
}

.spe-homecard-container .spe-content .h3 {
  font-size: 2.8rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1.2rem
}

.spe-homecard-container .spe-content .desc {
  line-height: 1.5;
  margin-bottom: 2rem
}

.spe-homecard-container .spe-content a {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #d41d4e;
  font-weight: 500
}

.spe-homecard-container .spe-content a:hover {
  color: #9fa8bd
}

.panel-content{
  position: fixed;
  top: 0;
  right: -73rem;
  bottom: 0;
  width: 73rem;
  background-color: #fff;
  z-index: 1001;
  padding: 5rem;
  box-shadow: 0 .3rem .4rem 0 #cfcfcf;
  transition: all .4s;
}

.panel-content.open{
  right: 0;
}

.panel-content .close{
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #525558;
  background-color: #E8EBF4;
  font-size: 1.8rem;
  line-height: .8;
  cursor: pointer;
  transition: all .3s;
}

.panel-content .close:hover{
  background-color: #d3d9ea;
}

.panel-spe .img-spe{
  aspect-ratio: 618/316;
  width: 100%;
  object-fit: cover;
  border-radius: 2.5rem;
}

.panel-spe .h2{
  font-size: 3.6rem;
  margin-top: 5rem;
  margin-bottom: 0;
  justify-content: flex-start;
}

.panel-spe .h2 img{
  margin-left: 0;
}

.panel-spe .desc{
  font-size: 2rem;
}

.panel-spe .button_blue{
  margin-top: 2.5rem;
  font-size: 1.4rem;
}

.panel-spe .stitre{
  font-size: 2.1rem;
  font-weight: 700;
  color: #68728B;
  margin-bottom: 2rem;
}

/* SECTION MARQUE-RDV-VALEURS */
.marque-rdv{
  margin-top: 12rem;
  background-repeat: no-repeat;
}
.marque-rdv .marque-rdv-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -3rem;
  margin-right: -3rem;
}

.marque-rdv .marque-rdv-content > div{
  width: calc(50% - 6rem);
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: -5rem;
}

.marque-rdv .h4{
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  text-transform: none;
  line-height: 1.1;
}

.marque-rdv .block-marque{
  color: #fff;
  padding: 2rem 5rem 12rem 5rem;
  margin-bottom: 5rem;
  background-position: bottom;
  background-size: contain;
  background-color: #D41D4E;
  background-repeat: no-repeat;
}

.marque-rdv .block-marque form{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3rem;
}

.marque-rdv .block-marque input{
  height: 6.8rem;
  font-weight: 500;
  padding-left: 5rem;
  color: #656975;
  border-radius: 3rem;
  border: none;
}

.marque-rdv .block-marque button[type="submit"]{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 6.6rem;
  width: 8.8rem;
  color: #D41D4E;
  background-color: #fff;
  border-radius: 3rem;
  border: .1rem solid #fff;
  cursor: pointer;
  margin-left: 1.2rem;
  transition: all .3s;
}

.marque-rdv .block-marque button[type="submit"] span{
  font-size: 3rem;
  line-height: 0;
}

.marque-rdv .block-marque button[type="submit"]:hover{
  color: #fff;
  background-color: #D41D4E;
}

.marque-rdv .block-marque .button_pink_outline {
  padding-right: 1rem;
}

.marque-rdv .block-marque .button_pink_outline .picto-next{
  font-size: 5rem;
  vertical-align: middle;
  margin-left: 1.5rem;
  opacity: .5;
}

.block-rdv{
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
}

.block-rdv .left-col{
  width: 60%;
  padding: 5rem 2rem 6rem 6rem;
  background-color: rgba(135, 120, 176, 0.6);
}

.block-rdv .left-col .text{
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 1.2rem;
}

.block-rdv .left-col a{
  display: inline-block;
  color: #D41D4E;
  background-color: #fff;
  font-weight: 500;
  font-size: 1.6rem;
  padding: 2rem;
  border-radius: 3rem;
  border: .1rem solid #fff;
  text-transform: uppercase;
}

.block-rdv .left-col a:hover{
  color: #fff;
  background-color: #D41D4E;
}

.block-rdv .right-col{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  color: #fff;
  background-color: rgba(255, 33, 94, 0.8);
  text-transform: uppercase;
}

.block-rdv .right-col .highlight{
  font-size: 5rem;
  font-weight: 500;
}

.block-rdv .right-col .lowlight{
  font-size: 2.4rem;
  font-weight: 700;
}

.block-rdv .right-col .mediumlight{
  font-size: 4.3rem;
  font-weight: 400;
}

.block-valeurs{
  text-align: right;
  max-width: 54rem;
}

.block-valeurs .h4{
  color: #D41D4E;
  font-size: 3.4rem;
  font-weight: 500;
  text-align: right;
  margin-bottom: .5rem;
}

.block-valeurs .h5{
  color: #68728B;
  font-size: 2.1rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.block-valeurs .text{
  color: #68728B;
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 4rem;
}

/* SECTION NEWS */
#news {
  margin-top: 8rem
}

#news .h2{
  display: flex;
  justify-content: center;
  align-items: center;
}

#news .h2 span{
  line-height: 1;
}

#news .h2 img{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .6rem;
  margin-left: 1.5rem;
  max-width: 41rem;
}

#news .h5{
  font-size: 2.4rem;
  font-weight: 300;
  color: #68728B;
  text-align: center;
  margin-bottom: 5rem;
}

#news .list_2img,
#news .list_4img {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
}

#news .list_2img .news_card{
  width: calc(50% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
}

#news .list_4img{
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#news .list_4img .news_card{
  width: calc(25% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
}

#news .news_img{
  transition: filter .3s;
}

#news .news_img:hover{
  filter: blur(3px);
}

#news .news_content{
  margin: 1rem 2rem;
}

#news .news_infos{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 500;
  color: #68728B;
  margin-bottom: 1.5rem;
}

#news .news_title{
  display: block;
  height: 3.8rem;
  font-size: 1.8rem;
  font-weight: 500;
  color: #D41D4E;
  overflow: hidden;
}

#news .news_title:hover{
  color: #9fa8bd;
}

#news .news_desc{
  line-height: 1.5;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

#news .news_name_store{
  text-align: center;
  font-weight: 500;
  color: #D41D4E;
  border-top: .1rem solid #D41D4E;
  padding-top: .8rem;
}

/* SECTION MO magazine */
.mo_book{
  margin-top: 10rem;
}

.mo_book .h4{
  text-align: center;
  font-size: 5rem;
  font-weight: 500;
  color: #454545;
  text-transform: none;
  margin-bottom: 1.2rem;
}

.mo_book .stitre{
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 4rem;
  line-height: 1.5;
}

.list-card-mo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -1rem;
  margin-right: -1rem;
}

.card-mo{
  width: calc(33% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
}

.card-mo a{
  transition: filter .3s;
}

.card-mo a:hover{
  filter: blur(3px);
}

.numero-mo{
  color: #525558;
  font-size: 2.4rem;
  font-weight: 500;
  margin-top: .6rem;
}

.numero-mo .icon{
  font-size: 3.5rem;
}

.card-mo .titre{
  text-transform: uppercase;
  margin-top: 1rem;
}

/* SECTION Abonnement NL */
.newsletter_container{
  display: flex;
  align-items: center;
  height: 32rem;
  margin-top: 6rem;
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  background-repeat: no-repeat;
  background-size: cover;
}

.newsletter_container .newsletter_content{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.newsletter_container .icon{
  display: inline-block;
  font-size: 4rem;
  vertical-align: text-top;
  margin-right: 1rem;
}

.newsletter_container form{
  display: flex;
  align-items: center;
}

.newsletter_container input[type="text"]{
  font-size: 1.6rem;
  padding-left: 5rem;
  margin-left: 2rem;
  border: none;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  height: 6.5rem;
}

.newsletter_container button[type="submit"] {
  height: 6.5rem;
  font-size: 5rem;
  font-weight: 500;
  color: #fff;
  background-color: #0EA6AF;
  border: none;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  cursor: pointer;
  transition: all .3s;
}

.newsletter_container button[type="submit"]:hover{
  color: #0EA6AF;
  background-color: #fff;
}

#msg_newsletter{
  display: flex;
  justify-content: center;
  font-size: 2rem;
}

/* SECTION Marques */
.bandeau_marques{
  margin-top: 10rem;
  margin-bottom: 8rem;
}

.slider_marques{
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: 5rem;
}

.slider_marques .slick-slide {
  margin-left: 2rem;
  margin-right: 2rem;
}

.marque_card{
  display: block;
  text-align: center;
  border-radius: 2.5rem;
  background-color: #fff;
  border: .1rem solid #E2DDDD;
  box-shadow: 0 .3rem .6rem rgba(0, 0, 0, .1);
  padding: 4rem 3rem;
  transition: box-shadow .3s;
}

a.marque_card:hover{
  box-shadow: 0 .3rem .6rem rgba(0, 0, 0, .5);
}

.marque_card figure {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 20rem;
  margin: 0 auto;
}

.marque_card figure img{
  object-fit: contain;
}

.marque_card .title{
  font-size: 2.6rem;
  font-weight: 500;
  color: #525558;
}

.marque_desc{
  line-height: 1.5;
  margin-top: 1.2rem;
}

/*************/
/* /HOMEPAGE */
/*************/

.error {
  margin: 10px 0;
  padding: 12px;
  color: #d8000c;
  background-color: #ffbaba
}

.success {
  margin: 10px 0;
  padding: 12px;
  color: #3c763d;
  border-color: #d6e9c6;
  background-color: #c6ffae
}

.kyxar,
.kyxar a {
  color: #fff!important
}

.kyxar {
  font-size: 9px;
  line-height: 25px;
  position: absolute;
  z-index: 10;
  right: 10px;
  bottom: 0;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0;
  white-space: nowrap
}

.kyxar a {
  font-size: 9px;
  position: relative;
  text-decoration: none;
  letter-spacing: 1px
}

.kyxar a:hover {
  color: #fff!important
}

.kyxar a .none {
  position: absolute;
  right: 0;
  bottom: 13px;
  overflow: hidden;
  height: 0
}

.kyxar a .none span {
  font-size: 9px;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #0ea6af
}

.kyxar a:focus .none,
.kyxar a:hover .none {
  height: 28px
}



.picto-cross {
  position: absolute;
  top: 14px;
  left: 3px;
  display: block;
  width: 24px;
  height: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  background: #000
}

.picto-cross:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 24px;
  height: 2px;
  margin: 0;
  content: '';
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  background: #000
}

.simple_title,
body .simple_title {
  font-size: 30px;
  font-weight: 400;
  margin: 20px 0;
  padding: 6px 0;
  text-align: center;
  text-transform: uppercase;
  color: #555
}

.simple_title.no_reponse {
  margin: 60px 0
}

.center_title .icon {
  font-size: 120%;
  color: #d41d4e
}

.center_title .highlight,
.highlight {
  font-weight: 700
}

#marques .pager_c {
  position: relative;
  margin-top: 20px
}

.pager_c ul {
  margin: 0;
  text-align: center
}

.pager_c li {
  display: inline-block;
  margin-left: 4px
}

.pager_c li .link,
.pager_c li a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  height: 3rem;
  width: 3rem;
  color: #000;
  background-color: #f4f4f6;
  border: .1rem solid #b7c0db;
  border-radius: 50%;
}

.pager_c li.navig .link,
.pager_c li.navig a {
  font-size: 13px;
}

.pager_c li .link .icon,
.pager_c li a .icon{
  line-height: 0;
}

.pager_c li.current .link,
.pager_c li.current .link:focus,
.pager_c li.current .link:hover,
.pager_c li.current a,
.pager_c li.current a:focus,
.pager_c li.current a:hover {
  color: #fff;
  background-color: #d02752
}

.pager_c li:not(.truncate) .link:focus,
.pager_c li:not(.truncate) .link:hover,
.pager_c li:not(.truncate) a:focus,
.pager_c li:not(.truncate) a:hover {
  background-color: #b7c0db
}

.pager_c li.truncate .link:focus,
.pager_c li.truncate .link:hover,
.pager_c li.truncate a:focus,
.pager_c li.truncate a:hover {
  cursor: pointer
}

.link {
  position: relative
}

.sub_nav_content {
  position: absolute;
  z-index: 2;
  top: 45px;
  right: 0;
  left: 0;
  overflow: hidden;
  max-height: 0;
  transition: all .1s ease-in-out
}

#marques .sub_nav_content {
  top: 33px
}

.sub_nav_content .sub_nav {
  padding: 5px;
  text-align: left;
  border: 1px solid #e3e3e3;
  background-color: #fff
}

.truncate.shownav .sub_nav_content {
  max-height: 500px
}

.truncate.shownav > .link::after,
.truncate.shownav > .link::before {
  position: absolute;
  z-index: 3;
  left: 50%;
  width: 0;
  height: 0;
  content: '';
  border-style: solid
}

.truncate.shownav > .link::before {
  bottom: -11px;
  margin-left: -6px;
  border-width: 0 6px 9px 6px;
  border-color: transparent transparent #ccc transparent
}

.truncate.shownav > .link::after {
  bottom: -12px;
  margin-left: -5px;
  border-width: 0 5px 8px 5px;
  border-color: transparent transparent #fff transparent
}

.sub_nav li {
  margin: 1px
}

.pager_c .sub_nav .link {
  font-size: 9px;
  line-height: 20px;
  width: 20px;
  height: auto;
  min-height: 20px;
  padding-right: 0;
  padding-left: 0;
  text-align: center
}

.btn_reseau_c .btn_reseaux {
  margin-top: -4px;
  margin-left: auto
}

.btn_reseau_c .text {
  font-size: 16px;
  margin-left: 10px;
  text-transform: uppercase
}

.btn_reseau_c .btn_reseaux .icon {
  font-size: 30px;
  margin: 0 5px;
  vertical-align: middle
}

.btn_reseau_c .btn_reseaux .icon-facebook-rounded {
  color: #3b5998
}

.btn_reseau_c .btn_reseaux .icon-twitter-rounded {
  color: #1da1f2
}

.btn_reseau_c .btn_reseaux .icon-linkedin-rounded {
  color: #0077b5
}

.btn_reseau_c .btn_reseaux .icon:hover {
  opacity: .8
}

.bg_content {
  padding: 10px 28px;
  background: #fff
}

.sort_by_type {
  text-align: right
}

.sort_by_tag {
  line-height: 25px
}

.sort_by_tag ul,
.sort_by_tag ul > li {
  display: inline-block;
  margin: auto
}

.text_label {
  font-size: 12px;
  font-weight: 700
}

.bar {
  height: 1px;
  margin: 10px 0;
  background: #b3b3b3
}

.type {
  font-size: 11px;
  padding: 3px 5px;
  white-space: nowrap;
  color: #fff
}

.type.all_filter {
  background-color: #35c3c8
}

.type.fournisseur {
  background-color: #248128
}

.type.cdo {
  background-color: #d41d4e
}

.type.opticien {
  background-color: #c03779
}

.tag {
  font-size: 11px;
  margin-right: 5px;
  padding: 3px 8px;
  white-space: nowrap;
  color: #555;
  border-radius: 10px;
  background-color: #d9dce5
}

.tag:focus,
.tag:hover {
  background-color: rgba(217,220,229,.8)
}

#current_news_content .tag {
  color: #fff;
  border-radius: 0
}

.sort_by_type,
.sort_container {
  line-height: 32px;
  float: left;
  min-height: 32px
}

.sort_by_type {
  float: right
}

.sort_by_type a:focus,
.sort_by_type a:hover {
  opacity: .8;
  color: #fff
}

.filtre_news {
  display: inline-block
}

.sort_container .ui-selectmenu-button {
  line-height: 32px;
  position: relative;
  display: inline-block;
  height: 32px;
  margin-left: 10px;
  padding: 0 70px 0 15px;
  text-align: left;
  border: 1px solid #000;
  border-radius: 0;
  background-color: #fff
}

.sort_container .ui-selectmenu-button:active,
.sort_container .ui-selectmenu-button:focus,
.sort_container .ui-selectmenu-button:hover {
  color: #2b2b2b;
  background-color: #f6f6f6
}

.ui-button .ui-icon {
  line-height: 20px;
  position: absolute;
  top: 6px;
  right: 3px;
  width: 20px;
  height: 20px;
  text-align: center;
  text-indent: inherit;
  background-image: none
}

.sort_container .ui-button .ui-icon:before {
  font-family: kfont!important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  display: inline-block;
  width: 0;
  height: 0;
  content: '';
  transition: all .2s ease-in-out;
  text-transform: none;
  border-width: 7px 6px 0 6px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.sort_container .ui-button.ui-selectmenu-button-open .ui-icon:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.ui-menu .ui-menu-item {
  background: #fff
}

.ui-selectmenu-text {
  font-style: italic;
  margin-right: 5px
}

.ui-selectmenu-button.ui-button {
  width: auto
}

#news_content .bloc_actu ul.list_news > li {
  visibility: hidden;
  transition-duration: .5s;
  transition-property: opacity;
  opacity: 0
}

#news_content .bloc_actu ul.list_news > li.visible {
  display: block;
  visibility: visible;
  opacity: 1
}

#news_content .bloc_actu ul.list_news > li:not(.visible) {
  width: 0;
  height: 0;
  padding: 0
}

#current_news_content .img_header .cover_bg {
  position: relative;
  height: 430px
}

#current_news_content h2 {
  margin-top: 30px
}

#current_news_content .date {
  font-family: Montserrat,sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.3;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 9px 14px;
  color: #fff
}

#current_news_content .list_mini_news .date {
  bottom: -5px;
  left: 0;
  padding: 3px 4px
}

#current_news.cdo .border_bottom,
#current_news.cdo .cover_bg .text,
#current_news.cdo .date {
  background-color: #d41d4e
}

#current_news.fournisseur .border_bottom,
#current_news.fournisseur .cover_bg .text,
#current_news.fournisseur .date {
  background-color: #248128
}

#current_news.opticien .border_bottom,
#current_news.opticien .cover_bg .text,
#current_news.opticien .date {
  background-color: #c03779
}

#current_news.cdo .date {
  display: none
}

#current_news .border_bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 8px
}

#current_news .cover_bg .text {
  font-family: Montserrat,sans-serif;
  font-size: 24px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 9px 14px;
  text-transform: uppercase;
  color: #fff
}

#current_news .cover_bg .text .highlight {
  font-weight: 700
}

#current_news .white_box {
  padding: 25px 16px;
  color: #555;
  background-color: #fff
}

#current_news h1 {
  font-size: 28px;
  font-weight: 400
}

#current_news.cdo h1 {
  color: #d41d4e
}

#current_news.fournisseur h1 {
  color: #248128
}

#current_news.opticien h1 {
  color: #c03779
}

#current_news .stitle {
  font-size: 16px;
  margin-top: 1em;
  text-transform: uppercase
}

#current_news .text {
  line-height: 1.3;
  margin-top: 20px;
  text-align: justify
}

.info {
  font-size: 10px;
  line-height: 30px;
  height: 30px;
  padding: 0 10px;
  letter-spacing: -.8px;
  text-transform: uppercase;
  color: grey;
  background: #fff
}

#slide_next,
#slide_next a,
#slide_prev,
#slide_prev a {
  position: absolute;
  top: 0;
  width: 70px;
  height: 100%
}

#slide_prev,
#slide_prev a {
  left: 0
}

#slide_next,
#slide_next a {
  right: 0
}

#slide_next a,
#slide_prev a {
  background: rgba(255,255,255,.4)
}

#slide_next a:hover,
#slide_prev a:hover {
  background: rgba(255,255,255,.8)
}

#slide_next .icon,
#slide_prev .icon {
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  -webkit-transform: translate3d(-50%,-50%,0);
  transform: translate3d(-50%,-50%,0);
  color: #35c3c8
}


/* Page Recherche magasin */
#opti_locator .header-optilocator{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  z-index: 1;
  background-position: 0 -250px;
  background-repeat: no-repeat;
  background-size: cover;
}

#recherche_mag{
  position: relative;
}

#recherche_mag .h3{
  font-size: 2.3rem;
  color: #fff;
  margin-bottom: .8rem;
}

.overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  pointer-events: none;
}

#opti_locator .newsletter_container{
  margin-top: 0;
}

#opti_locator .content-optilocator{
  display: flex;
  justify-content: space-between;
}

.content-optilocator .left-col,
.content-optilocator .right-col{
  width: calc(50% - 1.2rem);
}

.content-optilocator .left-col{
  padding-left: 8rem;
  margin-bottom: 3rem;
}

.content-optilocator .left-col header{
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
}

.content-optilocator .left-col .h2{
  font-size: 2.1rem;
  color: #A0A8BF;
  margin-bottom: 1.5rem;
}

.content-optilocator .type-affichage{
  display: flex;
  align-items: center;
}

.content-optilocator .type-affichage .icon{
  font-size: 2.1rem;
  margin-left: 1rem;
  line-height: 0;
  color: #D4DBEB;
  cursor: pointer;
  transition: color .3s;
}

.content-optilocator .type-affichage .icon.active,
.content-optilocator .type-affichage .icon:hover{
  color: #9FA8BD;
}

.content-optilocator .type-affichage .icon-line{
  font-size: 2.6rem;
}

.content-optilocator .filtres-magasins{
  font-size: 1.7rem;
  color: #d41d4e;
}

.content-optilocator .filtre{
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  border: .1rem solid #d41d4e;
  padding: .5rem 1rem;
  color: #d41d4e;
  border-radius: 2rem;
  margin-left: .5rem;
  margin-right: .5rem;
  cursor: pointer;
  margin-bottom: 2.2rem;
}

.content-optilocator .filtre .icon{
  line-height: 0;
  margin-left: .8rem;
  transition: color .3s;
}

.content-optilocator .filtre:hover .icon{
  color: #a0a8bf;
}

.content-optilocator .delete-filters{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.8rem;
  height: 2.8rem;
  font-size: 1.2rem;
  color: #fff;
  background-color: #d41d4e;
  line-height: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: all .3s;
}

.content-optilocator .delete-filters:hover{
  background-color: #a0a8bf;
}

/* Présentation magasin commun présentation ligne / grille */
.mag-card{
  font-size: 1.2rem;
  font-weight: 500;
}

.mag-card .bloc-info-mag{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
}

.mag-card .distance-mag .icon{
  font-size: 2.2rem;
  margin-top: .3rem;
}

.mag-card .ouverture-mag{
  text-align: center;
  line-height: 1.4;
  width: 10rem;
}

.mag-card .ouverture-mag .statut-ouverture{
  text-transform: uppercase;
}

.mag-card .ouverture-mag .statut-ouverture.mag-opened{
  color: #5EB52C;
}

.mag-card .ouverture-mag .statut-ouverture.mag-closed{
  color: #d93434;
}

.mag-card a{
  color: #68728b;
  text-align: center;
  line-height: 1.3;
}

.mag-card a .icon{
  font-size: 2rem;
}

.mag-card a:hover{
  color: #000;
}

/* Présentation magasin en ligne */
.liste-mag-line .mag-card{
  position: relative;
  border-radius: 2.2rem;
  background-color: #F4F4F6;
  margin-bottom: 2rem;
  width: 100%;
}

.liste-mag-line .mag-card .mag-card-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem 8rem 1.4rem 1.4rem;
  height: 11.1rem;
}

.liste-mag-line .mag-card .mag-photo{
  width: 12rem;
}

.liste-mag-line .mag-card .mag-photo img{
  max-height: 7.2rem;
  max-width: 12rem;
  object-fit: contain;
  border-radius: 1rem;
}

.liste-mag-line .mag-card .name-mag{
  width: calc(100% - 50rem);
}

.liste-mag-line .mag-card .h3{
  font-size: 2rem;
  text-transform: none;
}

.liste-mag-line .mag-card .mag-city{
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}

.liste-mag-line .mag-card .mag-city .icon{
  margin-right: .2rem;
}

.liste-mag-line .mag-card .see-more-mag{
  position: absolute;
  top: .6rem;
  bottom: .6rem;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 9rem;
  font-size: 1.6rem;
  border-left: .1rem solid #C6C9D3;
  line-height: 0;
}

.liste-mag-line .mag-card.detail-open .mag-photo{
  display: none;
}

.liste-mag-line .mag-card.detail-open .name-mag{
  width: calc(100% - 34.8rem);
}

.mag-card .mag-card-detail{
  height: 0;
  overflow: hidden;
}

.mag-card .mag-card-detail-content{
  margin-top: 1.2rem;
  padding: 2.2rem 1.4rem 1.4rem 1.4rem;
  display: flex;
  justify-content: space-between;
}

.mag-card .mag-card-detail-content .left-col-detail{
  width: calc(50% - 3rem);
}

.mag-card .mag-card-detail-content .right-col-detail{
  width: calc(50% - 3rem);
}

.mag-card .mag-card-detail .photo-mag-detail{
  aspect-ratio: 828 / 221;
  width: 100%;
}

.mag-card .mag-card-detail .h3{
  text-transform: uppercase;
  font-size: 1.4rem;
}

.mag-card .mag-card-detail .detail-adresse{
  font-size: 1.4rem;
  line-height: 1.2;
}

.mag-card .mag-card-detail .detail-tel{
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.mag-card .mag-card-detail .detail-tel .icon{
  margin-right: .6rem;
}

.mag-card .mag-card-detail .liste-spe{
  margin-bottom: 1.5rem;
}

.mag-card .mag-card-detail .liste-spe .spe{
  display: inline-block;
  font-size: 1.2rem;
  color: #fff;
  background-color: #A0A8BF;
  padding: .8rem;
  border-radius: .5rem;
  margin-bottom: 0.4rem;
}

.mag-card .mag-card-detail .button_outline {
  color: #d41d4e;
  margin-bottom: .8rem;
  text-align: left;
}

.mag-card .mag-card-detail .button_outline:focus,
.mag-card .mag-card-detail .button_outline:hover {
  color: #fff;
}

.mag-card .mag-card-detail .right-col-detail .h3{
  margin-bottom: 2rem;
}

.mag-card .mag-card-detail .ligne-horaires{
  margin-bottom: 1.5rem;
}

.mag-card .mag-card-detail .ligne-horaires .day{
  display: inline-block;
  font-weight: 700;
  width: 8rem;
}

.mag-card .mag-card-detail .ligne-horaires .matin{
  display: inline-block;
  width: 9rem;
}

.mag-card .mag-card-detail .liste-services{
  display: flex;
  margin-top: 3.5rem;
  text-transform: uppercase;
  text-align: center;
  margin-left: -1rem;
  margin-right: -1rem;
}

.mag-card .mag-card-detail .liste-services .service{
  max-width: 10rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.mag-card .mag-card-detail .liste-services .icon{
  font-size: 2.5rem;
  margin-bottom: .5rem;
}

/* Présentation magasin en grille */
.liste-mag-content{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.liste-mag-grid .mag-card{
  width: calc(100% / 3 - 1.5rem);
}

.liste-mag-grid .mag-card{
  width: calc(100% / 3 - 1.5rem);
}

.liste-mag-grid .mag-card .mag-photo img{
  width: 100%;
  border-radius: 1rem;
  aspect-ratio: 260 / 170;
}

.liste-mag-grid .mag-card .h3{
  font-size: 1.8rem;
}

.liste-mag-grid .mag-card .mag-city{
  font-size: 1.2rem;
}

.liste-mag-grid .mag-card .details-grille{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 8rem;
}





.content-optilocator #map{
  min-height: 120rem;
  height: 100%;
}

.content-optilocator .marker-cluster div{
  background-image: url(../img/marker-generic-grey.svg);
  background-size: contain;
}

.content-optilocator .leaflet-popup a{
  color: #d41d4e;
}

.content-optilocator .leaflet-popup a .name{
  text-transform: uppercase;
}

.content-optilocator .leaflet-popup-content-wrapper{
  padding: 1rem 1rem 1.5rem 1rem;
  width: 16rem;
  height: auto;
}

.content-optilocator .leaflet-popup a.link_minisite{
  margin-top: .4rem;
  display: block;
}

.content-optilocator .leaflet-container a.leaflet-popup-close-button{
  color: #68728b;
  z-index: 9;
  right: -1rem;
}

/* Page Marques */
#marques .header-marques{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 2000 / 405;
}

#marques .header-marques .h3{
  font-size: 5rem;
  margin-bottom: 2rem;
}

#marques .h2{
  font-size: 3.2rem;
  color: #A0A8BF;
  margin-top: 4rem;
  margin-bottom: 5rem;
}

#marques .liste-marques{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 5rem;
}

#marques .liste-marques .marque_card_container{
  width: calc(25% - 1.5rem);
}


#home #filtres_boutiques {
  background-color: rgba(255,255,255,.9);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity .3s
}

#home .open #filtres_boutiques {
  visibility: visible;
  opacity: 1
}

#home #filtres_boutiques .list_checkbox {
  width: calc(100% - 250px);
  height: 153px;
  padding-top: 5px
}

#filtres_boutiques .list_services {
  width: calc(100% / 3)
}

#filtres_boutiques .list_specialities {
  width: calc(200% / 3)
}

#filtres_boutiques .list_checkbox .checkbox {
  width: 250px;
  margin-left: 0;
  color: #7e828c
}

#filtres_boutiques .list_checkbox .list_specialities .checkbox {
  display: inline-block
}

#filtres_boutiques .list_checkbox ul {
  display: inline-block;
  margin-top: .2em;
  text-align: left;
  vertical-align: top
}

#filtres_boutiques .list_checkbox .title {
  text-transform: uppercase
}

#filtres_boutiques .list_checkbox ul .sep_dotted,
#recherche_boutique .list_checkbox ul .sep_dotted {
  width: 80%;
  height: 1px;
  margin-bottom: 2px;
  padding-bottom: 2px;
  border-bottom: 1px dotted #7e828c
}

#filtres_boutiques .list_checkbox ul:nth-child(2) .sep_dotted,
#recherche_boutique .list_checkbox ul:nth-child(2) .sep_dotted {
  width: 95%
}

#home #filtres_boutiques .list_checkbox .checkbox {
  text-align: left
}

#filtres_boutiques input[type=checkbox]:checked + label::after {
  font-family: kfont;
  font-size: inherit;
  content: '\EA39'!important;
  color: #737373;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

#filtres_boutiques .list_checkbox label::before {
  top: 4px;
  left: 0
}

#filtres_boutiques .list_selects {
  display: inline-block;
  vertical-align: top
}

#home #filtres_boutiques .list_selects {
  padding-top: 20px
}

#home #filtres_boutiques .list_selects .findus {
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  display: none;
  width: 200px;
  height: 32px;
  margin: 0 auto;
  padding-left: 15px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-align: left;
  color: #fff;
  border: 0;
  background-color: #35c3c8
}

#home #filtres_boutiques .list_selects .findus:hover {
  background-color: #00a4a9
}

#filtres_boutiques .list_selects select {
  font-style: italic;
  display: block;
  width: 200px;
  height: 32px;
  margin-bottom: 15px;
  padding-left: 10px;
  color: #7e828c
}

#filtres_boutiques .list_button {
  display: inline-block;
  margin-left: 50px;
  vertical-align: top
}

.bandeau_landing {
  overflow: hidden;
  max-height: 250px
}

.bandeau_landing > img {
  width: 100%
}

.landing_background {
  padding: 10px 10px;
  background-color: #fef3f9
}

.landing_img {
  max-width: 500px
}

@media (max-width:1500px) {
  .list_mini_news .abs_container .sub_title {
    font-size: 12px
  }

  .list_mini_news .abs_container p {
    margin-top: 5px
  }
}

@media (min-width:1250px) {
  .footer_menu li {
    text-align: left
  }

  #slogan {
    display: block
  }
}

@media screen and (max-width:1249px) {
  #logo {
    top: 15px;
    left: 15px;
    width: 320px
  }
}

@media only screen and (min-width :1215px) {
  .list_mini_news.list_news .abs_container .second_block {
    padding-top: 40%
  }
}

@media only screen and (max-width :1214px) {
  .container {
    width: 980px
  }

  #brand_header .container {
    width: 1055px
  }

  #header {
    height: 72px
  }

  #logo {
    top: 8px;
    left: 10px;
    width: 310px
  }

  #primary_nav_wrap ul a {
    margin: 0 2px
  }

  #primary_nav_wrap ul > li {
    margin-left: 12px
  }

  #primary_nav_wrap > ul > li:last-child {
    top: -32px;
    right: 10px
  }

  #primary_nav_wrap > ul > li .text-menu-access {
    font-size: 12px
  }

  #primary_nav_wrap > ul > li .mag_favo::before {
    font-size: 22px;
    margin-right: 2px
  }

  #recherche_boutique .bg_dark input.code_post {
    width: 230px
  }

  #recherche_boutique .bg_dark input.marque_pref {
    width: 260px
  }

  #recherche_boutique .bg_dark .expand_box #filtres_boutiques {
    width: calc(100% - 55px)
  }

  #filtres_boutiques .list_checkbox .checkbox {
    width: 210px
  }

  #recherche_boutique .bg_dark + .bg_dark .button_blue {
    width: 276px
  }

  .list_mini_news .abs_container .hover_block .icon {
    margin-top: 96px
  }

  #active_news .tags_news_c {
    max-width: 240px;
    height: 74px
  }

  .list_product li {
    width: 270px!important;
    margin-right: 15px
  }

  #brand_header .head_pres {
    height: 187px
  }

  #marques #brand_search li.filter {
    margin-right: 5px
  }

  #marques #brand_search li.sep_hori {
    padding-right: 7px
  }

  #marques #brand_search li.sep_hori + li {
    margin-left: 7px
  }

  #marques #brand_search li:last-child {
    margin-right: 0
  }

  #marque .prod_slider_container.no_slider {
    max-height: none;
    text-align: center
  }

  #marque .prod_slider_container.no_slider .list_product li {
    width: 280px!important
  }
}

@media only screen and (max-width:1150px) {
  #brand_header .container,
  .container {
    width: 971px
  }

  #marques #brand_header {
    height: 305px
  }

  #marques .head_pres.master {
    height: 90px
  }

  #marques .head_pres.master.expand {
    height: 182px
  }

  #marques #brand_header .head_pres {
    padding: 10px
  }
}

@media only screen and (max-width :991px) {
  #brand_header .container,
  .container {
    width: 740px
  }

  .mobile {
    display: block
  }

  .desktop {
    display: none
  }

  #logo {
    top: 8px;
    right: 10px;
    left: auto
  }

  #menu_launcher {
    display: block
  }

  #primary_nav_wrap ul {
    margin: 0;
    padding: 0;
    list-style: none
  }

  #primary_nav_wrap ul li .text-menu-access,
  #primary_nav_wrap ul li:not(:last-child) {
    display: none
  }

  #header.sticky #logo_sticky {
    position: absolute;
    top: 6px;
    right: 0;
    left: 0;
    display: block;
    width: 200px;
    margin-right: auto;
    margin-left: auto
  }

  #header.sticky #logo {
    display: none
  }

  #primary_nav_wrap ul li .mag_favo:before {
    font-size: 30px;
    position: absolute;
    top: 13px;
    left: 65px;
    transition: all .3s ease-in-out
  }

  #primary_nav_wrap ul li .mag_favo:hover:before {
    color: #9fa8bf
  }

  #primary_nav_wrap > ul > li .mag_favo {
    display: none
  }

  .contact_footer {
    margin-right: 60px
  }

  #infos_footer {
    padding-right: 48px
  }

  .menu_footer ul.main_menu > li {
    margin-right: 30px
  }

  #recherche_boutique .search_title {
    letter-spacing: 2px
  }

  #home #filtres_boutiques .list_checkbox {
    width: calc(100% - 220px);
    height: 249px
  }

  #filtres_boutiques .list_services,
  #filtres_boutiques .list_specialities {
    width: calc(100%/2)
  }

  #filtres_boutiques .list_checkbox .checkbox {
    width: 230px
  }

  .center_title {
    text-align: left
  }

  .center_title .icon {
    font-size: 160%;
    margin: 0 15px
  }

  .center_title .icon + .aheight {
    max-width: calc(100% - 75px)
  }

  .button_c_news {
    position: static;
    margin-bottom: 15px
  }

  .button_c_news .button_blue {
    line-height: 20px;
    height: 40px
  }

  .list_mini_news .abs_container .second_block {
    height: 130px
  }

  .list_mini_news .abs_container .sub_title {
    padding-top: 5px
  }

  .list_mini_news .abs_container p {
    font-size: 11px;
    margin-top: 5px
  }

  .list_mini_news .abs_container .hover_block .icon {
    margin-top: 73px
  }

  .bx-wrapper img {
    width: 80%
  }

  .list_mini_news.list_news .abs_container .second_block {
    padding-top: 60%
  }

  .list_product li {
    width: 291px!important;
    margin-right: 18px
  }

  .button_c_news .button_blue {
    font-size: 15px
  }

  .button_c_news .plus {
    font-size: 36px
  }

  #marques #results .results_box {
    padding: 5px
  }

  #marques #brand_search .head_search_box {
    margin: 0 10px
  }

  #marques .head_pres.master #brand_search .expand_box {
    padding: 0
  }

  #marques #brand_search li.verres .text_verres {
    width: 42px
  }

  #marques #brand_search li.filter {
    margin-right: 0
  }

  #marques #brand_search li.sep_hori {
    padding-right: 1px
  }

  #marques #brand_search input[type=checkbox] + label,
  #marques #brand_search input[type=radio] + label {
    font-size: 11px;
    padding-top: 1px
  }

  #marques #brand_search li.filter span.icon {
    font-size: 25px
  }

  #marques #results .results_box .list_results {
    padding: 15px
  }

  #marques #results .list_results .savoir_plus .button_blue {
    line-height: 133px;
    min-width: 0;
    height: 133px
  }

  #marque #brand_header.slider_exist {
    margin-bottom: 290px
  }

  #marque #brand_header .container {
    padding-top: 0
  }

  #marque #brand_header .slider_mag {
    position: absolute;
    right: 0;
    bottom: -380px;
    left: 0;
    padding: 0
  }

  #marque #brand_header .container .brand_logo {
    height: auto;
    margin: 15px 0;
    text-align: center
  }

  #marque #brand_header .brand_img {
    height: auto
  }

  #marque #brand_header .container .brand_logo > img {
    position: static;
    max-height: 110px;
    -webkit-transform: none;
    transform: none
  }

  #marque #brand_header .brand_img ul.list_img_mag li {
    position: static!important;
    width: 450px!important;
    margin: 0 auto 0 0
  }

  .landing_img {
    max-width: 350px
  }
}

@media (min-width:768px) {
  .visite_mobile {
    display: none
  }

  #footer a.button_pink {
    display: none
  }
}

@media only screen and (max-width :767px) {

  #brand_header .container,
  .container {
    width: calc(100% - 40px)
  }

  #logo {
    top: 8px;
    width: 300px
  }

  #header.sticky #logo_sticky {
    top: 6px;
    width: 200px
  }

  #footer .contact_footer {
    float: none;
    width: calc(100%);
    margin: 0 auto
  }

  #footer .contact_footer a {
    display: block;
    width: 100%
  }

  #footer .contact_footer {
    position: absolute;
    bottom: 100px;
    left: 0;
    margin-bottom: 60px
  }

  #footer .sep_footer_thin,
  #infos_footer {
    display: none
  }

  #footer a.button_pink {
    margin-top: 230px
  }

  #recherche_boutique {
    margin-bottom: 290px;
    transition: all .3s ease-in-out
  }

  #recherche_boutique.expand {
    margin-bottom: 480px
  }

  #recherche_boutique .search_title .highlight {
    display: block
  }

  #recherche_boutique .search_title .highlight .blue {
    font-size: 50px
  }

  #recherche_boutique .button_blue {
    width: 200px;
    margin-top: 15px
  }

  #home #filtres_boutiques .list_selects .findus {
    display: block
  }

  #recherche_boutique #filtres_boutiques .list_checkbox {
    width: 100%;
    height: 236px
  }

  #recherche_boutique #filtres_boutiques .list_selects {
    display: block;
    width: 500px;
    margin: 0 auto;
    padding-bottom: 10px
  }

  #recherche_boutique #filtres_boutiques .list_selects select {
    display: block;
    margin-right: auto;
    margin-left: auto
  }

  .center_title {
    font-size: 26px;
    margin-right: -20px;
    margin-left: -20px;
    padding-left: 20px
  }

  .visite_mobile {
    font-size: 16px;
    width: 100%;
    margin-top: -40px;
    padding: 12px 0;
    text-align: center;
    background-color: #35c3c8
  }

  .visite_mobile a {
    margin-right: 5px;
    color: #fff
  }

  #filtres_boutiques .list_selects select {
    margin-bottom: 8px
  }

  #marques #brand_header {
    height: auto;
    background-image: none!important
  }

  #marques #brand_header .container {
    margin-top: 30px;
    padding-top: 0
  }

  #marques #results {
    margin-top: 70px
  }

  #marques #brand_search .brand_favo {
    width: 100%
  }

  #marques #brand_header .head_pres {
    margin-bottom: 52px;
    padding: 0;
    background: 0 0
  }

  #marques #brand_header .head_pres.expand {
    margin-bottom: 50px
  }

  #marques .head_pres.master {
    height: 70px;
    box-shadow: 0 0 10px 0 #cfcfcf;
    -o-box-shadow: 0 0 10px 0 #cfcfcf
  }

  #marques .head_pres.master.expand {
    height: 260px
  }

  #marques #brand_header .head_search_box .plus_criteres {
    position: absolute;
    bottom: -140px;
    left: 50%;
    float: none;
    width: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center
  }

  #marques .head_pres.master.expand #brand_search .expand_box {
    height: 180px
  }

  #marques #brand_search ul {
    text-align: center
  }

  #marques #brand_search li.filter {
    margin-right: 20px;
    margin-bottom: 0
  }

  #marques #brand_search li.sep_hori {
    padding-right: 20px
  }

  #marques #brand_search .btn_blue_box {
    width: 100%
  }

  #marques #brand_search .btn_blue_box::before {
    top: 0;
    visibility: visible;
    opacity: 1
  }

  #marques #results .results_box {
    padding: 5px
  }

  #marques #results .results_box .list_results {
    padding: 5px
  }

  #marques #results .results_box .list_results .text_result {
    width: 62%;
    padding: 10px
  }

  #marques #results .results_box .list_results .savoir_plus {
    width: 20%
  }

  #marques #results .text_result .stitle {
    margin-bottom: 12px
  }

  #marques #news {
    margin-bottom: 20px
  }

  #marques .list_mini_news {
    display: none
  }

  #marques #results .list_results .savoir_plus .button_blue {
    padding: 0
  }

  #marques #results .results_box .list_results .logo_c {
    border: none
  }

  #slide_next,
  #slide_next a,
  #slide_prev,
  #slide_prev a {
    width: 20px
  }

  .prod_slider_container {
    padding: 0 20px
  }

  #current_news .cover_bg .text {
    font-size: 20px
  }

  #logo_footer {
    width: 225px
  }

  #mini_footer .right_block_footer {
    line-height: 37px;
    float: right;
    height: 37px;
    margin-right: 80px
  }

  #mini_footer .right_block_footer img {
    top: -18px;
    right: -20px;
    max-width: 80px
  }

  .landing_img {
    display: block;
    float: none;
    max-width: none;
    margin-left: auto;
    text-align: center
  }
}

@media only screen and (max-width :599px) {
  #footer #partage {
    display: none
  }

  #marques #results .results_box .list_results .logo_c {
    width: 20%
  }

  #marques #results .results_box .list_results .text_result {
    width: 80%
  }

  #marques #brand_search li.filter {
    margin-right: 10px
  }

  #marques #brand_search li.sep_hori {
    padding-right: 10px
  }

  #marques #results .results_box .list_results .savoir_plus {
    display: block;
    width: 150px;
    margin: 0 auto
  }

  #marques #results .list_results .savoir_plus .button_blue {
    line-height: 30px;
    height: 30px
  }

  #marques #results .list_results .savoir_plus .button_blue::before {
    display: none
  }

  #marques #results .results_box li:not(:last-child) {
    padding-bottom: 5px
  }

  #marque #brand_header .head_pres {
    padding: 20px
  }

  #current_news_content .img_header .cover_bg {
    height: 275px
  }

  #current_news .cover_bg .text {
    font-size: 16px;
    padding: 4px 10px
  }

  #current_news_content .date {
    font-size: 12px;
    padding: 6px 10px
  }

  #body_news .list_news li {
    display: none
  }

  #body_news .list_news li:nth-child(1),
  #body_news .list_news li:nth-child(2),
  #body_news .list_news li:nth-child(3) {
    display: block
  }
}

@media only screen and (max-width :499px) {
  .container {
    width: calc(100% - 40px)
  }

  .optilocator {
    width: 100%
  }

  #primary_nav_wrap ul li .mag_favo::before {
    left: 50px
  }

  #logo {
    top: 20px;
    right: 10px;
    width: 190px
  }

  #header.sticky #logo_sticky {
    width: 186px
  }

  #recherche_boutique {
    height: 250px;
    margin-bottom: 250px
  }

  #recherche_boutique.expand {
    margin-bottom: 550px
  }

  #home #recherche_boutique.expand {
    margin-bottom: 500px
  }

  #recherche_boutique .bg_dark {
    width: calc(100% - 15px);
    padding: 10px 0 12px 0
  }

  #recherche_boutique .search_title,
  #recherche_boutique .search_title .highlight {
    font-size: 22px
  }

  #recherche_boutique .bg_dark .expand_box {
    width: 100%
  }

  #recherche_boutique.expand .bg_dark .expand_box {
    height: 500px
  }

  #recherche_boutique #filtres_boutiques .list_checkbox .checkbox {
    width: 100%
  }

  #home #recherche_boutique #filtres_boutiques .list_checkbox .checkbox {
    line-height: 24px
  }

  #recherche_boutique .white_box a.expand_notice {
    width: calc(100% - 25px)
  }

  #recherche_boutique #filtres_boutiques ul {
    width: calc(100%);
    margin-bottom: 0
  }

  #recherche_boutique .bg_dark .expand_box #filtres_boutiques {
    padding-left: 4px
  }

  #recherche_boutique input[type=checkbox] + label,
  #recherche_boutique input[type=radio] + label {
    line-height: 15px;
    height: 22px;
    margin-bottom: 2px;
    vertical-align: middle
  }

  #recherche_boutique #filtres_boutiques .list_selects {
    width: auto;
    margin-top: 4px;
    padding-top: 10px
  }

  #home #recherche_boutique #filtres_boutiques .list_checkbox {
    height: 326px;
    padding-top: 0
  }

  #recherche_boutique #filtres_boutiques .list_checkbox label::before {
    top: 0
  }

  #recherche_boutique input[type=radio]:checked + label::after {
    top: 3px
  }

  #recherche_boutique .bg_dark:first-child {
    margin: 50px auto
  }

  #recherche_boutique .bg_dark input.code_post,
  #recherche_boutique .bg_dark input.marque_pref {
    padding-left: 15px
  }

  #recherche_boutique .bg_dark + .bg_dark .button_blue,
  #recherche_boutique .bg_dark .search_opticien input {
    width: 100%
  }

  #recherche_boutique .button_blue .icon-marker {
    font-size: 22px
  }

  #recherche_boutique .button_blue {
    font-size: 10px;
    line-height: 35px;
    width: 160px;
    min-width: 0;
    height: 35px
  }

  .center_title {
    font-size: 15px;
    margin-right: -30px;
    margin-left: -30px;
    padding-left: 25px
  }

  .center_title .icon {
    font-size: 170%;
    margin: 0 5px
  }

  .visite_mobile {
    margin-top: 0
  }

  .acces_mobile_boutique .btn_boutique_mobile {
    width: 50%
  }

  .acces_mobile_boutique .btn_boutique_mobile:nth-child(odd) {
    text-align: left
  }

  .acces_mobile_boutique.no_rdv .btn_boutique_mobile:nth-child(odd) {
    text-align: right
  }

  .acces_mobile_boutique .btn_boutique_mobile:nth-child(even) {
    text-align: right
  }

  .acces_mobile_boutique.no_rdv .btn_boutique_mobile:nth-child(even) {
    text-align: left
  }

  .acces_mobile_boutique .btn_boutique_mobile a {
    width: calc(100% - 5px);
    padding-left: 5px
  }

  .acces_mobile_boutique .btn_boutique_mobile a .icon {
    padding-right: 5px
  }

  .checkbox_style label::after,
  .checkbox_style label::before {
    top: 4px
  }

  #filtres_boutiques .checkbox_style label::before,
  .checkbox_style label::after {
    top: 0
  }

  .sub_nav_content {
    top: 33px
  }

  #marques #results .title {
    font-size: 24px;
    margin-bottom: 18px
  }

  #marques .head_pres.master.expand #brand_search .expand_box {
    height: auto
  }

  #marques .head_pres.master.expand {
    height: auto
  }

  #marques #brand_search li.sep_hori {
    padding-right: 6px
  }

  #marques #brand_search li.filter {
    margin-right: 6px
  }

  #marques #results .results_box .list_results .logo_c {
    line-height: 1;
    width: 100%;
    height: auto;
    margin-top: 10px
  }

  #marques #results .results_box .list_results .text_result {
    width: 100%
  }

  #marques #results .text_result .title {
    font-size: 22px
  }

  #marques #results .text_result .stitle {
    font-size: 13px
  }

  #marques #results .text_result .stitle {
    margin-bottom: 5px
  }

  #marque #brand_header .head_pres .stitle {
    font-size: 30px;
    margin-bottom: 8px
  }

  .news_and_vid .list_mini_news {
    display: none
  }

  #body_news #current_news .cover_bg .text,
  #body_news #current_news_content .date {
    font-size: 10px;
    padding: 5px
  }

  #body_news #current_news h1 {
    font-size: 22px
  }

  #logo_footer {
    width: 220px;
    margin-top: 10px
  }

  #footer #mini_footer .lien_fb {
    position: absolute;
    top: 0;
    right: 5px
  }

  #footer #mini_footer .right_block_footer {
    display: none
  }

  #footer a.button_pink {
    margin-top: 220px
  }

  #footer a.button_pink {
    margin-bottom: 15px
  }

  .list_mini_news.list_news .abs_container .second_block {
    padding-top: 55%
  }

  .sort_by_type {
    line-height: 24px;
    margin-top: 5px
  }
}