@charset "UTF-8";
/* Bootstrap 4
.col- (extra small devices - screen width less than 576px)
.col-sm- (small devices - screen width equal to or greater than 576px)
.col-md- (medium devices - screen width equal to or greater than 768px)
.col-lg- (large devices - screen width equal to or greater than 992px)
.col-xl- (xlarge devices - screen width equal to or greater than 1200px)
*/
/* Bootstrap 4
.col- (extra small devices - screen width less than 576px)
.col-sm- (small devices - screen width equal to or greater than 576px)
.col-md- (medium devices - screen width equal to or greater than 768px)
.col-lg- (large devices - screen width equal to or greater than 992px)
.col-xl- (xlarge devices - screen width equal to or greater than 1200px)
*/
.pink,
.color1,
.color1 a {
  color: #D22350; }

.blue,
.color2,
.color2 a {
  color: #0ea6af; }

.grey {
  color: #9fa8bd; }

@font-face {
  font-family: Poppins;
  src: local("Poppins Light"), local("Poppins-Light"), url(../fonts/Poppins-Light.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Poppins;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(../fonts/Poppins-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Poppins;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(../fonts/Poppins-Medium.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Poppins;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(../fonts/Poppins-Bold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

body,
button,
fieldset,
form,
html,
textarea {
  font-family: Poppins,sans-serif; }

body,
html {
  scroll-behavior: smooth; }

html {
  font-size: 62.5%; }

body {
  font-size: 1.6rem;
  color: #68728b;
  font-weight: 300; }

.container {
  position: relative;
  max-width: 130rem;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto; }
  .container::before, .container::after {
    content: none; }
  .container.container-xl {
    max-width: 1760px; }

hr {
  color: #d4d7d9;
  border: 1px inset #d4d7d9; }

.fixed {
  position: fixed; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static;
  overflow: hidden; }

.hyphenate {
  text-align: left;
  -webkit-hyphens: auto;
  hyphens: auto;
  -ms-hyphens: auto; }

.inline {
  display: inline; }

.inlineblock {
  display: inline-block; }

.block {
  display: block; }

.hidden,
.masque,
figcaption {
  display: none; }

.floatleft {
  float: left; }

.floatright {
  float: right; }

.floatnone {
  float: none; }

.textleft {
  text-align: left; }

.textright {
  text-align: right; }

.textcenter {
  text-align: center; }

.textjustify {
  text-align: justify; }

.width80 {
  width: 80%; }

.width60 {
  width: 60%; }

.width50 {
  width: 50%; }

.width47 {
  width: 47%; }

.width45 {
  width: 45%; }

.width35 {
  width: 35%; }

.width33 {
  width: 33.33333333%; }

.width25 {
  width: 25%; }

.margin0 {
  margin: 0; }

.marginauto {
  margin: auto; }

.lineh1 {
  line-height: 1; }

.lineh12 {
  line-height: 1.2; }

.lineh15 {
  line-height: 1.5; }

.lineh25 {
  line-height: 2.5; }

.height465 {
  height: 465px; }

.marginright {
  margin-right: 1em; }

.marginleft {
  margin-left: 1.5em; }

.marginbottom {
  margin-bottom: 2em !important; }

.marginbottomlight {
  margin-bottom: 1em; }

.margintopheavy {
  margin-top: 4em; }

.margintop {
  margin-top: 2em; }

.margintoplight {
  margin-top: 2em; }

.margintopultralight {
  margin-top: .5em; }

.margintop-2 {
  margin-top: -2em; }

.container900 {
  max-width: 900px;
  margin: 0 auto; }

.padding10 {
  padding: 10px; }

.paddingtopbot20 {
  padding: 20px 0; }

.padding1020 {
  padding: 10px 20px; }

.padding25 {
  padding: 25px; }

.padding30 {
  padding: 30px; }

.padding40 {
  padding: 40px; }

.padding60 {
  padding: 60px; }

.padding6040 {
  padding: 60px 40px; }

.padding7040 {
  padding: 70px 40px; }

.padding4070 {
  padding: 40px 3%; }

.padding4015 {
  padding: 40px 15px; }

.vmiddle {
  vertical-align: middle; }

.vtop {
  vertical-align: top; }

.vbottom {
  vertical-align: bottom; }

strong,
.bold {
  font-weight: 700; }

.normal {
  font-weight: 400; }

.mini,
.fontsize80 {
  font-size: 80%; }

.fontsize85 {
  font-size: 85%; }

.fontsize90 {
  font-size: 90%; }

.fontsize110 {
  font-size: 110%; }

.big,
.fontsize120 {
  font-size: 120%; }

.margin0auto {
  display: block;
  margin: 0 auto; }

.lowercase {
  text-transform: lowercase; }

.uppercase {
  text-transform: uppercase; }

.mobile {
  display: none; }

.desktop {
  display: block; }

img {
  max-width: 100%; }

.aheight,
.centered-height {
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle; }

.clear,
.line,
.row,
.wrap {
  clear: both; }

.clearfix:after,
.clearfix:before {
  display: block;
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  content: '.'; }

.clearfix:after {
  clear: both; }

.bg_cover {
  background-position: center center;
  background-size: cover; }

.overlay-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10; }

.error {
  margin: 10px 0;
  padding: 12px;
  color: #d8000c;
  background-color: #ffbaba; }

.success {
  margin: 10px 0;
  padding: 12px;
  color: #3c763d;
  border-color: #d6e9c6;
  background-color: #c6ffae; }

a, .lien-off {
  transition: background-color .2s ease-in,color .2s ease-in,box-shadow .2s ease-in,opacity .2s ease-in,border .2s ease-in;
  text-decoration: none;
  color: #000; }

a:active,
a:focus,
a:hover,
.lien-off:active,
.lien-off:focus,
.lien-off:hover {
  color: #706d70; }

a.button,
.lien-off.button {
  display: inline-block; }

.table,
table {
  font-size: 11px;
  border-collapse: collapse;
  color: #3d3d3d;
  border: 1px solid #e3e3e3;
  background-color: #fff; }

.table td,
.table th,
table td,
table th {
  padding: 5px 10px;
  border: 1px solid #e3e3e3; }

.table th,
table th {
  padding: 10px 10px; }

.header-page-classic {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 2rem;
  padding-right: 2rem; }
  .header-page-classic .h3 {
    position: relative;
    color: #fff;
    z-index: 1;
    padding-left: .8rem;
    padding-right: .8rem; }
  .header-page-classic .custom-select::after {
    font-size: 1.6rem;
    font-weight: 500;
    right: 1.5rem;
    top: 2.4rem; }

/* Positionnement */
/* Limite le nombre de ligne du champ, NE PAS METTRE DE HAUTEUR */
/* Effets css */
.button1 {
  font-size: 12px;
  display: inline-block;
  width: 200px;
  padding: 15px 20px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  border-radius: 20px;
  background: #81a3bf; }

.button1:hover {
  color: #fff;
  border-radius: 10px; }

.button2 {
  font-family: Montserrat,sans-serif;
  font-size: 13px;
  display: block;
  width: 200px;
  margin: 0 auto;
  padding: 15px 20px;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  background: 0 0; }

.button2:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.7); }

.button3,
a.button3 {
  font-family: Montserrat,sans-serif;
  font-size: 13px;
  display: inline-block;
  margin: 0 auto;
  padding: 10px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  vertical-align: middle;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #feca1c;
  border-radius: 20px;
  outline: 0;
  background: #feca1c; }

.button4 {
  font-size: 12px;
  display: inline-block;
  width: 200px;
  padding: 15px 20px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  border-radius: 50px;
  background: #b56259; }

.button4:hover {
  background: #784137; }

.button5 {
  font-size: 12px;
  display: inline-block;
  width: auto;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  background: #020202; }

.button_blue {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1.8rem 3.6rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #0EA6AF;
  border: .1rem solid #0EA6AF;
  border-radius: 3rem;
  transition: all .3s ease-in-out;
  cursor: pointer; }

.button_blue:focus,
.button_blue:hover {
  color: #0EA6AF;
  background-color: #fff; }

.button_pink {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1.8rem 3.6rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #d02752;
  border: .1rem solid #d02752;
  border-radius: 3rem;
  transition: all .3s ease-in-out; }

.button_pink:focus,
.button_pink:hover {
  cursor: pointer;
  color: #d02752;
  background-color: #fff; }

.button_pink_overlay {
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  width: 235px;
  height: 26px;
  padding-top: 8px;
  transition: all .3s ease-in-out;
  text-align: center;
  text-transform: uppercase;
  color: #d02752;
  border-radius: 16px;
  background-color: #fff; }

.button_pink_overlay:focus,
.button_pink_overlay:hover {
  cursor: pointer;
  opacity: .85;
  color: #d02752; }

.button_pink_outline {
  color: #fff;
  background-color: #d02752;
  border: .1rem solid #fff;
  padding: .8rem 2rem;
  border-radius: 2.4rem;
  font-weight: 500;
  transition: all .3s; }

.button_pink_outline:focus,
.button_pink_outline:hover {
  color: #d02752;
  background-color: #fff; }

.button_grey {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1.8rem 3.6rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #4D4D4D;
  border: .1rem solid #4D4D4D;
  border-radius: 3rem;
  transition: all .3s ease-in-out;
  cursor: pointer; }
  .button_grey:active, .button_grey:hover {
    color: #4D4D4D;
    background-color: #fff; }

.button_outline {
  display: inline-block;
  font-size: 1.4rem;
  color: #d02752;
  background-color: transparent;
  border: .1rem solid #d02752;
  padding: .5rem 1rem;
  border-radius: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s; }

.button_outline:focus,
.button_outline:hover {
  color: #fff;
  background-color: #d02752; }

.big-button {
  font-size: 1.6rem;
  text-transform: none;
  font-weight: 600;
  padding: 1.8rem 2.2rem;
  margin-top: 2.5rem;
  width: auto; }

input[type=checkbox],
input[type=radio] {
  display: none; }

input[type=checkbox] + label,
input[type=radio] + label {
  line-height: 24px;
  position: relative;
  display: inline-block;
  height: 24px;
  padding-left: 24px; }

input[type=checkbox] + label::before,
input[type=radio] + label::before {
  font-family: kfont;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 4px;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin: 0;
  content: '';
  text-align: center;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff; }

input[type=radio] + label:before {
  border-radius: 50%; }

input[type=radio]:checked + label:after {
  position: absolute;
  top: 8px;
  left: 4px;
  display: block;
  width: 10px;
  height: 10px;
  content: '';
  border-radius: 50%;
  background: #333; }

input[type=checkbox]:checked + label:after {
  content: '\EA39';
  font-family: kfont;
  position: absolute;
  top: 4px;
  left: 3px;
  color: #333; }

.checkbox_style {
  line-height: 20px;
  position: relative; }

.checkbox_style input {
  position: absolute;
  visibility: hidden;
  opacity: 0; }

.checkbox_style label {
  padding-left: 20px; }

.checkbox_style label::after,
.checkbox_style label::before {
  position: absolute;
  top: 0;
  left: 1px;
  display: block;
  width: 14px;
  height: 14px;
  content: ''; }

.checkbox_style label::before {
  background-color: #fff; }

.checkbox_style input:checked + label::after {
  font-family: kfont;
  font-size: inherit;
  padding-left: 1px;
  content: '\ea39';
  color: #737373;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.custom-select {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  cursor: pointer; }
  .custom-select select {
    font-size: 1.4rem;
    font-weight: 500;
    color: #abadb4;
    background-color: #fff;
    border: none;
    appearance: none;
    padding: .4rem 2.5rem .4rem .5rem;
    cursor: pointer;
    min-width: 20rem; }
    .custom-select select option {
      font-size: 1.2rem; }
  .custom-select::after {
    font-family: kfont;
    content: "\EA02";
    display: block;
    position: absolute;
    right: .5rem;
    top: .7rem;
    font-size: 1.3rem;
    color: #abadb4;
    pointer-events: none; }
  .custom-select:focus {
    color: red; }

/* Select2 */
.select2-container--default .select2-selection--multiple {
  border: none;
  height: 6.8rem;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  cursor: pointer; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: none; }

.select2-container--default.select2-container--focus .select2-selection--multiple .counter {
  color: #656975; }

.select2-container--default .select2-search--inline .select2-search__field {
  color: #656975;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  pointer-events: none; }

.select2-container .select2-search--inline .select2-search__field {
  display: flex;
  align-items: center;
  height: 2.2rem;
  margin: 0; }

.select2-results__option--selectable {
  position: relative;
  margin-left: 3.5rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 1rem;
  color: #abadb4;
  font-size: 1.6rem;
  font-weight: 500; }
  .select2-results__option--selectable:not(:last-child) {
    border-bottom: .1rem solid #ddd; }
  .select2-results__option--selectable::before, .select2-results__option--selectable::after {
    position: absolute;
    top: 50%;
    left: -2rem;
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateY(-50%); }
  .select2-results__option--selectable::before {
    content: '';
    display: block;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    text-align: center;
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff; }
  .select2-results__option--selectable.select2-results__option--selected::after {
    content: "\EA39" !important;
    font-family: kfont;
    font-size: inherit;
    color: #737373;
    text-rendering: auto;
    margin-left: .1rem;
    margin-top: -.1rem; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 40rem;
  scrollbar-width: thin; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar {
    width: 8px; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-track {
    background-color: #eee; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-thumb {
    background-color: #ccc; }

.select2-container--default .select2-results__option--selected,
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  color: #abadb4;
  background-color: transparent; }
  .select2-container--default .select2-results__option--selected:hover,
  .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable:hover {
    color: #68728b; }

.select2-container--default .select2-results__option--selected {
  color: #68728b; }

/* Focus effect sur les inputs */
.custom-form input[type="text"],
.custom-form input[type="email"],
.custom-form input[type="password"],
.custom-form select {
  display: block;
  height: 5.5rem;
  width: 100%;
  border: .1rem solid #bbb;
  border-radius: .4rem;
  padding: 1.5rem 1.5rem .7rem 1.2rem;
  z-index: 1; }
  .custom-form input[type="text"]:disabled,
  .custom-form input[type="email"]:disabled,
  .custom-form input[type="password"]:disabled,
  .custom-form select:disabled {
    border-color: #eee; }

.custom-form select {
  padding-top: .7rem; }

.custom-form textarea {
  display: block;
  height: 20rem;
  width: 100%;
  border: .1rem solid #bbb;
  border-radius: .4rem;
  margin-top: .5rem;
  padding: 1.5rem 1.5rem .7rem 1.2rem;
  resize: none; }

.custom-form .input-effect {
  max-width: 39rem;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-bottom: 2rem;
  margin-right: auto; }
  .custom-form .input-effect .icon {
    margin-right: .6rem; }

.custom-form .focus-effect {
  padding: .7rem 1.5rem;
  border: 0.1rem solid #4B4947;
  position: relative;
  background: transparent; }

.custom-form .focus-effect ~ label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 5.5rem;
  width: 100%;
  color: #888;
  border-radius: .4rem;
  background-color: #fff;
  margin-bottom: 0;
  padding-left: 1.2rem;
  padding-top: 1.6rem;
  transition: all .2s linear;
  z-index: 0;
  letter-spacing: .5px; }

.custom-form .focus-effect:focus ~ label,
.custom-form .has-content.focus-effect ~ label {
  top: 0;
  font-size: 11px;
  color: #888;
  padding-top: .2rem;
  transition: all .2s linear; }

.custom-form .form-radio-container {
  margin-bottom: 2rem;
  max-width: 39rem;
  margin-right: auto;
  margin-left: auto; }
  .custom-form .form-radio-container .libelle {
    margin-right: 2rem; }
  .custom-form .form-radio-container input ~ input {
    margin-left: 2rem; }

.custom-form .form-checkbox-container {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto; }
  .custom-form .form-checkbox-container label {
    display: inline; }

.sep {
  height: 1px;
  margin: 15px 0;
  background-color: #d4d8da; }

#cms .container h1, #cms .container h2, #cms .container h3, #cms .container h4, #cms .container h5, #cms .container h6,
#cms .container .h1, #cms .container .h2, #cms .container .h3, #cms .container .h4, #cms .container .h5, #cms .container .h6,
.liste-content h1,
.liste-content h2,
.liste-content h3,
.liste-content h4,
.liste-content h5,
.liste-content h6,
.liste-content .h1,
.liste-content .h2,
.liste-content .h3,
.liste-content .h4,
.liste-content .h5,
.liste-content .h6,
.tox-editor-container h1,
.tox-editor-container h2,
.tox-editor-container h3,
.tox-editor-container h4,
.tox-editor-container h5,
.tox-editor-container h6,
.tox-editor-container .h1,
.tox-editor-container .h2,
.tox-editor-container .h3,
.tox-editor-container .h4,
.tox-editor-container .h5,
.tox-editor-container .h6 {
  word-wrap: normal;
  hyphens: none;
  line-height: 1; }

#cms .container h1,
#cms .container .h1,
.liste-content h1,
.liste-content .h1,
.tox-editor-container h1,
.tox-editor-container .h1 {
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.2;
  margin: .5rem 0;
  color: #D22350;
  text-align: center; }
  @media (max-width: 991px) {
    #cms .container h1,
    #cms .container .h1,
    .liste-content h1,
    .liste-content .h1,
    .tox-editor-container h1,
    .tox-editor-container .h1 {
      font-size: 3rem; } }

#cms .container h2,
#cms .container .h2,
.liste-content h2,
.liste-content .h2,
.tox-editor-container h2,
.tox-editor-container .h2 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.2;
  margin: .5rem 0;
  color: #D22350;
  text-align: center;
  font-weight: 400;
  color: #4D4D4D;
  font-size: 5rem; }
  @media (max-width: 991px) {
    #cms .container h2,
    #cms .container .h2,
    .liste-content h2,
    .liste-content .h2,
    .tox-editor-container h2,
    .tox-editor-container .h2 {
      font-size: 2.2rem; } }

#cms .container h3,
#cms .container .h3,
.liste-content h3,
.liste-content .h3,
.tox-editor-container h3,
.tox-editor-container .h3 {
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 1.2;
  margin: .5rem 0;
  color: #D22350;
  font-weight: 500;
  font-size: 4rem; }
  @media (max-width: 991px) {
    #cms .container h3,
    #cms .container .h3,
    .liste-content h3,
    .liste-content .h3,
    .tox-editor-container h3,
    .tox-editor-container .h3 {
      font-size: 2rem; } }

#cms .container h4,
#cms .container .h4,
.liste-content h4,
.liste-content .h4,
.tox-editor-container h4,
.tox-editor-container .h4 {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  margin: .6em 0;
  text-align: left;
  color: #0ea6af;
  text-transform: none;
  font-weight: 400;
  font-size: 3.2rem;
  color: #4D4D4D; }
  @media (max-width: 991px) {
    #cms .container h4,
    #cms .container .h4,
    .liste-content h4,
    .liste-content .h4,
    .tox-editor-container h4,
    .tox-editor-container .h4 {
      font-size: 1.2rem; } }

#cms .container h5,
#cms .container .h5,
.liste-content h5,
.liste-content .h5,
.tox-editor-container h5,
.tox-editor-container .h5 {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase; }

#cms .container h6,
#cms .container .h6,
.liste-content h6,
.liste-content .h6,
.tox-editor-container h6,
.tox-editor-container .h6 {
  font-size: 1.2rem;
  color: #0ea6af;
  margin: .6rem 0;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  text-align: center; }

#cms {
  line-height: 1.3; }

a, .lien-off {
  transition: all .3s ease-in-out;
  text-decoration: none; }

.lien-off {
  cursor: pointer; }

.cms ol,
.mce-content-body ol,
.product .text ol {
  margin-left: 15px;
  list-style-type: decimal; }

.cms ul,
.mce-content-body ul {
  margin-left: 15px;
  list-style-type: square; }

.cms ul li,
.mce-content-body ul li {
  margin: 4px auto; }

.cms img {
  height: auto; }

#cms p {
  line-height: 1.3em;
  margin: .6em auto; }

#grid_builder.overlay_bg,
.cover_bg {
  position: relative;
  background-position: center center;
  background-size: cover; }

#grid_builder.overlay_bg:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  content: '';
  background: rgba(255, 255, 255, 0.9); }

@media screen and (max-width: 767px) {
  .h1,
  .titre,
  h1 {
    font-size: 24px; }
  .h2,
  .stitre,
  h2 {
    font-size: 22px; }
  .h3,
  .sstitre,
  h3 {
    font-size: 16px; }
  .h4,
  .ssstitre,
  h4 {
    font-size: 14px; } }

#header {
  display: flex;
  align-items: center;
  position: relative;
  height: 12.5rem;
  background-color: rgba(255, 255, 255, 0.76);
  z-index: 10; }

#home #header {
  display: block;
  padding-top: 3.1rem;
  height: 12.5rem; }

#header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: scale(0);
  animation-name: smoothUnSticky;
  animation-duration: .4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow: hidden; }

#logo {
  max-width: 35rem; }
  #logo img {
    object-fit: contain; }
  #logo .logo-tablet,
  #logo .logo-sticky {
    display: none; }

#header.sticky {
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  border-bottom: solid 1px #9fa8bf;
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
  padding-top: 0; }
  #header.sticky .container {
    transform: scale(1); }
  #header.sticky #logo {
    max-width: 35rem;
    height: auto; }
  #header.sticky .logo-classique {
    display: none; }
  #header.sticky .logo-sticky {
    display: block; }

#home #header.sticky,
#header.sticky,
#header.sticky .container {
  height: 0;
  padding-top: 0;
  animation-name: smoothSticky;
  animation-duration: .4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow: hidden; }

@keyframes smoothSticky {
  from {
    height: 0; }
  to {
    height: 5rem; } }

@keyframes smoothUnSticky {
  from {
    transform: scale(0); }
  to {
    transform: scale(1); } }

#header.sticky + .content {
  margin-top: 13rem; }

#header.sticky #logo {
  max-width: 22rem; }

@media (max-width: 1499px) {
  #logo {
    max-width: 25rem; }
  #home #header {
    height: 14rem; }
  #home #header.sticky + .content {
    margin-top: 14rem; } }

@media (max-width: 1199px) {
  #logo {
    max-width: 20rem; }
  #home #header {
    height: 8.4rem;
    padding-top: 2.5rem; }
  #home #header.sticky + .content {
    margin-top: 8.4rem; } }

@media (max-width: 991px) {
  #logo {
    max-width: 25rem; }
    #logo .logo-classique {
      display: none; }
    #logo .logo-tablet {
      display: block; }
  #header.sticky .logo-tablet {
    display: none; }
  #home #header {
    padding-top: 1.8rem; }
  #header .container {
    justify-content: flex-end; } }

@media (max-width: 575px) {
  #logo {
    max-width: 20rem; } }

#footer {
  position: relative;
  color: #fff;
  background-color: #4D4D4D;
  padding-top: 4.2rem; }

.footer-content {
  display: flex;
  justify-content: space-between; }

#logo_footer {
  width: 28rem;
  margin-bottom: 4rem; }

.menu_footer_title > *,
.footer-content a,
.footer-content .lien-off {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
  transition: all .3s; }

#footer .menu_footer_title > *,
#footer .lien_footer a,
#footer .menu-footer-picto a,
#footer .lien_footer .lien-off,
#footer .menu-footer-picto .lien-off {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1.6rem;
  font-size: 1.4rem; }

#footer .lien_sociaux_footer .lien_sociaux_footer_titre {
  margin-bottom: 1.2rem;
  text-transform: uppercase;
  font-weight: 500; }

#footer .lien_sociaux_footer a .icon,
#footer .lien_sociaux_footer .lien-off .icon {
  display: inline-block;
  font-size: 3rem;
  line-height: 0;
  transition: color .3s;
  margin-right: 1rem; }
  #footer .lien_sociaux_footer a .icon:hover,
  #footer .lien_sociaux_footer .lien-off .icon:hover {
    color: #D22350; }

.sep_footer {
  height: 1px;
  margin-bottom: 15px;
  background-color: #838da3; }

.menu_footer .main_menu {
  display: flex; }

.menu_footer ul.sub_menu {
  margin-top: 0; }

.menu_footer ul.sub_menu > li a,
.menu_footer ul.sub_menu > li .lien-off {
  font-weight: 400; }

.menu_footer ul.sub_menu > li a .icon,
.menu_footer ul.sub_menu > li .lien-off .icon {
  font-size: 2.4rem;
  margin-right: 1rem; }
  .menu_footer ul.sub_menu > li a .icon::before,
  .menu_footer ul.sub_menu > li .lien-off .icon::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-size: 20px 20px; }

.menu_footer ul.sub_menu > li {
  display: block;
  margin-bottom: 1.2rem;
  margin-left: 0; }

.lien_footer a,
.menu_footer ul.sub_menu > li a,
.menu_footer ul.sub_menu > li .lien-off {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 1.4rem; }

.lien_footer a::after,
.menu_footer ul.sub_menu > li a::after,
.menu_footer ul.sub_menu > li .lien-off::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 1px;
  content: '';
  transition: all .3s;
  background-color: #fff; }

.lien_footer a:hover::after,
.menu_footer ul.sub_menu > li a:hover::after,
.lien_footer .lien-off:hover::after,
.menu_footer ul.sub_menu > li .lien-off:hover::after {
  width: 100%; }

.menu-footer-legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1.5rem; }

.menu-footer-legal a,
.menu-footer-legal .lien-off {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400; }

.menu-footer-legal a:hover,
.menu-footer-legal .lien-off:hover {
  color: #9fa8bd; }

.liens-villes-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1.5rem;
  font-size: 1.2rem; }
  .liens-villes-footer a, .liens-villes-footer .lien-off {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 400; }
    .liens-villes-footer a:hover, .liens-villes-footer .lien-off:hover {
      color: #9fa8bd; }

.kyxar,
.kyxar a,
.kyxar .lien-off {
  color: #fff !important; }

.kyxar {
  font-size: 9px;
  line-height: 25px;
  position: absolute;
  z-index: 10;
  right: 10px;
  bottom: 0;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0;
  white-space: nowrap; }

.kyxar a,
.kyxar .lien-off {
  font-size: 9px;
  position: relative;
  text-decoration: none;
  letter-spacing: 1px; }

.kyxar a:hover,
.kyxar .lien-off:hover {
  color: #fff !important; }

.kyxar a .none,
.kyxar .lien-off .none {
  position: absolute;
  right: 0;
  bottom: 13px;
  overflow: hidden;
  height: 0; }

.kyxar a .none span,
.kyxar .lien-off .none span {
  font-size: 9px;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #0ea6af; }

.kyxar a:focus .none,
.kyxar a:hover .none,
.kyxar .lien-off:focus .none,
.kyxar .lien-off:hover .none {
  height: 28px; }

@media (max-width: 991px) {
  .footer-content {
    flex-wrap: wrap;
    text-align: center; }
    .footer-content .logo_footer_c {
      width: 100%; }
    .footer-content #logo_footer {
      margin-bottom: 2rem;
      margin-left: auto;
      margin-right: auto; }
    .footer-content .menu_footer {
      width: 50%;
      margin-top: 2rem; }
  #footer {
    padding-bottom: 1.5rem; }
    #footer .lien_footer a,
    #footer .menu-footer-picto a {
      margin-bottom: 1.2rem; } }

@media (max-width: 575px) {
  .footer-content #logo_footer {
    width: 26rem;
    margin-bottom: 2rem; }
  .footer-content .menu_footer {
    width: 100%; } }

#primary_nav_wrap ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 -2.2rem; }

#primary_nav_wrap ul > li {
  position: relative;
  transition: all .3s ease-in-out;
  text-align: center;
  margin-left: 2.2rem;
  margin-right: 2.2rem; }

#primary_nav_wrap ul > li a {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: #4D4D4D;
  transition: color .3s; }

#primary_nav_wrap ul > li.current a {
  color: #d02752; }

#primary_nav_wrap > ul > li a::after {
  position: absolute;
  bottom: -.6rem;
  left: 0;
  width: 0;
  height: 3px;
  content: '';
  transition: all .3s;
  background: #d02752; }

#primary_nav_wrap > ul > li a:hover::after {
  width: 100%; }

#primary_nav_wrap ul > li.current > a > span {
  line-height: 30px;
  display: inline-block;
  color: #d02752;
  border-bottom: 3px solid #d02752; }

#primary_nav_wrap ul > li.current:hover > a > span {
  color: #d02752; }

@media (max-width: 1499px) {
  #primary_nav_wrap ul {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
    #primary_nav_wrap ul > li {
      margin-left: 1.5rem;
      margin-right: 1.5rem; }
      #primary_nav_wrap ul > li a {
        font-size: 1.6rem; } }

@media (max-width: 1199px) {
  #primary_nav_wrap ul {
    margin-left: -1rem;
    margin-right: -1rem; }
    #primary_nav_wrap ul > li {
      margin-left: 1rem;
      margin-right: 1rem; }
      #primary_nav_wrap ul > li a {
        font-size: 1.4rem; } }

@media (max-width: 991px) {
  #primary_nav_wrap ul {
    display: none; } }

#menu_launcher {
  display: none;
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  width: 40px;
  height: 40px;
  margin: auto;
  color: #fff;
  cursor: pointer; }

.no-csstransforms3d .mp-pusher,
.no-js .mp-pusher {
  padding-left: 300px; }

.no-csstransforms3d .mp-menu .mp-level,
.no-js .mp-menu .mp-level {
  display: none; }

.no-csstransforms3d .mp-menu > .mp-level,
.no-js .mp-menu > .mp-level {
  display: block; }

.mp-menu {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  transition: transform .5s,-webkit-transform .5s;
  transform: translateX(-100%);
  background: #fff; }

.mp-level > ul > li > a > span {
  color: #333; }

.mp-level > ul > li > a:hover > span {
  color: #706d70; }

.mp-level {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
  height: calc(100vh - 50px);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background: #f4f4f4; }

.mp-menu > .mp-level {
  top: 50px; }

.mp-menu > .mp-level.mp-level-open {
  height: calc(100% - 50px); }

.mp-level.mp-level-open {
  overflow: auto;
  height: calc(100vh - 50px); }

.mp-level.mp-level-open.mp-level-overlay {
  overflow: hidden;
  height: 200vh; }

.mp-level.mp-level-open.mp-level-overlay > ul > li:not(.m_current) {
  visibility: hidden;
  opacity: 0; }

.mp-level.mp-level-overlay {
  cursor: pointer; }

.mp-level.mp-level-overlay.mp-level:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: 0 0; }

.mp-level,
.mp-pusher {
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s,-webkit-transform .5s; }

.mp-overlap .mp-level.mp-level-open {
  -webkit-transform: translateX(-40px);
  transform: translateX(-40px);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2); }

.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s,-webkit-transform .5s;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  box-shadow: none; }

.mp-cover .mp-level.mp-level-open {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.mp-menu .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  display: block;
  min-height: 40px;
  margin: 0 20px;
  padding: .7em 0;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1); }

.mp-menu .title .text {
  margin-left: 20px;
  vertical-align: middle; }

.mp-menu .title .picto + .text {
  margin-left: 0; }

.mp-menu .close_bloc .title,
.mp-menu .mp-level .mp-level .mp-level .title {
  color: #fff; }

.mp-menu .mp-level .mp-level .mp-level .title a {
  line-height: 18px;
  display: inline-block;
  padding-bottom: 3px;
  vertical-align: middle; }

.mp-menu .title a:hover,
.mp-menu ul li .title a:hover {
  color: #56c1ff;
  background: 0 0; }

.mp-menu .title .picto,
.mp-menu .title a .picto {
  font-size: 40px;
  line-height: 40px;
  overflow: visible;
  width: auto;
  margin: 0 5px; }

.mp-menu .title .picto + .centered-height {
  width: calc(100% - 60px); }

.mp-menu.mp-overlap .title:before {
  font-size: 75%;
  line-height: 1.8;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 8px;
  transition: opacity .3s,-webkit-transform .1s .3s;
  transition: opacity .3s,transform .1s .3s;
  transition: opacity .3s,transform .1s .3s,-webkit-transform .1s .3s;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0; }

.mp-overlap .mp-level.mp-level-overlay > .title:before {
  transition: opacity .3s,-webkit-transform .3s;
  transition: transform .3s,opacity .3s;
  transition: transform .3s,opacity .3s,-webkit-transform .3s;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1; }

.mp-menu ul {
  overflow: hidden;
  list-style: none;
  text-align: left; }

.mp-menu > .mp-level > ul {
  position: relative;
  overflow: auto;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 0; }

.mp-menu ul li:before {
  line-height: 30px;
  position: absolute;
  z-index: -1;
  left: 10px;
  color: rgba(0, 0, 0, 0.8); }

.mp-menu ul li > a {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  padding: .7em 1em .7em 1em;
  transition: background .3s,box-shadow .3s;
  text-transform: uppercase;
  outline: 0; }

.mp-menu ul li > a .icon {
  font-size: 12px; }

.mp-level a.favoris-menu-mobile {
  color: #fff;
  background-color: #9fa8bd;
  text-align: center;
  padding: 0 0 1rem; }

.mp-level a.favoris-menu-mobile .text {
  color: #fff;
  transition: color .3s; }

.mp-level a.favoris-menu-mobile:hover .text {
  padding-left: 0; }

.mp-menu ul li > a .icon-favoris {
  font-size: 6rem; }

.mp-level a {
  color: rgba(0, 0, 0, 0.7); }

#mp-menu .mp-level ul {
  list-style: none; }

#mp-menu .mp-level-2 a {
  line-height: 20px;
  vertical-align: middle;
  text-transform: none; }

#mp-menu .mp-level-2 .centered-height a {
  line-height: 1;
  display: inline-block;
  float: none;
  vertical-align: middle; }

#mp-menu .mp-level-2 .c_container a,
#mp-menu .mp-level-2 .c_container span {
  color: rgba(255, 255, 255, 0.7); }

#mp-menu .mp-level-2 a.mp-back {
  text-transform: uppercase; }

#mp-menu .mp-level-2 ul {
  padding: 5px 0; }

#mp-menu .mp-level-2 li {
  padding: 0 5px; }

.mp-level a .text {
  padding-left: 0;
  transition: border-color .2s ease-in,padding .2s ease-in;
  vertical-align: middle; }

.mp-level a:hover .text {
  padding-left: 5px; }

.mp-level a .picto {
  font-size: 22px;
  overflow: visible;
  width: 24px;
  margin: 0 10px 0 0; }

.mp-level > ul > li > a {
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.2); }

.mp-menu.out ul li a:hover {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px transparent; }

.mp-level.mp-level-overlay > ul > li:first-child > a,
.mp-menu.out .mp-level.mp-level-overlay > ul > li > a {
  box-shadow: inset 0 -1px transparent; }

#mp-menu .mp-level .picto_menu {
  position: static;
  margin: 0 0 0 6px;
  transition: border-color .2s ease-in,margin .3s ease-in;
  vertical-align: middle;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.7); }

#mp-menu .mp-level a:hover .picto_menu {
  border-color: transparent transparent transparent #fff; }

.mp-back {
  font-size: 1.2em;
  font-weight: 700;
  position: relative;
  display: block;
  padding: 1em 1em 1em 3.4em;
  transition: color .2s,background .3s,padding .2s;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  outline: 0;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1); }

.mp-back:hover {
  padding: 1em 1em 1em 4em; }

.mp-menu.out .mp-level.mp-level-overlay > .mp-back {
  color: transparent;
  background: 0 0;
  box-shadow: none; }

#mp-menu .mp-back .picto_menu,
#mp-menu .mp-level-2 .mp-back .picto_menu {
  position: absolute;
  top: 50%;
  left: 18px;
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  vertical-align: middle;
  border-width: 3px 4px 3px 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent; }

#mp-menu .mp-back .picto_menu {
  border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent; }

#mp-menu .mp-level .mp-back:hover .picto_menu {
  border-color: transparent #fff transparent transparent; }

.mp-level li {
  margin-left: 0; }

.m_event:after {
  font-family: kfont;
  margin: 0 20px;
  content: '\e98e'; }

#fancybox-content > div,
.fancybox-inner {
  overflow: hidden !important; }

#fancybox-content > div img {
  width: 100%; }

.mp-pusher {
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s,-webkit-transform .5s; }

.fancy_visible .scroller,
.menu_visible .scroller {
  overflow: hidden;
  height: 100%; }

.menu_visible html {
  overflow: hidden; }

.mp-fancy {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 340px;
  height: 100%;
  -webkit-transform: translateX(-340px);
  transform: translateX(-340px); }

.scroller:before {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  content: ''; }

.scroller.mp-pushed {
  cursor: pointer; }

.scroller.mp-pushed:before {
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  transition: background .5s;
  background: rgba(0, 0, 0, 0.5); }

.mp-pusher.fancy_visible {
  -webkit-transform: translateX(340px);
  transform: translateX(340px); }

.mp-pusher.menu_visible {
  -webkit-transform: translateX(300px);
  transform: translateX(300px); }

.mp-pusher::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  content: '';
  opacity: 0; }

.mp-pusher.fancy_visible {
  -webkit-transform: translateX(340px);
  transform: translateX(340px); }

#mp-fancy > .header {
  position: relative;
  border-bottom: 5px solid #5cb39b; }

#menu_launcher a {
  display: block; }

#menu_launcher .text {
  font-size: 1.1em;
  line-height: 1;
  padding-bottom: 5px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #191818; }

.picto-menu {
  position: relative;
  top: 5px;
  left: 6px;
  display: inline-block;
  width: 28px;
  height: 2px;
  transition: all .3s ease-in-out 0s;
  background: #9fa8bf; }

.picto-menu:after,
.picto-menu:before {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 28px;
  height: 2px;
  content: '';
  transition: all .3s ease-in-out 0s;
  -webkit-transform-origin: center center 0;
  transform-origin: center center 0;
  background: #9fa8bf none repeat scroll 0 0; }

.picto-menu:before {
  top: 8px; }

.picto-menu:after {
  top: -8px; }

.menuhidden .picto-menu:before {
  height: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.menuhidden .picto-menu:after {
  height: 2px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.menuhidden .picto-menu:after,
.menuhidden .picto-menu:before {
  top: 0;
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0; }

.menuhidden .picto-menu {
  background: transparent none repeat scroll 0 0; }

.m_icon {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 2px;
  margin: 10px 0;
  vertical-align: middle;
  background: #191818; }

.m_icon:before {
  position: absolute;
  top: -8px;
  left: 0;
  display: block;
  width: 24px;
  height: 2px;
  content: '';
  background: #191818; }

.m_icon:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  display: block;
  width: 24px;
  height: 2px;
  content: '';
  background: #191818; }

#mh_close {
  padding-top: 5px;
  padding-right: 15px;
  text-align: right; }

#mh_close .icon {
  padding: 10px;
  cursor: pointer; }

#logo_mh {
  margin: 5px 0;
  text-align: center; }

@media (max-width: 991px) {
  #menu_launcher {
    display: block; } }

/* Sticky magasins favoris */
.sticky-mag-favo {
  display: block;
  position: fixed;
  right: 0;
  top: 28rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  background-color: #9fa8bd;
  text-align: center;
  padding: 1.2rem 1.6rem;
  line-height: 1.2;
  z-index: 1009; }
  .sticky-mag-favo:hover {
    color: #D22350; }
  .sticky-mag-favo .icon {
    display: block;
    font-size: 7rem;
    line-height: 0;
    margin-top: -1rem;
    margin-bottom: -.5rem; }
  .sticky-mag-favo .count-favo > span {
    display: inline-block;
    width: 3rem; }

@media (max-width: 1199px) {
  .sticky-mag-favo {
    font-size: 1.2rem;
    padding: .5rem 1rem; }
    .sticky-mag-favo .icon {
      margin-bottom: -1.2rem; } }

@media (max-width: 991px) {
  .sticky-mag-favo {
    display: none; } }

.cd-main {
  z-index: 21;
  overflow: hidden;
  overflow-y: auto;
  transition: transform .7s,-webkit-transform .7s;
  transition-timing-function: cubic-bezier(0.91, 0.01, 0.6, 0.99);
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.navigation-is-open .cd-main {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.cd-nav {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  transition: visibility 0s .7s; }

#kpop_contain {
  margin-bottom: 20px; }

#kpop_contain img.fullimg {
  max-height: 80vh; }

#kpop_contain img.fullimg {
  max-height: calc(100vh - 25vh); }

.cd-nav .cd-navigation-wrapper {
  overflow-y: auto;
  box-sizing: border-box;
  height: 100%;
  padding: 40px 5% 40px 5%;
  transition: -webkit-transform .7s;
  transition: transform .7s,-webkit-transform .7s;
  transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
  transform: translateX(-50%);
  text-align: left;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.navigation-is-open .cd-nav {
  visibility: visible;
  transition: visibility 0s 0s; }

.navigation-is-open .cd-nav .cd-navigation-wrapper {
  transition: -webkit-transform .5s;
  transition: transform .5s,-webkit-transform .5s;
  transition-timing-function: cubic-bezier(0.82, 0.01, 0.77, 0.78);
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.cd-nav-trigger {
  z-index: 50;
  display: inline-block;
  overflow: hidden;
  width: 54px;
  height: 54px;
  margin-bottom: 2em;
  cursor: pointer;
  transition: transform .5s,background-color .2s ease-in,-webkit-transform .5s;
  white-space: nowrap;
  text-indent: 100%;
  background-color: #35c3c8; }

.cd-nav-trigger:focus,
.cd-nav-trigger:hover {
  background-color: #6e6e6e; }

.cd-nav-trigger .cd-nav-icon {
  position: absolute;
  top: 47%;
  right: auto;
  bottom: auto;
  left: 33%;
  width: 22px;
  height: 2px;
  transform: rotate(-45deg);
  background-color: #fff; }

.cd-nav-trigger .cd-nav-icon::before,
.cd-nav-trigger .cd-nav-icon:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  content: '';
  transition: transform .5s,width .5s,top .3s,-webkit-transform .5s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  background-color: inherit;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.cd-nav-trigger .cd-nav-icon::before {
  transform: rotate(45deg);
  -webkit-transform-origin: right top;
  transform-origin: right top; }

.cd-nav-trigger .cd-nav-icon::after {
  transform: rotate(-45deg);
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom; }

.cd-nav-trigger svg {
  position: absolute;
  top: 0;
  left: 0; }

.navigation-is-open .cd-nav-trigger {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg); }

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after,
.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
  width: 50%;
  transition: transform .5s,width .5s,-webkit-transform .5s; }

.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::after,
.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::before {
  top: 0; }

.no-js main {
  overflow: visible;
  height: auto; }

.no-js .cd-nav {
  position: static;
  visibility: visible; }

.no-js .cd-nav .cd-navigation-wrapper {
  overflow: visible;
  height: auto;
  padding: 100px 5%;
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.newsletter_container {
  position: relative;
  display: flex;
  align-items: center;
  height: 32rem;
  margin-top: 6rem;
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.newsletter_container .newsletter_content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.newsletter_container .icon {
  display: inline-block;
  font-size: 4rem;
  vertical-align: text-top;
  margin-right: 1rem; }

.newsletter_container form {
  display: flex;
  align-items: center; }

.newsletter_container input[type="text"] {
  font-size: 1.6rem;
  padding-left: 2.8rem;
  margin-left: 2rem;
  border: none;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  height: 6.5rem; }
  .newsletter_container input[type="text"]::placeholder {
    color: #999; }

.newsletter_container button[type="submit"] {
  height: 6.5rem;
  font-size: 5rem;
  font-weight: 500;
  color: #fff;
  background-color: #D22350;
  border: none;
  border-left: 1px solid #D22350;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  cursor: pointer;
  transition: all .3s; }

.newsletter_container button[type="submit"]:hover {
  color: #D22350;
  background-color: #fff; }

#msg_newsletter {
  display: flex;
  justify-content: center;
  font-size: 2rem; }

.message-rgpd {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 12px;
  font-size: 1.4rem;
  text-align: center; }
  .message-rgpd label {
    cursor: pointer; }
    .message-rgpd label p {
      margin-bottom: 3px; }

.newsletter_home .rgpd-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding-left: .8rem;
  padding-right: .8rem; }
  .newsletter_home .rgpd-form input {
    margin-top: 0;
    margin-right: 5px; }
  .newsletter_home .rgpd-form label {
    padding-top: .5rem;
    height: auto;
    line-height: 1.2; }

button[type="submit"].disabled {
  background-color: #666;
  cursor: not-allowed; }

.see-more-rgpd {
  margin-top: -1rem; }
  .see-more-rgpd a {
    color: #fff;
    font-size: 12px; }
    .see-more-rgpd a:hover {
      color: #555; }

#detail-rgpd {
  width: calc(100% - 40px);
  max-width: 600px;
  padding-bottom: 2px; }

#chemin {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  min-height: 4rem;
  margin-top: 1rem;
  margin-bottom: 2rem; }
  #chemin a {
    color: #9fa8bd;
    margin-left: 1rem;
    margin-right: 1rem; }
    #chemin a:hover {
      color: #F7F7F7; }

/* Détail commun actualités + news */
.liste-detail {
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  margin-top: 1rem;
  height: 0;
  overflow: hidden;
  line-height: 1.2;
  z-index: 1; }
  .liste-detail .icon-close {
    position: absolute;
    right: .5rem;
    top: 2rem;
    display: block;
    color: #D22350;
    font-size: 2.2rem;
    cursor: pointer;
    transition: color .3s;
    z-index: 1; }
    .liste-detail .icon-close:hover {
      color: #a0a8bf; }
  .liste-detail .voir-plus {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center; }
    .liste-detail .voir-plus:before {
      content: "";
      position: relative;
      top: -14rem;
      display: block;
      height: 20rem;
      background: white;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.723109) 50%, rgba(255, 255, 255, 0.930392) 100%);
      z-index: 1; }
    .liste-detail .voir-plus .button_pink {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      font-size: 1.5rem;
      font-weight: 400;
      padding: 2.2rem 1.8rem;
      text-transform: none;
      z-index: 2; }

/* Détail actualités */
.bloc_actu .liste-news .detail-new {
  top: 38.5rem; }
  .bloc_actu .liste-news .detail-new .voir-plus {
    top: 65rem; }
  .bloc_actu .liste-news .detail-new .detail-new-content {
    display: flex;
    border-top: .2rem solid #ddd;
    padding-top: 6rem; }

.new-detail {
  display: flex;
  margin-bottom: 6rem; }
  .new-detail .new-left-col {
    width: 32rem;
    margin-right: 4rem; }
    .new-detail .new-left-col img {
      border-radius: .8rem; }
    .new-detail .new-left-col .detail_date {
      font-size: 1.7rem;
      font-weight: 500;
      color: #D22350;
      margin-top: 2.5rem; }
    .new-detail .new-left-col .partage-new {
      display: flex;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.4rem;
      font-weight: 700;
      color: #000;
      margin-top: 1rem; }
      .new-detail .new-left-col .partage-new .icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2.8rem;
        height: 2.8rem;
        background-color: #D9D9D9;
        border-radius: 50%;
        margin-left: .6rem; }
      .new-detail .new-left-col .partage-new a:hover .icon,
      .new-detail .new-left-col .partage-new .lien-off:hover .icon {
        color: #D22350; }
    .new-detail .new-left-col .tags-liste {
      margin-top: 2rem; }
      .new-detail .new-left-col .tags-liste .tag-new {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 2.7rem;
        border: .1rem solid #D22350;
        color: #D22350;
        border-radius: 2rem;
        margin-bottom: .5rem;
        padding-left: .6rem;
        padding-right: .6rem; }
  .new-detail .new-right-col {
    width: calc(100% - 36rem); }
    .new-detail .new-right-col .h1 {
      font-size: 2.8rem;
      text-align: left;
      margin-bottom: 2.5rem; }

/* Détail marques */
#marques .liste-marques .detail-marque {
  top: 32.4rem; }
  #marques .liste-marques .detail-marque .bandeau-detail-marque {
    padding-top: 6rem; }
  #marques .liste-marques .detail-marque .bandeau-marque-contenu {
    top: 6rem; }
  #marques .liste-marques .detail-marque .voir-plus {
    top: 112rem; }

.liste-marques .h1 {
  color: #525558;
  text-align: left;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 2rem; }

.liste-marques .bandeau-marque {
  position: relative; }
  .liste-marques .bandeau-marque .bandeau-detail-marque {
    border-top: .2rem solid #ddd;
    padding-top: 6rem;
    padding-bottom: 2rem; }
    .liste-marques .bandeau-marque .bandeau-detail-marque img {
      display: block;
      width: 100%;
      object-fit: cover;
      max-height: 86.5rem;
      height: 100%; }
  .liste-marques .bandeau-marque .bandeau-marque-contenu {
    position: absolute;
    top: 3rem;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu > div {
      width: calc(100% / 3 - 2rem);
      text-align: center;
      margin-left: 1rem;
      margin-right: 1rem; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .head_pres {
      font-size: 1.2rem;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.35);
      padding: 3.5rem 4rem; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .brand_logo img {
      max-height: 18rem;
      max-width: 18rem; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .list_img_mag {
      margin-bottom: 0;
      max-width: 24rem;
      margin-left: auto;
      margin-right: auto; }
      .liste-marques .bandeau-marque .bandeau-marque-contenu .list_img_mag .slick-slide > div {
        height: 100%; }
      .liste-marques .bandeau-marque .bandeau-marque-contenu .list_img_mag img {
        height: 100%;
        object-fit: cover;
        max-height: 18rem; }

.liste-marques .main_content {
  padding-left: 3rem;
  margin-bottom: 3rem; }
  .liste-marques .main_content h2.h2,
  .liste-marques .main_content h2.h3 {
    font-size: 3rem;
    color: #555555;
    text-transform: uppercase;
    margin-top: 4rem;
    margin-bottom: 1.5rem; }
  .liste-marques .main_content .slider_marques {
    padding-bottom: 5rem; }
    .liste-marques .main_content .slider_marques .slick-list {
      padding-bottom: 3rem; }
    .liste-marques .main_content .slider_marques .slick-dots {
      bottom: .5rem; }
  .liste-marques .main_content .news_and_vid {
    display: flex;
    justify-content: center; }
    .liste-marques .main_content .news_and_vid > div {
      width: calc(50% - 3rem);
      margin-left: 1.5rem;
      margin-right: 1.5rem; }
    .liste-marques .main_content .news_and_vid .actu-card {
      position: relative; }
      .liste-marques .main_content .news_and_vid .actu-card .actu-img-bg {
        aspect-ratio: 465 / 155;
        background-position: center;
        object-fit: cover;
        min-height: 14rem;
        max-width: 100%; }
      .liste-marques .main_content .news_and_vid .actu-card .date {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% / 3);
        min-width: 13rem;
        text-align: left;
        color: #fff;
        background-color: #417F35;
        padding: .5rem 1rem; }
      .liste-marques .main_content .news_and_vid .actu-card .actu-desc .h4 {
        font-size: 2.1rem;
        margin-top: 1.2rem;
        text-transform: none;
        color: #417F35; }
      .liste-marques .main_content .news_and_vid .actu-card .actu-desc p {
        line-height: 1.6; }
    .liste-marques .main_content .news_and_vid .video-iframe {
      aspect-ratio: 605/340; }
  .liste-marques .main_content .button_blue,
  .liste-marques .main_content .button_green {
    font-size: 1.6rem;
    text-transform: none;
    font-weight: 600;
    padding: 1.8rem 2.2rem;
    margin-top: 2.5rem; }
  .liste-marques .main_content .liste-mag {
    max-width: 102rem;
    margin-left: auto;
    margin-right: auto; }

/* Page détail news et marque */
.page-marque-detail .btn-cta-listing,
.page-news-detail .btn-cta-listing {
  position: absolute;
  top: 1.4rem;
  right: 1.5rem; }
  .page-marque-detail .btn-cta-listing .button_grey,
  .page-marque-detail .btn-cta-listing .button_green,
  .page-news-detail .btn-cta-listing .button_grey,
  .page-news-detail .btn-cta-listing .button_green {
    position: relative;
    z-index: 1;
    padding: 1.2rem 2.4rem;
    height: auto; }

.page-news-detail {
  padding-top: 6rem;
  border-top: .2rem solid #ddd; }
  .page-news-detail .desc-new {
    font-size: 1.8rem;
    line-height: 1.3; }

@media (max-width: 1199px) {
  .liste-marques .main_content .list_product .card-product-marque {
    width: calc(100% / 3 - 2rem);
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem; }
  .liste-marques .bandeau-marque .bandeau-marque-contenu .head_pres {
    padding: 1.5rem 2rem; } }

@media (max-width: 991px) {
  .new-detail .new-left-col {
    margin-right: 2rem; }
  .new-detail .new-right-col {
    width: calc(100% - 34rem); }
  .liste-marques .bandeau-marque .bandeau-marque-contenu .head_pres {
    padding: .5rem; } }

@media (max-width: 767px) {
  .new-detail {
    flex-direction: column; }
    .new-detail .new-left-col {
      width: 100%;
      margin-bottom: 3rem;
      margin-right: 0; }
    .new-detail .new-right-col {
      width: 100%; }
  .liste-marques .main_content h2.h3 {
    font-size: 2.2rem; }
  .liste-marques .main_content .list_product .card-product-marque {
    width: calc(50% - 2rem); }
    .liste-marques .main_content .list_product .card-product-marque:nth-child(3) {
      display: none; }
  .liste-marques .main_content .news_and_vid {
    flex-wrap: wrap; }
    .liste-marques .main_content .news_and_vid > div {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem; }
  .liste-marques .bandeau-marque .bandeau-marque-contenu > div {
    width: calc(50% - 2rem); }
  .liste-marques .bandeau-marque .bandeau-marque-contenu .slider_mag {
    display: none; } }

@media (max-width: 575px) {
  .liste-marques .main_content {
    padding-left: 1.5rem; }
    .liste-marques .main_content .list_product .card-product-marque {
      width: 100%; }
      .liste-marques .main_content .list_product .card-product-marque:nth-child(2) {
        display: none; }
  .liste-marques .bandeau-marque .bandeau-marque-contenu {
    flex-direction: column; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu > div {
      width: 100%; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .head_pres_c {
      display: none; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .brand_logo_c {
      margin-bottom: 1rem; }
  .liste-marques .bandeau-marque.page-marque-detail .bandeau-marque-contenu {
    position: static;
    flex-direction: column;
    margin-top: 2rem; }
    .liste-marques .bandeau-marque.page-marque-detail .bandeau-marque-contenu .head_pres_c {
      display: block; }
    .liste-marques .bandeau-marque.page-marque-detail .bandeau-marque-contenu .brand_logo img {
      max-height: 10rem; }
  .liste-marques .bandeau-marque.page-marque-detail .bandeau-detail-marque {
    min-height: 0;
    height: 0; } }

/* Slider blog page marque */
.blog-slider-container {
  max-width: 1667px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.blog-slider {
  padding-left: 14px;
  padding-right: 50px; }

.two_slider_blog .slick-track,
.one_slider_blog .slick-track {
  margin-left: auto !important;
  margin-right: auto !important; }

.blog-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
  max-width: 499px;
  max-height: 479px;
  width: 100%;
  margin-left: 35px;
  overflow: hidden; }

.blog-text-container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.8s ease; }

.blog-card:hover .blog-text-container {
  max-height: 200px; }

.blog-text-container p {
  font-size: 16px;
  font-weight: 400;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
  margin-top: 0; }

.blog-card:hover p {
  opacity: 1;
  transform: translateY(0); }

.blog-contenu {
  position: absolute;
  left: 33px;
  bottom: 15px;
  right: 23px;
  z-index: 1; }

.blog-effet-noir {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none; }

.blog-effet-noir::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0) 50%);
  transform-origin: bottom;
  transform: scaleY(1);
  transition: transform 0.8s ease;
  z-index: 1; }

.blog-card a {
  display: block;
  color: inherit;
  text-decoration: none;
  height: 100%;
  width: 100%; }

.icon-slide-blog-arrow-left::before {
  content: "\EA01"; }

.icon-slide-blog-arrow-right::before {
  content: "\EA02"; }

.blog-card:hover .blog-effet-noir::before {
  transform: scaleY(1.5); }

.blog-card img {
  width: 100%;
  z-index: 0;
  pointer-events: none; }

.blog-card * {
  color: white; }

.blog-tags span {
  background-color: #1A1F2B;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 32px;
  line-height: 25px;
  font-weight: 400; }

.blog-title {
  font-size: 32px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 0; }

.slider_blog {
  padding-right: 50px;
  padding-left: 10px; }

.icon-slide-blog-arrow-left {
  margin-left: 20px; }

.icon-slide-blog-arrow-right {
  margin-right: 20px; }

@media (max-width: 1675px) {
  .blog-slider-container {
    max-width: 1080px; }
  .slider_blog {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 40px; }
    .slider_blog .slick-dots {
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto; } }

@media (max-width: 1100px) {
  .blog-slider-container {
    max-width: 570px; } }

@media (max-width: 991px) {
  .slider_blog .slick-dots {
    margin-bottom: 0;
    padding-left: 70px;
    margin-right: 0; } }

@media (max-width: 580px) {
  .blog-slider-container {
    max-width: 450px; }
  .blog-card {
    max-width: 400px; } }

@media (max-width: 460px) {
  .blog-slider-container {
    max-width: 360px; }
  .blog-card {
    max-width: 300px; }
  .blog-tags {
    font-size: 14px; }
  .blog-title {
    font-size: 22px; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  word-wrap: normal;
  hyphens: none;
  line-height: 1; }

h1,
.h1 {
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.2;
  margin: .5rem 0;
  color: #D22350;
  text-align: center; }
  @media (max-width: 991px) {
    h1,
    .h1 {
      font-size: 3rem; } }

h2,
.h2 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.2;
  margin: .5rem 0;
  color: #D22350;
  text-align: center; }
  @media (max-width: 991px) {
    h2,
    .h2 {
      font-size: 2.2rem; } }

h3,
.h3 {
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 1.2;
  margin: .5rem 0;
  color: #D22350; }
  @media (max-width: 991px) {
    h3,
    .h3 {
      font-size: 2rem; } }

h4,
.h4 {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  margin: .6em 0;
  text-align: left;
  color: #0ea6af;
  text-transform: none; }
  @media (max-width: 991px) {
    h4,
    .h4 {
      font-size: 1.2rem; } }

h5,
.h5 {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase; }

h6,
.h6 {
  font-size: 1.2rem;
  color: #0ea6af;
  margin: .6rem 0;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  text-align: center; }

.title-picto {
  display: flex;
  justify-content: center;
  align-items: center; }
  .title-picto img {
    margin-right: 2rem; }

.title_underline p {
  margin-bottom: 0; }

.title_underline::after {
  content: "";
  display: block;
  width: 28rem;
  height: .1rem;
  background-color: #D4DBEB;
  margin: .8rem auto 1.8rem; }

.desc,
.sstitre .news_content,
.stitre,
.t1,
.t2,
.t3,
.text,
.title,
.titre,
h1,
h2,
h3,
h4,
h5,
h6 .rassurance {
  word-wrap: normal;
  -webkit-hyphens: none;
  hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none; }

#pagination {
  position: relative; }

.pager_c ul {
  margin: 0;
  text-align: center; }

.pager_c li {
  display: inline-block;
  margin-left: 4px; }

.pager_c li .link,
.pager_c li a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  height: 3.5rem;
  width: 3.5rem;
  color: #000;
  background-color: #f4f4f6;
  border: .1rem solid #b7c0db;
  border-radius: 50%; }

.pager_c li.navig .link,
.pager_c li.navig a {
  font-size: 13px; }

.pager_c li .link .icon,
.pager_c li a .icon {
  line-height: 0; }

.pager_c li.current .link,
.pager_c li.current .link:focus,
.pager_c li.current .link:hover,
.pager_c li.current a,
.pager_c li.current a:focus,
.pager_c li.current a:hover {
  color: #fff;
  background-color: #d02752; }

.pager_c li:not(.truncate) .link:focus,
.pager_c li:not(.truncate) .link:hover,
.pager_c li:not(.truncate) a:focus,
.pager_c li:not(.truncate) a:hover {
  background-color: #b7c0db; }

.pager_c li.truncate .link:focus,
.pager_c li.truncate .link:hover,
.pager_c li.truncate a:focus,
.pager_c li.truncate a:hover {
  cursor: pointer; }

#marques .pager_c {
  position: relative;
  margin-top: 20px; }

.sub_nav_content {
  position: absolute;
  z-index: 2;
  top: 45px;
  right: 0;
  left: 0;
  overflow: hidden;
  max-height: 0;
  transition: all .1s ease-in-out; }

#marques .sub_nav_content {
  top: 33px; }

.sub_nav_content .sub_nav {
  padding: 5px;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  text-align: center; }

.truncate.shownav .sub_nav_content {
  max-height: 500px; }

.truncate.shownav > .link::after,
.truncate.shownav > .link::before {
  position: absolute;
  z-index: 3;
  left: 50%;
  width: 0;
  height: 0;
  content: '';
  border-style: solid; }

.truncate.shownav > .link::before {
  top: 37px;
  margin-left: -6px;
  border-width: 0 6px 9px 6px;
  border-color: transparent transparent #ccc transparent; }

.truncate.shownav > .link::after {
  top: 38px;
  margin-left: -5px;
  border-width: 0 5px 8px 5px;
  border-color: transparent transparent #fff transparent; }

.sub_nav li {
  margin: 1px; }

.pager_c .sub_nav .link {
  font-size: 9px;
  line-height: 22px;
  width: 22px;
  height: 22px;
  min-height: 22px;
  padding-right: 0;
  padding-left: 0;
  text-align: center; }

.bg-homepage-c {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center; }
  .bg-homepage-c:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.252521) 50%, rgba(255, 255, 255, 0.801541) 70%, white 100%); }

/* RECHERCHE OPTICIENS */
#recherche_boutique .container {
  position: relative;
  min-height: 46rem; }

#recherche_boutique .container::before {
  content: "";
  display: block;
  position: absolute;
  left: -50rem;
  top: 0;
  bottom: 0;
  width: 50rem;
  background-color: rgba(255, 255, 255, 0.76); }

#recherche_boutique .container::after {
  content: "";
  display: block;
  position: absolute;
  right: -50rem;
  top: 0;
  bottom: 0;
  width: 50rem;
  background-color: rgba(255, 255, 255, 0.76); }

#recherche_boutique .h1 {
  color: #fff;
  padding-top: 8rem;
  margin-bottom: 2.5rem; }
  #recherche_boutique .h1 p {
    margin-bottom: 0; }

#crit_search {
  text-align: center; }

.search_opticien,
.search_marque {
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .search_opticien .submit_button,
  .search_marque .submit_button {
    text-align: left; }

.search_opticien input,
.search_marque input,
.search_opticien .custom-select,
.search_marque .custom-select {
  height: 6.8rem;
  border: none;
  color: #656975;
  font-size: 1.6rem;
  font-weight: 500;
  width: 30rem;
  max-width: 100%; }
  .search_opticien input .select2,
  .search_marque input .select2,
  .search_opticien .custom-select .select2,
  .search_marque .custom-select .select2 {
    padding-left: 3rem; }

.search_opticien .code_post,
.search_marque .code_post {
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  padding-left: 2.8rem;
  padding-right: 2.8rem; }
  .search_opticien .code_post.active,
  .search_marque .code_post.active {
    border-bottom-left-radius: 0; }

.search_opticien .specialite_pref select,
.search_marque .marque_pref select {
  width: 100%;
  height: 100%; }

.search_opticien .specialite_pref::before,
.search_marque .marque_pref::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1.3rem;
  display: block;
  background-color: #D2D6E1;
  height: 4.5rem;
  width: .1rem; }

.specialite_pref.custom-select::after {
  font-size: 1.6rem;
  font-weight: 500;
  right: 1.5rem;
  top: 2.4rem; }

.search_opticien .button_grey,
.search_marque .button_grey {
  position: relative;
  display: flex;
  align-items: center;
  hyphens: initial;
  height: 6.8rem;
  margin-left: -.1rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border: none;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 0 3rem 3rem 0;
  background-color: #D22350;
  border-left: .1rem solid #D22350;
  z-index: 1; }
  .search_opticien .button_grey:active, .search_opticien .button_grey:hover,
  .search_marque .button_grey:active,
  .search_marque .button_grey:hover {
    color: #4D4D4D;
    background-color: #D22350; }
  .search_opticien .button_grey p,
  .search_marque .button_grey p {
    margin-bottom: 0; }

.search_opticien .plus_criteres,
.search_marque .plus_criteres {
  display: inline-block;
  color: #fff;
  cursor: pointer;
  text-align: left;
  margin-left: 2.7rem;
  margin-top: .7rem;
  font-size: 1.2rem;
  font-weight: 500;
  padding: .1rem .3rem;
  background-color: rgba(0, 0, 0, 0.3); }

.search_marque .plus_criteres.hidden {
  opacity: 0; }

.search_opticien .plus_criteres .icon_plus,
.search_marque .plus_criteres .icon_plus {
  display: inline-block;
  width: 1rem; }

.search_opticien .plus_criteres .icon_moins,
.search_marque .plus_criteres .icon_moins {
  display: none; }

.open .search_opticien .plus_criteres .icon_moins,
.open .search_marque .plus_criteres .icon_moins {
  display: inline-block;
  width: 1rem; }

.open .search_opticien .plus_criteres .icon_plus,
.open .search_marque .plus_criteres .icon_plus {
  display: none; }

/* SECTION SPECIALITES */
.specialites_home {
  position: relative;
  background-color: rgba(255, 255, 255, 0.76);
  padding-top: 5.5rem;
  padding-bottom: 12rem; }
  .specialites_home > .container {
    z-index: 1; }

.specialites_home .h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #4D4D4D;
  margin-bottom: 4rem; }

.spe-homecard-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -1.5rem;
  margin-right: -1.5rem; }

.spe-homecard-container .spe-homecard {
  width: calc(25% - 3rem);
  text-align: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem; }
  .spe-homecard-container .spe-homecard a {
    display: block; }

.spe-homecard-container .spe-homecard img {
  aspect-ratio: 411/316;
  object-fit: cover;
  width: 100%;
  filter: brightness(0.9);
  transition: filter .3s; }
  .spe-homecard-container .spe-homecard img:hover {
    filter: brightness(1); }

.spe-homecard-container .spe-content {
  position: relative;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20%;
  background-color: #fff;
  padding: 2rem 1.5rem 2.5rem;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.2); }

.spe-homecard-container .spe-content .h3 {
  font-size: 2.8rem;
  font-weight: 400;
  color: #4D4D4D;
  line-height: 1;
  margin-bottom: 1.2rem;
  height: 6rem;
  overflow: hidden; }

.spe-homecard-container.five-blocks .spe-content .h3 {
  font-size: 2rem; }

.spe-homecard-container .spe-content .desc {
  height: 9.6rem;
  overflow: hidden;
  line-height: 1.5;
  margin-bottom: 2rem; }

.spe-homecard-container .spe-content a {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #D22350;
  font-weight: 500; }

.spe-homecard-container .spe-content a:hover {
  color: #9fa8bd; }

.panel-content {
  position: fixed;
  top: 0;
  right: -73rem;
  bottom: 0;
  width: 73rem;
  background-color: #fff;
  z-index: 1009;
  padding: 5rem;
  box-shadow: 0 .3rem .4rem 0 #cfcfcf;
  transition: all .4s; }

.panel-content.open {
  right: 0; }

.panel-content .close {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #525558;
  background-color: #E8EBF4;
  font-size: 1.8rem;
  line-height: .8;
  cursor: pointer;
  transition: all .3s; }

.panel-content .close:hover {
  background-color: #d3d9ea; }

.panel-spe .img-spe {
  display: block;
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: 618/316;
  width: 100%;
  object-fit: cover;
  border-radius: 2.5rem; }

.panel-spe .h2 {
  display: flex;
  justify-content: flex-start;
  font-size: 3.6rem;
  margin-top: 5rem;
  margin-bottom: 0; }

.panel-spe .h2 img {
  margin-left: 0; }

.panel-spe .desc {
  font-size: 2rem;
  line-height: 1.3; }

.panel-spe .button_blue {
  margin-top: 2.5rem;
  font-size: 1.4rem; }

.panel-spe .stitre {
  font-size: 2.1rem;
  font-weight: 700;
  color: #68728B;
  margin-bottom: 2rem; }

/* SECTION MARQUE-RDV-VALEURS */
.marque-rdv {
  background-repeat: no-repeat;
  background-position: center bottom; }

.marque-rdv .marque-rdv-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -3rem;
  margin-right: -3rem; }
  .marque-rdv .marque-rdv-content p {
    margin-bottom: 0; }

.marque-rdv .marque-rdv-content > div {
  width: calc(50% - 6rem);
  padding-left: 3rem;
  padding-right: 3rem;
  margin-bottom: -5rem; }

.marque-rdv .h4 {
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  text-transform: none;
  line-height: 1.1; }

.marque-rdv .block-marque {
  color: #fff;
  padding: 2rem 5rem 12rem 5rem;
  margin-bottom: 5rem;
  background-position: bottom;
  background-size: contain;
  background-color: #D22350;
  background-repeat: no-repeat;
  opacity: 0.8 !important; }

.marque-rdv .block-marque form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3rem; }

.marque-rdv .block-marque input {
  height: 6.8rem;
  width: 100%;
  max-width: 35rem;
  font-weight: 500;
  padding-left: 2.8rem;
  color: #656975;
  border-radius: 3rem;
  border: none; }
  .marque-rdv .block-marque input::placeholder {
    color: #999; }
  .marque-rdv .block-marque input.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

.marque-rdv .block-marque button[type="submit"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 6.6rem;
  width: 8.8rem;
  color: #D22350;
  background-color: #fff;
  border-radius: 3rem;
  border: .1rem solid #fff;
  cursor: pointer;
  margin-left: 1.2rem;
  transition: all .3s; }

.marque-rdv .block-marque button[type="submit"] span {
  font-size: 3rem;
  line-height: 0; }

.marque-rdv .block-marque button[type="submit"]:hover {
  color: #fff;
  background-color: #D22350; }

.marque-rdv .block-marque .button_pink_outline {
  padding-right: 1rem; }

.marque-rdv .block-marque .button_pink_outline .picto-next {
  font-size: 5rem;
  vertical-align: middle;
  margin-left: 1.5rem;
  opacity: .5; }

.block-rdv {
  display: flex;
  background-size: cover;
  background-repeat: no-repeat; }

.block-rdv .left-col {
  width: 60%;
  padding: 5rem 2rem 6rem 6rem;
  background-color: rgba(135, 120, 176, 0.6); }

.block-rdv .left-col .text {
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 1.2rem; }

.block-rdv .left-col a,
.block-rdv .left-col .lien-off {
  display: inline-block;
  color: #D22350;
  background-color: #fff;
  font-weight: 500;
  font-size: 1.6rem;
  padding: 2rem;
  border-radius: 3rem;
  border: .1rem solid #fff;
  text-transform: uppercase; }

.block-rdv .left-col a:hover,
.block-rdv .left-col .lien-off:hover {
  color: #fff;
  background-color: #D22350; }

.block-rdv .right-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  color: #fff;
  background-color: rgba(255, 33, 94, 0.8);
  text-transform: uppercase; }

.block-rdv .right-col .highlight {
  font-size: 5rem;
  font-weight: 500; }

.block-rdv .right-col .lowlight {
  font-size: 2.4rem;
  font-weight: 700; }

.block-rdv .right-col .mediumlight {
  font-size: 4.3rem;
  font-weight: 400; }

.block-valeurs {
  text-align: right;
  max-width: 54rem; }

.block-valeurs .h4 {
  color: #D22350;
  font-size: 3.4rem;
  font-weight: 500;
  text-align: right;
  margin-bottom: .5rem; }
  .block-valeurs .h4 p {
    margin-bottom: 0; }

.block-valeurs .h5 {
  color: #68728B;
  font-size: 2.1rem;
  font-weight: 700;
  margin-bottom: 3rem; }
  .block-valeurs .h5 p {
    margin-bottom: 0; }

.block-valeurs .text {
  color: #68728B;
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 4rem; }

/* SECTION RASSURANCES */
.body-reassurance {
  position: relative;
  margin-top: -16rem;
  margin-bottom: 6rem;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/theme/bandeau.01.jpg); }
  @media (max-width: 991px) {
    .body-reassurance {
      margin-top: -11rem; } }
  .body-reassurance .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch; }
    .body-reassurance .container .bloc-reassurance {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 15rem;
      margin-bottom: 2rem; }
      @media (max-width: 991px) {
        .body-reassurance .container .bloc-reassurance {
          margin-top: 6rem; } }
      @media (max-width: 767px) {
        .body-reassurance .container .bloc-reassurance {
          flex-direction: column; } }
      .body-reassurance .container .bloc-reassurance .bulle-content {
        width: 26rem;
        height: 24rem;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url(../img/theme/bulle2.svg); }
        .body-reassurance .container .bloc-reassurance .bulle-content, .body-reassurance .container .bloc-reassurance .bulle-content .link, .body-reassurance .container .bloc-reassurance .bulle-content .bulle {
          display: flex;
          flex-direction: column;
          color: #fff; }
        @media (max-width: 767px) {
          .body-reassurance .container .bloc-reassurance .bulle-content {
            width: 20rem;
            height: 18rem; } }
        .body-reassurance .container .bloc-reassurance .bulle-content .link,
        .body-reassurance .container .bloc-reassurance .bulle-content .no_link {
          flex: 1 auto; }
          .body-reassurance .container .bloc-reassurance .bulle-content .link:hover, .body-reassurance .container .bloc-reassurance .bulle-content .link:focus,
          .body-reassurance .container .bloc-reassurance .bulle-content .no_link:hover,
          .body-reassurance .container .bloc-reassurance .bulle-content .no_link:focus {
            opacity: 0.8; }
          .body-reassurance .container .bloc-reassurance .bulle-content .link .bulle,
          .body-reassurance .container .bloc-reassurance .bulle-content .no_link .bulle {
            flex: 1 auto; }
            .body-reassurance .container .bloc-reassurance .bulle-content .link .bulle .icon,
            .body-reassurance .container .bloc-reassurance .bulle-content .no_link .bulle .icon {
              display: flex;
              align-items: center;
              position: relative;
              left: 0;
              height: 7.5rem;
              margin: 1rem auto 0 auto;
              font-size: 7.6rem;
              color: #fff; }
              .body-reassurance .container .bloc-reassurance .bulle-content .link .bulle .icon.icon-fournisseur,
              .body-reassurance .container .bloc-reassurance .bulle-content .no_link .bulle .icon.icon-fournisseur {
                font-size: 5.6rem; }
              @media (max-width: 767px) {
                .body-reassurance .container .bloc-reassurance .bulle-content .link .bulle .icon,
                .body-reassurance .container .bloc-reassurance .bulle-content .no_link .bulle .icon {
                  left: -4.5rem;
                  height: 4.5rem;
                  font-size: 4.6rem; }
                  .body-reassurance .container .bloc-reassurance .bulle-content .link .bulle .icon.icon-fournisseur,
                  .body-reassurance .container .bloc-reassurance .bulle-content .no_link .bulle .icon.icon-fournisseur {
                    font-size: 3.6rem; } }
            .body-reassurance .container .bloc-reassurance .bulle-content .link .bulle .t1,
            .body-reassurance .container .bloc-reassurance .bulle-content .no_link .bulle .t1 {
              display: flex;
              font-size: 7rem;
              font-weight: bold;
              margin: 0 auto;
              line-height: 1; }
              @media (max-width: 767px) {
                .body-reassurance .container .bloc-reassurance .bulle-content .link .bulle .t1,
                .body-reassurance .container .bloc-reassurance .bulle-content .no_link .bulle .t1 {
                  font-size: 5rem;
                  margin: 0 3rem 0 auto; } }
            .body-reassurance .container .bloc-reassurance .bulle-content .link .bulle .t2,
            .body-reassurance .container .bloc-reassurance .bulle-content .no_link .bulle .t2 {
              display: flex;
              font-size: 1.8rem;
              font-weight: bold;
              margin: 0 auto;
              line-height: 1; }
              @media (max-width: 767px) {
                .body-reassurance .container .bloc-reassurance .bulle-content .link .bulle .t2,
                .body-reassurance .container .bloc-reassurance .bulle-content .no_link .bulle .t2 {
                  font-size: 1.4rem;
                  margin: 0 3rem auto auto; } }
    .body-reassurance .container .bloc-desc {
      max-width: 60vw;
      margin: 2rem auto 6rem auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff; }
      @media (max-width: 991px) {
        .body-reassurance .container .bloc-desc {
          margin-bottom: 2rem;
          max-width: 80vw; } }
      @media (max-width: 767px) {
        .body-reassurance .container .bloc-desc {
          max-width: 100vw; } }
      .body-reassurance .container .bloc-desc .title {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 3.2rem;
        text-align: center;
        margin-bottom: 3rem; }
        @media (max-width: 767px) {
          .body-reassurance .container .bloc-desc .title {
            font-size: 2.8rem; } }
        .body-reassurance .container .bloc-desc .title::after {
          content: '';
          display: flex;
          margin: 2rem auto auto auto;
          width: 15rem;
          border-bottom: 1px solid #fff; }
      .body-reassurance .container .bloc-desc .desc {
        font-size: 2.2rem;
        text-align: center; }
        @media (max-width: 767px) {
          .body-reassurance .container .bloc-desc .desc {
            font-size: 1.6rem; } }

/* SECTION NEWS */
#news-homepage {
  margin-top: 13rem; }

#news-homepage .h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center; }

#news-homepage .h2 span {
  line-height: 1; }

#news-homepage .h2 img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .6rem;
  margin-left: 1.5rem;
  max-width: 41rem; }

#news-homepage .h5 {
  font-size: 2.4rem;
  font-weight: 300;
  color: #68728B;
  text-align: center;
  margin-bottom: 5rem; }

#news-homepage .list_2img,
#news-homepage .list_4img {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem; }

#news-homepage .list_2img .news_card {
  width: calc(50% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem; }

#news-homepage .list_4img {
  margin-top: 2rem;
  margin-bottom: 5rem; }

#news-homepage .list_4img .news_card {
  width: calc(25% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem; }

/* SECTION Réseaux sociaux */
.body-reseaux {
  padding-bottom: 10rem; }
  .body-reseaux .h2 {
    margin-bottom: 3rem;
    font-weight: 400; }
  .body-reseaux .slick-slide {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    height: auto; }
    @media (max-width: 991px) {
      .body-reseaux .slick-slide {
        margin-left: .5rem;
        margin-right: .5rem; } }
    .body-reseaux .slick-slide > div {
      height: 100%; }
  .body-reseaux .slide {
    width: 29.2rem !important;
    height: 100%; }
    .body-reseaux .slide.slide-double {
      width: 62.4rem !important; }
    @media (max-width: 991px) {
      .body-reseaux .slide {
        width: 18rem !important; }
        .body-reseaux .slide.slide-double {
          width: 38rem !important; } }
    @media (max-width: 575px) {
      .body-reseaux .slide {
        width: 100% !important; }
        .body-reseaux .slide.slide-double {
          width: 100% !important; } }
  .body-reseaux .link {
    display: flex;
    gap: 1.6rem;
    border-radius: .8rem;
    padding: .8rem;
    background-color: #fff;
    height: 100%;
    box-shadow: 0 .6rem .8rem 0 #cfcfcf;
    border: .1rem solid #eee; }
    @media (max-width: 575px) {
      .body-reseaux .link {
        gap: .8rem;
        width: fit-content;
        margin-left: auto;
        margin-right: auto; } }
    .body-reseaux .link header {
      position: relative;
      flex-shrink: 0; }
      @media (max-width: 575px) {
        .body-reseaux .link header {
          flex-shrink: 1;
          display: flex; } }
      .body-reseaux .link header .logo-slide-simple {
        position: absolute;
        top: .5rem;
        right: .5rem; }
    .body-reseaux .link .description-content {
      width: 100%; }
      .body-reseaux .link .description-content .desc {
        display: -webkit-box;
        -webkit-line-clamp: 11;
        line-clamp: 11;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        overflow: hidden; }
        @media (max-width: 991px) {
          .body-reseaux .link .description-content .desc {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            line-clamp: 5;
            -webkit-box-orient: vertical;
            box-orient: vertical;
            overflow: hidden;
            line-height: 1.2; } }
    .body-reseaux .link .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .6rem; }
      .body-reseaux .link .header-content .left-col {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0 .8rem; }
        .body-reseaux .link .header-content .left-col .title {
          font-size: 1.8rem;
          font-weight: 700; }
        .body-reseaux .link .header-content .left-col .date-post {
          font-size: 1.4rem;
          font-weight: 500;
          opacity: .6; }
    .body-reseaux .link .img-content img {
      border-radius: .8rem;
      height: 100%;
      width: 27rem;
      aspect-ratio: 1;
      object-fit: cover; }
      @media (max-width: 991px) {
        .body-reseaux .link .img-content img {
          width: 16.4rem; } }
  .body-reseaux .reseaux-slider {
    margin-left: -1rem;
    margin-right: -1rem;
    contain: none; }
    .body-reseaux .reseaux-slider .slick-list {
      padding: 1.5rem 1rem; }
    .body-reseaux .reseaux-slider .slick-prev {
      left: -45px;
      opacity: .75; }
    .body-reseaux .reseaux-slider .slick-next {
      right: -45px;
      opacity: .75; }
    .body-reseaux .reseaux-slider .slick-dots {
      bottom: -6rem; }
      .body-reseaux .reseaux-slider .slick-dots li button::before {
        background-color: #9D9D9C; }

/* SECTION MO magazine */
.mo_book {
  margin-top: 10rem; }
  .mo_book .slick-dots {
    bottom: -3.6rem; }

.mo_book .h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  font-weight: 500;
  color: #454545;
  text-transform: none;
  margin-bottom: 1.2rem; }

.mo_book .h4 p {
  margin-top: 0;
  margin-bottom: 0; }

.mo_book .h4 .icon {
  margin-right: 1.5rem; }

.mo_book .stitre {
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 4rem;
  line-height: 1.5; }

.list-card-mo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -1rem;
  margin-right: -1rem; }
  .list-card-mo .slick-list {
    width: 100%; }
  .list-card-mo .slick-prev {
    left: 1rem; }
  .list-card-mo .slick-next {
    right: 1rem; }

.card-mo a {
  position: relative;
  display: block;
  margin-left: 1rem;
  margin-right: 1rem; }
  .card-mo a .overlay-magazine {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity .3s; }
    .card-mo a .overlay-magazine .button_pink_overlay {
      height: 4rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: none;
      font-weight: 400;
      font-size: 1.8rem; }
      .card-mo a .overlay-magazine .button_pink_overlay:hover {
        color: #fff;
        background-color: #D22350; }
  .card-mo a:hover .overlay-magazine {
    opacity: 1;
    visibility: visible; }
  .card-mo a img {
    width: 100%; }

.numero-mo {
  color: #525558;
  font-size: 2.4rem;
  font-weight: 500;
  margin-top: .6rem;
  margin-left: 1rem; }

.numero-mo .icon {
  font-size: 3.5rem; }

.card-mo .titre {
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-left: 1rem; }

/* SECTION Marques */
.marques_homepage {
  margin-bottom: 8rem; }

.bandeau_marques {
  margin-top: 10rem;
  margin-bottom: 8rem; }
  .bandeau_marques .h2 {
    font-weight: 400; }

.slider_marques {
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: 5rem; }

.slider_marques .slick-slide {
  margin-left: 2rem;
  margin-right: 2rem; }

.marque_card {
  display: block;
  text-align: center;
  border-radius: 2.5rem;
  background-color: #fff;
  border: .1rem solid #E2DDDD;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.1);
  padding: 4rem 3rem;
  transition: box-shadow .3s; }

a.marque_card:hover {
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.5); }

.marque_card figure {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
  margin: 0 auto; }

.marque_card figure img {
  object-fit: contain;
  max-height: 100%;
  margin-bottom: 2rem; }

.marque_card .title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #a0a8bf; }

.marque_desc {
  line-height: 1.5;
  margin-top: 1.2rem;
  height: 9.6rem;
  overflow: hidden; }

/* Filtres boutiques */
#crit_search .expand_box,
#recherche_mag .expand_box {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none; }

#recherche_mag .expand_box {
  pointer-events: none; }

#filtres_boutiques {
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity .3s; }

#filtres_boutiques .icon-close {
  position: absolute;
  top: -.2rem;
  right: 1rem;
  font-size: 1.8rem;
  cursor: pointer;
  z-index: 1;
  transition: opacity .3s; }
  #filtres_boutiques .icon-close:hover {
    opacity: .8; }

.open #filtres_boutiques {
  position: relative;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 1; }

#filtres_boutiques .list_checkbox {
  padding-top: .8rem;
  padding-left: 1rem;
  padding-right: 1rem; }

#filtres_boutiques .list_services {
  width: 960px; }

#filtres_boutiques .list_specialities {
  width: calc(200% / 3); }

#filtres_boutiques .list_checkbox .checkbox {
  margin-left: 0;
  color: #7e828c;
  width: calc(50% - 2rem); }
  #filtres_boutiques .list_checkbox .checkbox label {
    line-height: 1.1;
    padding-right: .5rem; }

#filtres_boutiques .list_checkbox .list_specialities .checkbox {
  display: inline-block; }

#filtres_boutiques .list_checkbox ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  vertical-align: top;
  margin: .2em auto 1rem; }

#filtres_boutiques .list_checkbox .title {
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  text-align: left; }

#filtres_boutiques .list_checkbox .sep_dotted,
#recherche_boutique .list_checkbox .sep_dotted {
  width: 100%;
  max-width: 96rem;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  margin-bottom: 6px;
  padding-bottom: 2px;
  border-bottom: 1px dotted #7e828c; }

#home #filtres_boutiques .list_checkbox .checkbox {
  text-align: left; }

#filtres_boutiques input[type=checkbox]:checked + label::after {
  font-family: kfont;
  font-size: inherit;
  content: "" !important;
  color: #737373;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#filtres_boutiques .list_checkbox label::before {
  top: 0;
  left: 0; }

#filtres_boutiques .list_selects {
  display: inline-block;
  vertical-align: top; }

#home #filtres_boutiques .list_selects {
  padding-top: 20px; }

#home #filtres_boutiques .list_selects .findus {
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  display: none;
  width: 200px;
  height: 32px;
  margin: 0 auto;
  padding-left: 15px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-align: left;
  color: #fff;
  border: 0;
  background-color: #35c3c8; }

#home #filtres_boutiques .list_selects .findus:hover {
  background-color: #00a4a9; }

#filtres_boutiques .list_selects select {
  font-style: italic;
  display: block;
  width: 200px;
  height: 32px;
  margin-bottom: 15px;
  padding-left: 10px;
  color: #7e828c; }

#filtres_boutiques .list_button {
  display: inline-block;
  margin-left: 50px;
  vertical-align: top; }

/* Autocomplete */
.body_opti_locator .ui-widget-content,
.body_home .ui-widget-content {
  border-radius: 0 0 1.2rem 1.2rem;
  margin-top: 1rem;
  line-height: 1.2;
  padding-top: .6rem;
  max-height: 28.6rem;
  overflow-y: scroll;
  overflow-x: hidden;
  color: #656975;
  font-size: 1.6rem; }
  .body_opti_locator .ui-widget-content .ui-menu-item,
  .body_home .ui-widget-content .ui-menu-item {
    margin: .4rem .8rem; }
  .body_opti_locator .ui-widget-content .ui-menu-item-wrapper,
  .body_home .ui-widget-content .ui-menu-item-wrapper {
    padding: .8rem 1.2rem; }

.ui-button.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active {
  border-color: transparent;
  background: #efefef;
  color: #656975; }

.bandeau_landing {
  overflow: hidden;
  max-height: 250px; }

.bandeau_landing > img {
  width: 100%; }

.landing_background {
  padding: 10px 10px;
  background-color: #fef3f9; }

.landing_img {
  max-width: 500px; }

@media (max-width: 1499px) {
  .spe-homecard-container .spe-content {
    padding: 1rem; }
    .spe-homecard-container .spe-content .h3 {
      font-size: 2rem; } }

@media (max-width: 1199px) {
  .bg-homepage-c {
    max-height: 120rem; }
  #recherche_boutique .h1 {
    padding-top: 3rem; }
  .spe-homecard-container {
    margin-left: -1rem;
    margin-right: -1rem; }
    .spe-homecard-container .spe-homecard {
      width: calc(100% / 3 - 2rem); }
    .spe-homecard-container .spe-content {
      width: 92%; }
  .block-rdv .left-col {
    padding: 3rem 2rem; }
    .block-rdv .left-col a {
      padding: 1.4rem 1rem; }
  .block-rdv .right-col .highlight {
    font-size: 4rem; }
  .block-rdv .right-col .mediumlight {
    font-size: 3.8rem; }
  .block-rdv .right-col .lowlight {
    font-size: 2rem; }
  .marque-rdv .block-marque {
    padding: 2rem 3rem 3rem; }
  .marque-rdv .h4 {
    font-size: 2.4rem; }
  .block-valeurs .text {
    font-size: 2rem; }
  .news_block .news_content {
    margin: 1rem .5rem; }
  #news-homepage {
    margin-top: 10rem; }
  .bandeau_marques,
  .mo_book {
    margin-top: 5rem; } }

@media (max-width: 991px) {
  .search_opticien input,
  .search_opticien .custom-select,
  .search_marque input,
  .search_marque .custom-select {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .spe-homecard-container {
    flex-wrap: wrap; }
    .spe-homecard-container .spe-homecard {
      width: calc(50% - 3rem);
      margin-bottom: 2rem; }
  .specialites_home {
    padding-top: 4rem; }
    .specialites_home .h2 {
      margin-bottom: 2rem; }
  #news-homepage,
  .bandeau_marques,
  .mo_book {
    margin-top: 3rem; }
  .marque-rdv {
    margin-top: 5rem; }
    .marque-rdv .marque-rdv-content {
      flex-wrap: wrap; }
      .marque-rdv .marque-rdv-content > div {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0; }
      .marque-rdv .marque-rdv-content .block-valeurs {
        margin-top: 3rem;
        max-width: none;
        text-align: center; }
        .marque-rdv .marque-rdv-content .block-valeurs .h4 {
          text-align: center; }
    .marque-rdv .block-marque {
      text-align: center;
      margin-top: -3rem;
      background-position: right; }
      .marque-rdv .block-marque .h4 {
        text-align: center; }
      .marque-rdv .block-marque form {
        justify-content: center; }
  #news-homepage .h2 img {
    margin-top: .1rem;
    max-width: 28rem; }
  #news-homepage .h5 {
    font-size: 1.8rem;
    margin-bottom: 2.5rem; }
  #news-homepage .news_card {
    margin-bottom: 3rem; }
    #news-homepage .news_card .news_img img {
      width: 100%; }
  #news-homepage .list_4img {
    display: none; }
  .mo_book .stitre {
    font-size: 1.8rem;
    margin-bottom: 2rem; }
  .newsletter_container {
    height: 22rem; }
    .newsletter_container .newsletter_content {
      flex-direction: column; }
      .newsletter_container .newsletter_content .title_newsletter {
        margin-bottom: 3rem; }
  .slider_marques {
    margin-top: 3rem; }
    .slider_marques .slick-slide {
      margin-left: 1rem;
      margin-right: 1rem; }
    .slider_marques .slick-dots {
      right: 0;
      bottom: -3.5rem; }
  .marque_card {
    padding: 2rem 1rem; }
  #filtres_boutiques .list_services {
    width: 100%; } }

@media (max-width: 767px) {
  .search_opticien,
  .search_marque {
    flex-direction: column;
    align-items: center; }
    .search_opticien .code_post,
    .search_marque .code_post {
      border-radius: 0;
      border-bottom: .1rem solid #eee; }
    .search_opticien .button_grey,
    .search_marque .button_grey {
      width: 30rem;
      border-radius: 0;
      justify-content: center;
      border-top: 0.1rem solid #0ea6af; }
    .search_opticien .plus_criteres,
    .search_marque .plus_criteres {
      margin-left: 1rem; }
  .search_marque .custom-select .select2,
  .search_marque input .select2,
  .search_opticien .custom-select .select2,
  .search_opticien input .select2 {
    padding-left: 1rem; }
  .body_home .ui-widget-content,
  .body_opti_locator .ui-widget-content {
    max-width: 30rem; }
  .search_marque .marque_pref::before,
  .search_opticien .specialite_pref::before {
    content: none; }
  .spe-homecard-container .spe-homecard {
    width: calc(50% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem; }
  .specialites_home {
    padding-top: 4rem; }
    .specialites_home .h2 {
      margin-bottom: 2rem; }
  .mo_book .h4 {
    font-size: 3.5rem; }
  .panel-content {
    width: 80%;
    padding: 2rem; } }

@media (max-width: 575px) {
  .bg-homepage-c {
    max-height: 80rem; }
  #filtres_boutiques .list_checkbox {
    padding-left: .5rem;
    padding-right: 0; }
    #filtres_boutiques .list_checkbox .checkbox {
      max-width: calc(50% - .6rem); }
      #filtres_boutiques .list_checkbox .checkbox label {
        font-size: 1.2rem;
        margin-bottom: .3rem; }
    #filtres_boutiques .list_checkbox ul {
      max-height: 16rem; }
  .marque-rdv .block-marque {
    padding: 1rem; }
    .marque-rdv .block-marque form {
      flex-direction: column; }
      .marque-rdv .block-marque form input {
        padding-left: 2rem;
        margin-bottom: 1rem; }
    .marque-rdv .block-marque button[type="submit"] {
      width: 6.6rem;
      border-radius: 50%; }
  .search_marque .submit_button,
  .search_opticien .submit_button {
    max-width: 100%;
    width: 30rem; }
    .search_marque .submit_button button,
    .search_opticien .submit_button button {
      width: 100%; }
  .block-rdv {
    flex-wrap: wrap; }
    .block-rdv .left-col,
    .block-rdv .right-col {
      width: 100%; }
    .block-rdv .left-col {
      order: 1; }
    .block-rdv .right-col {
      padding-top: .5rem;
      padding-bottom: .5rem; }
  .spe-homecard-container .spe-homecard {
    width: 100%; }
  .block-valeurs .text {
    font-size: 1.8rem; }
  #news-homepage .h5 {
    font-size: 1.6rem; }
  #news-homepage .h2 {
    flex-wrap: wrap; }
    #news-homepage .h2 img {
      margin-top: .6rem; }
  #news-homepage .news_card {
    margin-bottom: 1rem; }
  #news-homepage .list_2img .news_card {
    width: 100%; }
  .marque-rdv .h4 {
    font-size: 2.2rem; }
  .card-mo {
    width: 100%; }
    .card-mo:nth-child(2) {
      display: none; }
  #news-homepage .list_2img {
    flex-wrap: wrap;
    margin-top: 1rem; }
  .newsletter_container .newsletter_content {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
    .newsletter_container .newsletter_content .title_newsletter {
      margin-bottom: 2rem;
      font-size: 2.4rem; }
  .newsletter_container input[type="text"] {
    padding-left: 2rem;
    margin-left: 0;
    height: 5.5rem;
    width: 100%; }
  .newsletter_container button[type="submit"] {
    padding-left: 1rem;
    padding-right: 1.2rem;
    height: 5.5rem;
    font-size: 4rem; } }

.header-optilocator {
  background-position: center;
  transition: height .3s;
  align-items: flex-start;
  padding-top: 2rem;
  height: 16.5rem; }

.header-optilocator.open {
  height: 33rem; }

#recherche_mag {
  z-index: 1; }

#recherche_mag .h3 {
  font-size: 2.3rem;
  margin-bottom: .8rem; }

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none; }

#opti_locator .newsletter_container {
  margin-top: 0; }

#opti_locator .content-optilocator {
  display: flex;
  justify-content: space-between; }

.content-optilocator .left-col,
.content-optilocator .right-col {
  width: calc(50% - 1.2rem); }

.content-optilocator .right-col {
  position: relative; }

.content-optilocator .left-col {
  padding-left: 8rem;
  padding-bottom: 3rem; }

.content-optilocator .left-col header {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem; }

.content-optilocator .left-col .h2 {
  font-size: 2.1rem;
  color: #A0A8BF;
  margin-bottom: 1.5rem;
  text-align: left; }

.content-optilocator .type-affichage {
  display: flex;
  align-items: center; }

.content-optilocator .type-affichage .icon {
  font-size: 2.1rem;
  margin-left: 1rem;
  line-height: 0;
  color: #D4DBEB;
  cursor: pointer;
  transition: color .3s; }

.content-optilocator .type-affichage .icon.active,
.content-optilocator .type-affichage .icon:hover {
  color: #9FA8BD; }

.content-optilocator .type-affichage .icon-line {
  font-size: 2.6rem; }

.content-optilocator .filtres-magasins {
  font-size: 1.7rem;
  color: #D22350;
  margin-bottom: 1.2rem; }

.content-optilocator .filtre {
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  border: .1rem solid #D22350;
  padding: .5rem 1rem;
  color: #D22350;
  border-radius: 2rem;
  margin-left: .5rem;
  margin-right: .5rem;
  margin-bottom: .8rem; }

.content-optilocator .filtre .icon {
  line-height: 0;
  margin-left: .8rem;
  transition: color .3s; }

.content-optilocator .filtre:hover .icon {
  color: #a0a8bf; }

.content-optilocator .delete-filters {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.8rem;
  height: 2.8rem;
  font-size: 1.2rem;
  color: #fff;
  background-color: #D22350;
  line-height: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: all .3s; }

.content-optilocator .delete-filters:hover {
  background-color: #a0a8bf; }

/* Présentation magasin commun présentation ligne / grille */
.mag-card {
  font-size: 1.2rem;
  font-weight: 500; }

.mag-card .bloc-info-mag {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 3rem;
  width: 4.8rem; }
  .mag-card .bloc-info-mag .text-favori {
    display: none; }
  .mag-card .bloc-info-mag .text-non-favori {
    display: block;
    hyphens: none; }
  .mag-card .bloc-info-mag.mag-favo .icon {
    color: #D22350; }
  .mag-card .bloc-info-mag.mag-favo .text-favori {
    display: block;
    hyphens: none; }
  .mag-card .bloc-info-mag.mag-favo .text-non-favori {
    display: none; }

.mag-card .mag-favori {
  cursor: pointer;
  transition: color .3s; }
  .mag-card .mag-favori:hover {
    color: #000; }
  .mag-card .mag-favori .icon {
    font-size: 3.6rem;
    margin-top: -.8rem;
    line-height: 0; }

.mag-card .ouverture-mag {
  text-align: center;
  line-height: 1.4;
  width: 10rem; }

.mag-card .ouverture-mag .statut-ouverture {
  text-transform: uppercase;
  margin-bottom: .9rem; }

.mag-card .ouverture-mag .statut-ouverture.mag-opened {
  color: #5EB52C; }

.mag-card .ouverture-mag .statut-ouverture.mag-closed {
  color: #d93434; }

.mag-card a,
.mag-card .lien-off {
  color: #68728b;
  text-align: center;
  line-height: 1.3; }

.mag-card a .icon,
.mag-card .lien-off .icon {
  font-size: 2rem; }

.mag-card a:hover,
.mag-card .lien-off:hover {
  color: #000; }

/* Présentation magasin en ligne */
.liste-mag-line .mag-card {
  position: relative;
  border-radius: 2.2rem;
  background-color: #F4F4F6;
  margin-bottom: 2rem;
  width: 100%; }

.liste-mag-line .mag-card .mag-card-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem 8rem 1.4rem 1.4rem;
  min-height: 10rem; }

.liste-mag-line .mag-card .mag-photo {
  width: 12rem;
  transition: all .3s; }

.liste-mag-line .mag-card .mag-photo img {
  max-height: 7.2rem;
  max-width: 12rem;
  object-fit: contain;
  border-radius: 1rem; }

.liste-mag-line .mag-card .name-mag {
  width: calc(100% - 40rem); }

.content .liste-mag-line .mag-card .h3 {
  font-size: 2rem;
  text-transform: none;
  text-align: left;
  margin-top: 0; }

.liste-mag-line .mag-card .mag-city {
  display: flex;
  align-items: center;
  font-size: 1.4rem; }

.liste-mag-line .mag-card .mag-city .icon {
  margin-right: .2rem; }

.liste-mag-line .mag-card .see-more-mag {
  position: absolute;
  top: .6rem;
  bottom: .6rem;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  font-size: 1.6rem;
  border-left: .1rem solid #C6C9D3;
  line-height: 0;
  max-height: 100%; }

.liste-mag-line .mag-card.detail-open .name-mag {
  width: calc(100% - 34.8rem); }

.mag-card .mag-card-detail {
  height: 0;
  overflow: hidden; }

.mag-card .mag-card-detail-content {
  margin-top: 1.2rem;
  padding: 2.2rem 1.4rem 1.4rem 1.4rem;
  display: flex;
  justify-content: space-between; }

.mag-card .mag-card-detail-content .left-col-detail {
  width: calc(50% - 3rem); }

.mag-card .mag-card-detail-content .right-col-detail {
  width: calc(50% - 3rem); }

.mag-card .mag-card-detail .photo-mag-detail {
  aspect-ratio: 828 / 221;
  width: 100%;
  object-fit: contain; }

.mag-card .mag-card-detail .h3 {
  text-transform: uppercase;
  font-size: 1.4rem; }

.mag-card .mag-card-detail .detail-adresse {
  font-size: 1.4rem;
  line-height: 1.2; }

.mag-card .mag-card-detail .detail-tel {
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  margin-top: 1rem;
  margin-bottom: 2rem; }

.mag-card .mag-card-detail .detail-tel .icon {
  margin-right: .6rem; }

.mag-card .mag-card-detail .liste-spe {
  margin-bottom: 1.5rem; }

.mag-card .mag-card-detail .liste-spe .spe {
  display: inline-block;
  font-size: 1.2rem;
  color: #fff;
  background-color: #A0A8BF;
  padding: .8rem;
  border-radius: .5rem;
  margin-bottom: 0.4rem; }

.mag-card .mag-card-detail .button_outline {
  color: #D22350;
  margin-bottom: .8rem;
  text-align: left; }

.mag-card .mag-card-detail .button_outline:focus,
.mag-card .mag-card-detail .button_outline:hover {
  color: #fff; }

.mag-card .mag-card-detail .right-col-detail .h3 {
  margin-bottom: 2rem; }

.mag-card .mag-card-detail .ligne-horaires {
  margin-bottom: 1.5rem; }

.mag-card .mag-card-detail .ligne-horaires .day {
  display: inline-block;
  font-weight: 700;
  width: 8rem; }

.mag-card .mag-card-detail .ligne-horaires .matin {
  display: inline-block;
  width: 9rem; }

.mag-card .mag-card-detail .liste-services {
  display: flex;
  margin-top: 3.5rem;
  text-transform: uppercase;
  text-align: center;
  margin-left: -1rem;
  margin-right: -1rem; }

.mag-card .mag-card-detail .liste-services .service {
  max-width: 10rem;
  margin-left: 1rem;
  margin-right: 1rem; }

.mag-card .mag-card-detail .liste-services .icon {
  font-size: 2.5rem;
  margin-bottom: .5rem; }

/* Présentation magasin en grille */
.liste-mag-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }

.liste-mag-grid .mag-card {
  width: calc(100% / 3 - 1.4rem);
  margin-left: .7rem;
  margin-right: .7rem; }

.liste-mag-grid.liste-mag-5-grid .mag-card {
  width: calc(100% / 5 - 1.4rem);
  margin-left: .7rem;
  margin-right: .7rem;
  margin-top: 1.6rem; }

.liste-mag-grid .mag-card .mag-photo img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  aspect-ratio: 260 / 170; }

.liste-mag-grid .mag-card .h3 {
  font-size: 1.8rem; }

.liste-mag-grid .mag-card .mag-city {
  font-size: 1.2rem; }

.liste-mag-grid .mag-card .details-grille {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 8rem; }
  .liste-mag-grid .mag-card .details-grille .bloc-info-mag {
    width: 25%; }

/* Magasin sans minisite */
.liste-mag.liste-mag-without-site .mag-card .mag-card-content {
  min-height: 6rem;
  padding-right: 1.4rem; }
  .liste-mag.liste-mag-without-site .mag-card .mag-card-content .name-mag {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .liste-mag.liste-mag-without-site .mag-card .mag-card-content .name-mag .h3 {
      color: #68728B;
      margin-bottom: 0;
      margin-right: 1rem; }

@media (max-width: 575px) {
  .liste-mag.liste-mag-without-site .mag-card .mag-card-content {
    align-items: center;
    padding-left: 1.4rem; }
  .liste-mag.liste-mag-without-site .mag-card .mag-city {
    display: inline-block;
    text-align: right; } }

/* Map */
#nright_content {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: calc(100% - 960px);
  vertical-align: top; }

#opti_locator #map_c {
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  min-height: 400px; }

#opti_locator #map {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-bottom: 0; }

.content-optilocator .marker-cluster div {
  background-image: url(../img/marker-generic.svg);
  background-size: contain; }

.content-optilocator .leaflet-popup .name {
  text-transform: uppercase;
  color: #D22350; }

.content-optilocator .leaflet-popup a,
.content-optilocator .leaflet-popup .lien-off {
  color: #D22350; }

.content-optilocator .leaflet-popup-content-wrapper {
  padding: 1rem .8rem 1rem .8rem;
  width: 24rem;
  height: auto;
  border-radius: .6rem; }
  .content-optilocator .leaflet-popup-content-wrapper .popup_content {
    text-align: center; }
    .content-optilocator .leaflet-popup-content-wrapper .popup_content.popup_without_link {
      margin-bottom: 1.8rem; }

.content-optilocator .leaflet-popup a.link_minisite,
.content-optilocator .leaflet-popup .lien-off.link_minisite {
  margin-top: .4rem;
  display: block; }

.content-optilocator .leaflet-container img.leaflet-marker-icon {
  transition: all .15s; }
  .content-optilocator .leaflet-container img.leaflet-marker-icon.marker-mag-hover {
    width: 4.2rem !important;
    height: 4.2rem !important;
    margin-left: -2.1rem !important;
    margin-top: -4rem !important; }

.content-optilocator .leaflet-container a.leaflet-popup-close-button,
.content-optilocator .leaflet-container .lien-off.leaflet-popup-close-button {
  color: #68728b;
  z-index: 9;
  top: -.2rem;
  right: -3rem; }

.body_magasin_favoris .content-optilocator .left-col {
  margin-left: auto;
  margin-right: auto; }

.body_magasin_favoris .aucun-favoris {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30rem; }

/* Page magasin dédiée */
.header-page-classic .titre-magasin {
  align-self: center;
  margin-top: -2rem; }

@media (max-width: 1499px) {
  .content-optilocator .left-col {
    padding-left: 1.5rem; }
    .content-optilocator .left-col .h2 {
      margin-bottom: 0.5rem;
      width: 100%;
      text-align: left; }
    .content-optilocator .left-col header {
      flex-wrap: wrap;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
  .liste-mag-grid .mag-card {
    width: calc(100% / 2 - 1rem); }
  .liste-mag-line .mag-card .mag-card-content {
    padding: .5rem 6rem 1rem .5rem; } }

@media (max-width: 1199px) {
  .content-optilocator .left-col {
    width: calc(60% - 0.5rem); }
  .content-optilocator .right-col {
    width: calc(40% - 0.5rem); }
  .liste-mag-grid.liste-mag-5-grid .mag-card {
    width: calc(100% / 4 - 1.4rem); }
  .liste-mag-line .mag-card .h3 {
    font-size: 1.6rem;
    line-height: .7; }
  .liste-mag-line .mag-card .mag-photo {
    width: 10rem; }
    .liste-mag-line .mag-card .mag-photo img {
      max-width: 100%; }
  .liste-mag-line .mag-card .name-mag {
    width: calc(100% - 38rem); } }

@media (max-width: 991px) {
  .content-optilocator {
    flex-wrap: wrap; }
    .content-optilocator .left-col {
      width: calc(100% - 1.5rem);
      padding-bottom: 0; }
    .content-optilocator .right-col {
      width: 100%; }
  .liste-mag-grid .mag-card {
    width: calc(100% / 3 - 1rem); }
    .liste-mag-grid .mag-card .details-grille {
      margin-bottom: 5rem; }
  .liste-mag-grid.liste-mag-5-grid .mag-card {
    width: calc(100% / 3 - 1rem);
    margin-left: .5rem;
    margin-right: .5rem; }
  .liste-mag-line .mag-card {
    margin-bottom: 1rem; }
  #opti_locator #map_c {
    position: static !important;
    height: auto;
    margin-top: 2rem; } }

@media (max-width: 767px) {
  .header-optilocator {
    height: 30rem;
    background-position: 0; }
  .header-optilocator.open {
    height: 42rem; }
  .content-optilocator .left-col .h2 {
    font-size: 1.8rem; }
  .liste-mag-grid .mag-card {
    width: calc(100% / 2 - 1rem); }
  .liste-mag-grid.liste-mag-5-grid .mag-card {
    width: calc(100% / 2 - 1rem); }
  .liste-mag-line .mag-card .mag-card-content {
    font-size: 1.1rem; }
  .liste-mag-line .mag-card .h3 {
    font-size: 1.4rem; }
  .liste-mag-line .mag-card .mag-photo {
    display: none; }
  .liste-mag-line .mag-card .mag-city {
    font-size: 1.2rem; }
  .liste-mag-line .mag-card .name-mag {
    width: calc(100% - 28rem); } }

@media (max-width: 575px) {
  .liste-mag-grid .mag-card {
    width: 100%; }
  .liste-mag-grid.liste-mag-5-grid .mag-card {
    width: 100%; }
  .liste-mag-line .mag-card .mag-card-content {
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: 12rem; }
  .liste-mag-line .mag-card .name-mag {
    width: 100%; }
  .mag-card .mag-card-detail-content {
    flex-wrap: wrap;
    padding: 1.5rem .5rem; }
    .mag-card .mag-card-detail-content .left-col-detail,
    .mag-card .mag-card-detail-content .right-col-detail {
      width: 100%; }
  .content-optilocator .right-col {
    width: calc(100% - 3rem);
    margin-left: 1.5rem; }
  #opti_locator #map {
    height: calc(100% - 2rem); } }

/* Animation ouverture popin */
.leaflet-popup .leaflet-popup-close-button {
  display: none; }

.leaflet-popup .leaflet-popup-content-wrapper {
  overflow: hidden;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  position: absolute;
  animation: fadeInPopup .4s ease-out; }

@keyframes fadeInPopup {
  0% {
    opacity: 0;
    height: 0;
    width: 0; }
  50% {
    opacity: 1;
    height: 0;
    width: 24rem; }
  100% {
    opacity: 1;
    height: 10.5rem;
    width: 24rem; } }
  .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
    width: 30rem;
    height: auto;
    text-align: center;
    padding: 0;
    margin: 0 0 0 1rem;
    overflow: hidden; }
    .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content div {
      height: 100%; }
      .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content div .caption {
        color: #F1F1F1;
        height: 100%;
        white-space: nowrap; }

#marques .header-marques {
  background-size: cover;
  height: 38.6rem; }

#marques .header-marques .h3 {
  font-size: 5rem;
  margin-bottom: 2rem; }

#marques .h2 {
  font-size: 3.2rem;
  color: #A0A8BF;
  margin-top: 4rem;
  margin-bottom: 5rem; }

#marques .marque_card {
  border: 0.3rem solid #e2dddd;
  height: 32rem; }

#marques .marque_card.selected-card {
  border-color: #D22350; }

#marques .liste-marques {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5rem; }

#marques .liste-marques .marque_card_container {
  width: calc(25% - 2rem);
  margin-bottom: 3rem;
  margin-left: 1rem;
  margin-right: 1rem; }

.search_marque .custom-select {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  color: #abadb4; }
  .search_marque .custom-select .marque_dropdown {
    position: absolute;
    top: 6.8rem;
    left: 0;
    right: 0;
    display: none;
    background-color: #fff;
    border: .1rem solid #aaa;
    border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
    z-index: 2; }
  .search_marque .custom-select .section-form-marque {
    border-bottom: .1rem solid #ddd;
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .search_marque .custom-select .input-marque {
    color: #abadb4;
    text-align: left;
    font-size: 1.6rem;
    font-weight: 500; }
    .search_marque .custom-select .input-marque label {
      display: flex;
      align-items: center;
      padding-top: .7rem;
      padding-bottom: .7rem;
      height: auto; }
      .search_marque .custom-select .input-marque label::before {
        top: 1.2rem; }
      .search_marque .custom-select .input-marque label::after {
        top: 1.6rem;
        background: #737373; }
    .search_marque .custom-select .input-marque input:checked + label {
      color: #68728b; }
    .search_marque .custom-select .input-marque .icon {
      font-size: 2.8rem;
      margin-right: 1rem;
      margin-left: .5rem;
      line-height: 0; }
    .search_marque .custom-select .input-marque.checkbox_style label::after {
      top: .9rem;
      background-color: transparent; }
    .search_marque .custom-select .input-marque:hover {
      color: #68728b; }
  .search_marque .custom-select .custom-select-placeholder .filtre-brands {
    color: #68728b; }

.search_marque .plus_criteres a {
  color: #fff; }

@media (max-width: 1199px) {
  #marques .liste-marques .marque_card_container {
    width: calc(100% / 3 - 2rem); } }

@media (max-width: 767px) {
  #marques .liste-marques .marque_card_container {
    width: calc(50% - 2rem); }
  .search_marque .custom-select .marque_dropdown {
    max-width: 27rem;
    left: 15px; } }

@media (max-width: 575px) {
  #marques .header-marques .h3 {
    font-size: 3rem; }
  #marques .liste-marques .marque_card_container {
    width: 100%; } }

#news {
  margin-bottom: 4rem; }
  #news .pager_c {
    margin-top: 2rem; }

.body_body_news .header-page-classic {
  height: 15rem; }

.filter_header {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-weight: 400; }
  .filter_header form {
    margin-left: 2rem; }
  .filter_header .text_label {
    line-height: 1; }
  .filter_header .custom-select {
    margin-left: 1rem; }
    .filter_header .custom-select::after {
      font-family: kfont;
      content: "\EA50";
      display: block;
      position: absolute;
      right: .5rem;
      top: 1.2rem;
      font-size: .8rem;
      color: #abadb4;
      pointer-events: none; }

/* Style générique */
.news_block .news_img {
  position: relative;
  display: block;
  transition: filter .3s; }
  .news_block .news_img img {
    aspect-ratio: 303 / 131;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover; }

.news_block .news_content {
  margin: 1rem 1.2rem; }

.news_block .news_date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  background-color: #D22350;
  margin-bottom: 1.5rem;
  padding: .5rem .6rem; }

.news_block .news_title {
  display: block;
  height: 4.4rem;
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 500;
  color: #D22350;
  overflow: hidden;
  text-align: left; }
  .news_block .news_title a, .news_block .news_title .lien-off {
    color: #D22350; }

.news_block .news_desc {
  line-height: 1.5;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  height: 9.6rem;
  overflow: hidden; }

.news_block .news-see-details {
  text-align: center;
  font-weight: 500;
  border-top: .2rem solid #D22350;
  padding-top: .8rem; }
  .news_block .news-see-details a, .news_block .news-see-details .lien-off {
    display: inline-block;
    color: #D22350; }
    .news_block .news-see-details a:hover, .news_block .news-see-details .lien-off:hover {
      color: #9fa8bd; }
    .news_block .news-see-details a .not-expanded, .news_block .news-see-details .lien-off .not-expanded {
      display: block; }
    .news_block .news-see-details a .expanded, .news_block .news-see-details .lien-off .expanded {
      display: none; }
      .news_block .news-see-details a .expanded .icon, .news_block .news-see-details .lien-off .expanded .icon {
        font-size: 2.5rem;
        line-height: 0; }
    .news_block .news-see-details a.selected-card .not-expanded, .news_block .news-see-details .lien-off.selected-card .not-expanded {
      display: none; }
    .news_block .news-see-details a.selected-card .expanded, .news_block .news-see-details .lien-off.selected-card .expanded {
      display: block; }

.news_block .new_type_fournisseur .news_date {
  background-color: #417f35; }

.news_block .new_type_fournisseur .news_title {
  color: #417f35; }
  .news_block .new_type_fournisseur .news_title a, .news_block .new_type_fournisseur .news_title .lien-off {
    color: #417f35; }

.news_block .new_type_fournisseur .news-see-details {
  border-top-color: #417f35; }
  .news_block .new_type_fournisseur .news-see-details a, .news_block .new_type_fournisseur .news-see-details .lien-off {
    color: #417f35; }

.news_main .news-see-details {
  display: none; }

.news_main .news_img {
  position: relative; }
  .news_main .news_img img {
    aspect-ratio: 300/145; }
  .news_main .news_img .button_pink {
    height: 4rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    font-weight: 400;
    font-size: 1.8rem; }
    .news_main .news_img .button_pink:hover {
      color: #fff;
      background-color: #D22350; }
  .news_main .news_img .overlay-news {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity .3s; }
  .news_main .news_img:hover {
    filter: none; }
    .news_main .news_img:hover .overlay-news {
      opacity: 1;
      visibility: visible; }

.news_main .new_type_fournisseur .button_pink {
  background-color: #417f35;
  border-color: #417f35; }
  .news_main .new_type_fournisseur .button_pink:hover {
    background-color: #417f35; }

/* Style spé page news */
.bloc_actu .liste-news {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem; }
  .bloc_actu .liste-news .news_card {
    width: calc(25% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 2rem; }
    .bloc_actu .liste-news .news_card .news_img {
      display: block;
      overflow: hidden;
      height: 13rem; }

.autres-actus {
  margin-top: 2.5rem;
  margin-bottom: 3rem; }

.bloc_actu .news_card.new_type_fournisseur .news_date {
  background-color: #417F35; }

.bloc_actu .news_card.new_type_fournisseur .news_title {
  color: #417F35; }
  .bloc_actu .news_card.new_type_fournisseur .news_title a, .bloc_actu .news_card.new_type_fournisseur .news_title .lien-off {
    color: #417f35; }

.bloc_actu .news_card.new_type_fournisseur .news-see-details {
  border-top-color: #417F35; }
  .bloc_actu .news_card.new_type_fournisseur .news-see-details a, .bloc_actu .news_card.new_type_fournisseur .news-see-details .lien-off {
    color: #417F35; }

.bloc_actu .news_card.new_type_fournisseur .icon-close {
  color: #417F35; }

.detail_type_fournisseur .news_date {
  background-color: #417F35; }

.detail_type_fournisseur .news_title {
  color: #417F35; }
  .detail_type_fournisseur .news_title a, .detail_type_fournisseur .news_title .lien-off {
    color: #417f35; }

.detail_type_fournisseur .new-right-col .h1,
.detail_type_fournisseur .new-left-col .detail_date {
  color: #417F35; }

.detail_type_fournisseur .new-left-col .tags-liste .tag-new {
  color: #417F35;
  border-color: #417F35; }

@media (max-width: 1199px) {
  .bloc_actu .liste-news .news_card {
    width: calc(100% / 3 - 1rem);
    margin-left: .5rem;
    margin-right: .5rem; }
  .news_block .news_content {
    margin-left: .5rem;
    margin-right: .5rem; } }

@media (max-width: 767px) {
  .bloc_actu .liste-news .news_card {
    width: calc(50% - 1rem); }
  .news_block .news_content {
    margin-left: .5rem;
    margin-right: .5rem; }
  .filter_header .text_label {
    display: block;
    line-height: 1.6; }
  .filter_header .custom-select {
    margin-left: 0; } }

@media (max-width: 575px) {
  .bloc_actu .liste-news .news_card {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  .filter_header {
    flex-direction: column; }
    .filter_header form {
      margin-top: .6rem;
      margin-left: 0; } }

.body_contact .main_content .container {
  margin-top: 4rem;
  margin-bottom: 4rem;
  max-width: 100rem; }
