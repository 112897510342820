/* Bootstrap 4
.col- (extra small devices - screen width less than 576px)
.col-sm- (small devices - screen width equal to or greater than 576px)
.col-md- (medium devices - screen width equal to or greater than 768px)
.col-lg- (large devices - screen width equal to or greater than 992px)
.col-xl- (xlarge devices - screen width equal to or greater than 1200px)
*/
.pink,
.color1,
.color1 a {
  color: #D22350; }

.blue,
.color2,
.color2 a {
  color: #0ea6af; }

.grey {
  color: #9fa8bd; }

@font-face {
  font-family: Poppins;
  src: local("Poppins Light"), local("Poppins-Light"), url(../fonts/Poppins-Light.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Poppins;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(../fonts/Poppins-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Poppins;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(../fonts/Poppins-Medium.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: Poppins;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(../fonts/Poppins-Bold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

body,
button,
fieldset,
form,
html,
textarea {
  font-family: Poppins,sans-serif; }

body,
html {
  scroll-behavior: smooth; }

html {
  font-size: 62.5%; }

body {
  font-size: 1.6rem;
  color: #68728b;
  font-weight: 300; }

.container {
  position: relative;
  max-width: 130rem;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto; }
  .container::before, .container::after {
    content: none; }
  .container.container-xl {
    max-width: 1760px; }

hr {
  color: #d4d7d9;
  border: 1px inset #d4d7d9; }

.fixed {
  position: fixed; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static;
  overflow: hidden; }

.hyphenate {
  text-align: left;
  -webkit-hyphens: auto;
  hyphens: auto;
  -ms-hyphens: auto; }

.inline {
  display: inline; }

.inlineblock {
  display: inline-block; }

.block {
  display: block; }

.hidden,
.masque,
figcaption {
  display: none; }

.floatleft {
  float: left; }

.floatright {
  float: right; }

.floatnone {
  float: none; }

.textleft {
  text-align: left; }

.textright {
  text-align: right; }

.textcenter {
  text-align: center; }

.textjustify {
  text-align: justify; }

.width80 {
  width: 80%; }

.width60 {
  width: 60%; }

.width50 {
  width: 50%; }

.width47 {
  width: 47%; }

.width45 {
  width: 45%; }

.width35 {
  width: 35%; }

.width33 {
  width: 33.33333333%; }

.width25 {
  width: 25%; }

.margin0 {
  margin: 0; }

.marginauto {
  margin: auto; }

.lineh1 {
  line-height: 1; }

.lineh12 {
  line-height: 1.2; }

.lineh15 {
  line-height: 1.5; }

.lineh25 {
  line-height: 2.5; }

.height465 {
  height: 465px; }

.marginright {
  margin-right: 1em; }

.marginleft {
  margin-left: 1.5em; }

.marginbottom {
  margin-bottom: 2em !important; }

.marginbottomlight {
  margin-bottom: 1em; }

.margintopheavy {
  margin-top: 4em; }

.margintop {
  margin-top: 2em; }

.margintoplight {
  margin-top: 2em; }

.margintopultralight {
  margin-top: .5em; }

.margintop-2 {
  margin-top: -2em; }

.container900 {
  max-width: 900px;
  margin: 0 auto; }

.padding10 {
  padding: 10px; }

.paddingtopbot20 {
  padding: 20px 0; }

.padding1020 {
  padding: 10px 20px; }

.padding25 {
  padding: 25px; }

.padding30 {
  padding: 30px; }

.padding40 {
  padding: 40px; }

.padding60 {
  padding: 60px; }

.padding6040 {
  padding: 60px 40px; }

.padding7040 {
  padding: 70px 40px; }

.padding4070 {
  padding: 40px 3%; }

.padding4015 {
  padding: 40px 15px; }

.vmiddle {
  vertical-align: middle; }

.vtop {
  vertical-align: top; }

.vbottom {
  vertical-align: bottom; }

strong,
.bold {
  font-weight: 700; }

.normal {
  font-weight: 400; }

.mini,
.fontsize80 {
  font-size: 80%; }

.fontsize85 {
  font-size: 85%; }

.fontsize90 {
  font-size: 90%; }

.fontsize110 {
  font-size: 110%; }

.big,
.fontsize120 {
  font-size: 120%; }

.margin0auto {
  display: block;
  margin: 0 auto; }

.lowercase {
  text-transform: lowercase; }

.uppercase {
  text-transform: uppercase; }

.mobile {
  display: none; }

.desktop {
  display: block; }

img {
  max-width: 100%; }

.aheight,
.centered-height {
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle; }

.clear,
.line,
.row,
.wrap {
  clear: both; }

.clearfix:after,
.clearfix:before {
  display: block;
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  content: '.'; }

.clearfix:after {
  clear: both; }

.bg_cover {
  background-position: center center;
  background-size: cover; }

.overlay-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10; }

.error {
  margin: 10px 0;
  padding: 12px;
  color: #d8000c;
  background-color: #ffbaba; }

.success {
  margin: 10px 0;
  padding: 12px;
  color: #3c763d;
  border-color: #d6e9c6;
  background-color: #c6ffae; }

a, .lien-off {
  transition: background-color .2s ease-in,color .2s ease-in,box-shadow .2s ease-in,opacity .2s ease-in,border .2s ease-in;
  text-decoration: none;
  color: #000; }

a:active,
a:focus,
a:hover,
.lien-off:active,
.lien-off:focus,
.lien-off:hover {
  color: #706d70; }

a.button,
.lien-off.button {
  display: inline-block; }

.table,
table {
  font-size: 11px;
  border-collapse: collapse;
  color: #3d3d3d;
  border: 1px solid #e3e3e3;
  background-color: #fff; }

.table td,
.table th,
table td,
table th {
  padding: 5px 10px;
  border: 1px solid #e3e3e3; }

.table th,
table th {
  padding: 10px 10px; }

.header-page-classic {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 2rem;
  padding-right: 2rem; }
  .header-page-classic .h3 {
    position: relative;
    color: #fff;
    z-index: 1;
    padding-left: .8rem;
    padding-right: .8rem; }
  .header-page-classic .custom-select::after {
    font-size: 1.6rem;
    font-weight: 500;
    right: 1.5rem;
    top: 2.4rem; }

/* Positionnement */
/* Limite le nombre de ligne du champ, NE PAS METTRE DE HAUTEUR */
/* Effets css */
.button1 {
  font-size: 12px;
  display: inline-block;
  width: 200px;
  padding: 15px 20px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  border-radius: 20px;
  background: #81a3bf; }

.button1:hover {
  color: #fff;
  border-radius: 10px; }

.button2 {
  font-family: Montserrat,sans-serif;
  font-size: 13px;
  display: block;
  width: 200px;
  margin: 0 auto;
  padding: 15px 20px;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  background: 0 0; }

.button2:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.7); }

.button3,
a.button3 {
  font-family: Montserrat,sans-serif;
  font-size: 13px;
  display: inline-block;
  margin: 0 auto;
  padding: 10px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  vertical-align: middle;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #feca1c;
  border-radius: 20px;
  outline: 0;
  background: #feca1c; }

.button4 {
  font-size: 12px;
  display: inline-block;
  width: 200px;
  padding: 15px 20px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  border-radius: 50px;
  background: #b56259; }

.button4:hover {
  background: #784137; }

.button5 {
  font-size: 12px;
  display: inline-block;
  width: auto;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  background: #020202; }

.button_blue {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1.8rem 3.6rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #0EA6AF;
  border: .1rem solid #0EA6AF;
  border-radius: 3rem;
  transition: all .3s ease-in-out;
  cursor: pointer; }

.button_blue:focus,
.button_blue:hover {
  color: #0EA6AF;
  background-color: #fff; }

.button_pink {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1.8rem 3.6rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #d02752;
  border: .1rem solid #d02752;
  border-radius: 3rem;
  transition: all .3s ease-in-out; }

.button_pink:focus,
.button_pink:hover {
  cursor: pointer;
  color: #d02752;
  background-color: #fff; }

.button_pink_overlay {
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  width: 235px;
  height: 26px;
  padding-top: 8px;
  transition: all .3s ease-in-out;
  text-align: center;
  text-transform: uppercase;
  color: #d02752;
  border-radius: 16px;
  background-color: #fff; }

.button_pink_overlay:focus,
.button_pink_overlay:hover {
  cursor: pointer;
  opacity: .85;
  color: #d02752; }

.button_pink_outline {
  color: #fff;
  background-color: #d02752;
  border: .1rem solid #fff;
  padding: .8rem 2rem;
  border-radius: 2.4rem;
  font-weight: 500;
  transition: all .3s; }

.button_pink_outline:focus,
.button_pink_outline:hover {
  color: #d02752;
  background-color: #fff; }

.button_grey {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1.8rem 3.6rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #4D4D4D;
  border: .1rem solid #4D4D4D;
  border-radius: 3rem;
  transition: all .3s ease-in-out;
  cursor: pointer; }
  .button_grey:active, .button_grey:hover {
    color: #4D4D4D;
    background-color: #fff; }

.button_outline {
  display: inline-block;
  font-size: 1.4rem;
  color: #d02752;
  background-color: transparent;
  border: .1rem solid #d02752;
  padding: .5rem 1rem;
  border-radius: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: all .3s; }

.button_outline:focus,
.button_outline:hover {
  color: #fff;
  background-color: #d02752; }

.big-button {
  font-size: 1.6rem;
  text-transform: none;
  font-weight: 600;
  padding: 1.8rem 2.2rem;
  margin-top: 2.5rem;
  width: auto; }

input[type=checkbox],
input[type=radio] {
  display: none; }

input[type=checkbox] + label,
input[type=radio] + label {
  line-height: 24px;
  position: relative;
  display: inline-block;
  height: 24px;
  padding-left: 24px; }

input[type=checkbox] + label::before,
input[type=radio] + label::before {
  font-family: kfont;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 4px;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin: 0;
  content: '';
  text-align: center;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff; }

input[type=radio] + label:before {
  border-radius: 50%; }

input[type=radio]:checked + label:after {
  position: absolute;
  top: 8px;
  left: 4px;
  display: block;
  width: 10px;
  height: 10px;
  content: '';
  border-radius: 50%;
  background: #333; }

input[type=checkbox]:checked + label:after {
  content: '\EA39';
  font-family: kfont;
  position: absolute;
  top: 4px;
  left: 3px;
  color: #333; }

.checkbox_style {
  line-height: 20px;
  position: relative; }

.checkbox_style input {
  position: absolute;
  visibility: hidden;
  opacity: 0; }

.checkbox_style label {
  padding-left: 20px; }

.checkbox_style label::after,
.checkbox_style label::before {
  position: absolute;
  top: 0;
  left: 1px;
  display: block;
  width: 14px;
  height: 14px;
  content: ''; }

.checkbox_style label::before {
  background-color: #fff; }

.checkbox_style input:checked + label::after {
  font-family: kfont;
  font-size: inherit;
  padding-left: 1px;
  content: '\ea39';
  color: #737373;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.custom-select {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  cursor: pointer; }
  .custom-select select {
    font-size: 1.4rem;
    font-weight: 500;
    color: #abadb4;
    background-color: #fff;
    border: none;
    appearance: none;
    padding: .4rem 2.5rem .4rem .5rem;
    cursor: pointer;
    min-width: 20rem; }
    .custom-select select option {
      font-size: 1.2rem; }
  .custom-select::after {
    font-family: kfont;
    content: "\EA02";
    display: block;
    position: absolute;
    right: .5rem;
    top: .7rem;
    font-size: 1.3rem;
    color: #abadb4;
    pointer-events: none; }
  .custom-select:focus {
    color: red; }

/* Select2 */
.select2-container--default .select2-selection--multiple {
  border: none;
  height: 6.8rem;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  cursor: pointer; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: none; }

.select2-container--default.select2-container--focus .select2-selection--multiple .counter {
  color: #656975; }

.select2-container--default .select2-search--inline .select2-search__field {
  color: #656975;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  pointer-events: none; }

.select2-container .select2-search--inline .select2-search__field {
  display: flex;
  align-items: center;
  height: 2.2rem;
  margin: 0; }

.select2-results__option--selectable {
  position: relative;
  margin-left: 3.5rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 1rem;
  color: #abadb4;
  font-size: 1.6rem;
  font-weight: 500; }
  .select2-results__option--selectable:not(:last-child) {
    border-bottom: .1rem solid #ddd; }
  .select2-results__option--selectable::before, .select2-results__option--selectable::after {
    position: absolute;
    top: 50%;
    left: -2rem;
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateY(-50%); }
  .select2-results__option--selectable::before {
    content: '';
    display: block;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    text-align: center;
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff; }
  .select2-results__option--selectable.select2-results__option--selected::after {
    content: "\EA39" !important;
    font-family: kfont;
    font-size: inherit;
    color: #737373;
    text-rendering: auto;
    margin-left: .1rem;
    margin-top: -.1rem; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 40rem;
  scrollbar-width: thin; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar {
    width: 8px; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-track {
    background-color: #eee; }
  .select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-thumb {
    background-color: #ccc; }

.select2-container--default .select2-results__option--selected,
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  color: #abadb4;
  background-color: transparent; }
  .select2-container--default .select2-results__option--selected:hover,
  .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable:hover {
    color: #68728b; }

.select2-container--default .select2-results__option--selected {
  color: #68728b; }

/* Focus effect sur les inputs */
.custom-form input[type="text"],
.custom-form input[type="email"],
.custom-form input[type="password"],
.custom-form select {
  display: block;
  height: 5.5rem;
  width: 100%;
  border: .1rem solid #bbb;
  border-radius: .4rem;
  padding: 1.5rem 1.5rem .7rem 1.2rem;
  z-index: 1; }
  .custom-form input[type="text"]:disabled,
  .custom-form input[type="email"]:disabled,
  .custom-form input[type="password"]:disabled,
  .custom-form select:disabled {
    border-color: #eee; }

.custom-form select {
  padding-top: .7rem; }

.custom-form textarea {
  display: block;
  height: 20rem;
  width: 100%;
  border: .1rem solid #bbb;
  border-radius: .4rem;
  margin-top: .5rem;
  padding: 1.5rem 1.5rem .7rem 1.2rem;
  resize: none; }

.custom-form .input-effect {
  max-width: 39rem;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-bottom: 2rem;
  margin-right: auto; }
  .custom-form .input-effect .icon {
    margin-right: .6rem; }

.custom-form .focus-effect {
  padding: .7rem 1.5rem;
  border: 0.1rem solid #4B4947;
  position: relative;
  background: transparent; }

.custom-form .focus-effect ~ label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 5.5rem;
  width: 100%;
  color: #888;
  border-radius: .4rem;
  background-color: #fff;
  margin-bottom: 0;
  padding-left: 1.2rem;
  padding-top: 1.6rem;
  transition: all .2s linear;
  z-index: 0;
  letter-spacing: .5px; }

.custom-form .focus-effect:focus ~ label,
.custom-form .has-content.focus-effect ~ label {
  top: 0;
  font-size: 11px;
  color: #888;
  padding-top: .2rem;
  transition: all .2s linear; }

.custom-form .form-radio-container {
  margin-bottom: 2rem;
  max-width: 39rem;
  margin-right: auto;
  margin-left: auto; }
  .custom-form .form-radio-container .libelle {
    margin-right: 2rem; }
  .custom-form .form-radio-container input ~ input {
    margin-left: 2rem; }

.custom-form .form-checkbox-container {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto; }
  .custom-form .form-checkbox-container label {
    display: inline; }

.sep {
  height: 1px;
  margin: 15px 0;
  background-color: #d4d8da; }

#cms .container h1, #cms .container h2, #cms .container h3, #cms .container h4, #cms .container h5, #cms .container h6,
#cms .container .h1, #cms .container .h2, #cms .container .h3, #cms .container .h4, #cms .container .h5, #cms .container .h6,
.liste-content h1,
.liste-content h2,
.liste-content h3,
.liste-content h4,
.liste-content h5,
.liste-content h6,
.liste-content .h1,
.liste-content .h2,
.liste-content .h3,
.liste-content .h4,
.liste-content .h5,
.liste-content .h6,
.tox-editor-container h1,
.tox-editor-container h2,
.tox-editor-container h3,
.tox-editor-container h4,
.tox-editor-container h5,
.tox-editor-container h6,
.tox-editor-container .h1,
.tox-editor-container .h2,
.tox-editor-container .h3,
.tox-editor-container .h4,
.tox-editor-container .h5,
.tox-editor-container .h6 {
  word-wrap: normal;
  hyphens: none;
  line-height: 1; }

#cms .container h1,
#cms .container .h1,
.liste-content h1,
.liste-content .h1,
.tox-editor-container h1,
.tox-editor-container .h1 {
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.2;
  margin: .5rem 0;
  color: #D22350;
  text-align: center; }
  @media (max-width: 991px) {
    #cms .container h1,
    #cms .container .h1,
    .liste-content h1,
    .liste-content .h1,
    .tox-editor-container h1,
    .tox-editor-container .h1 {
      font-size: 3rem; } }

#cms .container h2,
#cms .container .h2,
.liste-content h2,
.liste-content .h2,
.tox-editor-container h2,
.tox-editor-container .h2 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.2;
  margin: .5rem 0;
  color: #D22350;
  text-align: center;
  font-weight: 400;
  color: #4D4D4D;
  font-size: 5rem; }
  @media (max-width: 991px) {
    #cms .container h2,
    #cms .container .h2,
    .liste-content h2,
    .liste-content .h2,
    .tox-editor-container h2,
    .tox-editor-container .h2 {
      font-size: 2.2rem; } }

#cms .container h3,
#cms .container .h3,
.liste-content h3,
.liste-content .h3,
.tox-editor-container h3,
.tox-editor-container .h3 {
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 1.2;
  margin: .5rem 0;
  color: #D22350;
  font-weight: 500;
  font-size: 4rem; }
  @media (max-width: 991px) {
    #cms .container h3,
    #cms .container .h3,
    .liste-content h3,
    .liste-content .h3,
    .tox-editor-container h3,
    .tox-editor-container .h3 {
      font-size: 2rem; } }

#cms .container h4,
#cms .container .h4,
.liste-content h4,
.liste-content .h4,
.tox-editor-container h4,
.tox-editor-container .h4 {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  margin: .6em 0;
  text-align: left;
  color: #0ea6af;
  text-transform: none;
  font-weight: 400;
  font-size: 3.2rem;
  color: #4D4D4D; }
  @media (max-width: 991px) {
    #cms .container h4,
    #cms .container .h4,
    .liste-content h4,
    .liste-content .h4,
    .tox-editor-container h4,
    .tox-editor-container .h4 {
      font-size: 1.2rem; } }

#cms .container h5,
#cms .container .h5,
.liste-content h5,
.liste-content .h5,
.tox-editor-container h5,
.tox-editor-container .h5 {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase; }

#cms .container h6,
#cms .container .h6,
.liste-content h6,
.liste-content .h6,
.tox-editor-container h6,
.tox-editor-container .h6 {
  font-size: 1.2rem;
  color: #0ea6af;
  margin: .6rem 0;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  text-align: center; }

#cms {
  line-height: 1.3; }

a, .lien-off {
  transition: all .3s ease-in-out;
  text-decoration: none; }

.lien-off {
  cursor: pointer; }

.cms ol,
.mce-content-body ol,
.product .text ol {
  margin-left: 15px;
  list-style-type: decimal; }

.cms ul,
.mce-content-body ul {
  margin-left: 15px;
  list-style-type: square; }

.cms ul li,
.mce-content-body ul li {
  margin: 4px auto; }

.cms img {
  height: auto; }

#cms p {
  line-height: 1.3em;
  margin: .6em auto; }

#grid_builder.overlay_bg,
.cover_bg {
  position: relative;
  background-position: center center;
  background-size: cover; }

#grid_builder.overlay_bg:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  content: '';
  background: rgba(255, 255, 255, 0.9); }

@media screen and (max-width: 767px) {
  .h1,
  .titre,
  h1 {
    font-size: 24px; }
  .h2,
  .stitre,
  h2 {
    font-size: 22px; }
  .h3,
  .sstitre,
  h3 {
    font-size: 16px; }
  .h4,
  .ssstitre,
  h4 {
    font-size: 14px; } }
